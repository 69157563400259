<template>
	<span class="timeout">
		{{ timer }}
	</span>
</template>

<script>
export default {
	props: {
		value: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			interval: null,
			timer: this.value
		}
	},
	mounted() {
		this.interval = setInterval(() => {
			if (this.timer === 0) {
				this.$emit('end')
				clearInterval(this.interval)
			} else {
				this.$emit('step')
				this.timer -= 1
			}             
		}, 1000)
	},
	unmounted() {
		clearInterval(this.interval)
	}
}
</script>