export default (axios) => ({
  owner() {
    return axios.get(`place/owner/progress`)
  },
  client() {
    return axios.get(`place/client/progress`)
  },
  get(ids, { controller } = { controller: new AbortController() }) {
    let query = '?'
    for (let i = 0; i < ids.length; i++) {
      query += 'user_id[]=' + ids[i].toString()
      if ((i + 1) !== ids.length) {
        query += '&'
      }
    }
    return axios.post(`place/user/progress${query}`, _ , { signal: controller.signal })
  }
})