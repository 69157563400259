<template>
	<div class="infomodal">
		<div class="infomodal__wrapper">
			<div class="infomodal__logo">
				<img
					src="@/assets/img/svg/logo.svg"
					width="87"
					height="68"
					alt="Логотип Теремок"
				/>
			</div>
			<p v-if="texts[step]" class="infomodal__text">
				{{ texts[step].text }}
			</p>
			<div class="infomodal__buttons">
				<a :class="{ 'infomodal__buttons--one': step !== 1 }" @click="submit">ОК</a>
				<a v-if="step === 1" @click="hide">Отмена</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BookingCancellation',
	props: {
		content: {
			type: Object,
			default: () => ({
				step: 1,
				callback: () => {}
			})
		}
	},
	computed: {
		step() {
			return this.content.step
		},
		texts() {
			return {
				1: {
					text: 'Вы действительно хотите отменить заявку на бронирование?'
				},
				2: {
					text: 'Ваше бронирование успешно отменено. Средства будут возвращены в течение 72 часов'
				}
			}
		}
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		},
		submit() {
			if (this.step === 1) {
				this.content.callback()
			} else if (this.step === 2) {
				this.hide()
			}
		}
	},
}
</script>