import actions from './actions'
import mutations from './mutations'

export default {
  state: {
    client: {},
    owner: {},
    content: {}
  },
  actions,
  mutations,
  namespaced: true
}