export class YandexMetric {
  _id;

  constructor() {
    this._id = process.env.VUE_APP_YA_METRIKA_ID

    if (typeof ym == 'undefined') {
      const script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.appendChild(document.createTextNode(`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
           m[i].l=1*new Date();
           for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
           k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
           (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
        
           ym(${this._id}, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true
           });`))

      const parser = new DOMParser();
      const noscript = parser.parseFromString(`<noscript><div><img src="https://mc.yandex.ru/watch/${this._id}" style="position:absolute; left:-9999px;" alt="" /></div></noscript>`, "application/xml").firstChild;

      document.head.prepend(noscript);
      document.head.prepend(script);
    }
  }

  sendEvent(name) {
    ym(this._id, 'reachGoal', name);
  }
}