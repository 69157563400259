<template>
	<div class="info-modal">
		<div class="info-modal__logo">
			<img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
		</div>
		<p class="info-modal__text">
			Спасибо за информацию!<br />
			Мы обработаем запрос и свяжемся<br />
			с Вами, если нам понадобятся уточнения.
		</p>
		<a class="info-modal__button" @click="submit">Ок</a>
	</div>
</template>

<script>
export default {
	name: 'SuccessIdentificationPhoto',
	methods: {
		submit() {
			this.$router.push('/profile/anketa')

			setTimeout(() => {
				this.$store.commit('hideModal')
			}, 100)
		}
	}
}
</script>
