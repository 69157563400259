export default {
  state: {
    newBookingsCount: 42,
  },
  mutations: {
    setNewBookingsCount(state, payload) {
      state.newBookingsCount = payload.count
    }
  },
  actions: {
    async getNewBookingsCount(context) {
      const response = await this.$api.reserve.getNewBookingsCount()

      if (response?.status) {
        const count = response.response.meta.total
        context.commit('setNewBookingsCount', {
          count: count
        })
      }
    }
  }
}