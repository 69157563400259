import { formatDatetime } from "@/chat/utils";

/**
 * Сообщение в чате
 */
export default class ChatMessage {
  constructor(message, chat) {
    this._chat = chat

    this.id = message.id
    this.is_read = message.is_read
    this.room_id = message.room_id
    this.text = message.text
    this.created_at = message.created_at
    this.updated_at = message.updated_at
    this.user_id = message.user_id
  }

  /**
   * Возвращает объект пользователя (автора)
   *
   * @returns {*}
   */
  get user() {
    return this._chat.getUserById(this.user_id)
  }

  /**
   * Возвращает объект чат-комнаты, к которой относится это вообщение
   *
   * @returns {*}
   */
  get room() {
    return this._chat.getRoomById(this.room_id)
  }

  /**
   * Проверяем, является ли наш пользователь чата
   * автором сообщения
   *
   * @returns {boolean}
   */
  get isUserselfMessage() {
    return this.user_id === this._chat.userSelf.id
  }

  /**
   * Время написание сообщения в формате DD.MM.YYYY HH:MM
   *
   * @returns {string}
   */
  get datetime() {
    return formatDatetime(this.created_at)
  }
}