export default (axios) => ({
	getRejectAndComplaint() {
		return axios.get(`place/global/lists/places/reject-and-complaint`)
	},
	getHousingOptions() {
		return axios.get(`place/global/lists/places/options`)
	},
	getCounters({ controller } = { controller: new AbortController() }) {
		return axios.get(`place/counters?filter[]=1&filter[]=2&filter[]=3`, { signal: controller.signal })
	},
  getSleepingPlace() {
    return axios.get(`place/global/lists/places/bed-types`)
  }
})
