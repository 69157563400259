<template>
	<svg
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="15" cy="15" r="14.75" stroke="#3B3F47" stroke-width="0.5" />
		<path d="M19 16.0625H11V14.7969H19V16.0625Z" fill="#3B3F47" />
	</svg>
</template>
