import actions from './actions'
import mutations from './mutations'

export default {
	state: {
		content: null
	},
	actions,
	mutations,
	namespaced: true
}