<template>
	<Transition name="slide-fade">
		<div v-if="isActive" :class="['notification', notification.type]">
			<div class="notification__container">
				<button @click="hide" type="button" class="notification__close">
					x
				</button>
				<h3 v-if="notification.title" class="notification__title">
					{{ notification.title }}
				</h3>
				<p class="notification__text notif-content">{{ notification.text }}</p>
			</div>
		</div>
	</Transition>
</template>

<script>
import { computed, watch, ref } from 'vue'
import { useStore } from 'vuex'

import { types } from '@/constants/notification'

export default {
	name: 'GlobalNotification',
	props: {
		time: {
			// time to show
			type: Number,
			default: 5000
		}
	},
	setup(props) {
		const timerId = ref(null)
		const { state, commit } = useStore()

		const hide = () => {
			clearTimeout(timerId.value)

			commit('hideNotification')
		}

		const notification = computed(() => state.notification)
		const currentType = computed(() => state.notification.type || types.INFO)
		const isActive = computed(() => state.notification.isShow)

		watch(isActive, () => {
			if (isActive.value) {
				timerId.value = setTimeout(hide, props.time)
			}
		})

		return {
			hide,

			notification,
			currentType,
			isActive
		}
	}
}
</script>

<style lang="sass" scoped>
.slide-fade-enter-active
  transition: all 0.5s ease-out

.slide-fade-leave-active
  transition: all 0.5s ease-out

.slide-fade-enter-from,
.slide-fade-leave-to
  transform: translateX(50px)
  opacity: 0

.notification
  position: fixed
  top: 70px
  right: 20px
  width: 100%
  max-width: 300px
  padding: 12px
  background-color: white
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1)
  border-radius: 12px
  z-index: 999

  &.info
    .notif-content
      color: black

  &.warn
    .notif-content
      color: darkorange

  &.error
    .notif-content
      color: red


  &.success
    .notif-content
      color: green

  &__title
    margin-bottom: 8px
    font-weight: 500
    font-size: 16px
    line-height: 20px

  &__text
    margin: 0
    font-weight: 400
    font-size: 16px
    padding-right: 5px
    line-height: 20px

  &__close
    position: absolute
    top: 10px
    right: 10px
    width: 10px
    height: 10px
    padding: 0
    background-color: transparent
    border: none
    cursor: pointer
</style>
