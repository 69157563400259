<template>
	<label class="toggle-radio" :for="$attrs.id">
		<span v-if="label" class="toggle-radio__label radio-label">{{ label }}</span>

		<div class="toggle-radio__switcher radio-switcher">
			<input
				class="tgl tgl-light"
				v-bind="$attrs"
				type="radio"
			/>
			<label class="tgl-btn" :for="$attrs.id" />
		</div>
	</label>
</template>

<script>

// Use: Profile/Anketa/Questions

export default {
	props: {
		label: {
			type: String,
			default: null
		},
		modelValue: {
			type: String
		}
	},
	inheritAttrs: false,
}
</script>
