<template>
	<component class="svg-icon" v-bind="$attrs" :is="type" />
</template>

<script>
const requireComponent = require.context('@/icons', true, /[a-z]\w+\.vue$/)
const cmps = {}
requireComponent.keys().forEach((fileName) => {
	const componentConfig = requireComponent(fileName)
	const componentName = fileName
		.split('/')
		.pop()
		?.replace(/\.\w+$/, '')

	cmps[componentName] = componentConfig.default || componentConfig
})

export default {
	components: {
		...cmps
	},
	props: {
		type: {
			type: String,
			required: true
		}
	}
}
</script>
