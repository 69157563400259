import { SET_MUT, CLEAR_MUT } from '@/constants/store/place/mutations'

export default {
	state: {
		place_id: null,
		current: 1,
		verification_status: null
	},
	mutations: {
		[SET_MUT]: (state, { key, value }) => (state[key] = value),
		[CLEAR_MUT]: (state) => {
			state.place_id = null
			state.current = 1
			state.verification_status = null
		}
	},
	namespaced: true
}
