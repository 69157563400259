<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20 10C20 4.47311 15.5275 0 10 0C4.47311 0 0 4.4725 0 10C0 15.4779 4.43512 20 10 20C15.542 20 20 15.5031 20 10ZM10 1.17188C14.8679 1.17188 18.8281 5.13214 18.8281 10C18.8281 11.7807 18.2985 13.4923 17.3163 14.942C13.3722 10.6995 6.63498 10.6918 2.68372 14.942C1.70151 13.4923 1.17188 11.7807 1.17188 10C1.17188 5.13214 5.13214 1.17188 10 1.17188ZM3.41461 15.8789C6.92139 11.9455 13.0795 11.9464 16.5852 15.8789C13.0667 19.8154 6.93466 19.8166 3.41461 15.8789Z"
			fill="#CCCCCC"
      class="active-fill"
		/>
		<path
			d="M10 10.5859C11.9385 10.5859 13.5156 9.00879 13.5156 7.07031V5.89844C13.5156 3.95996 11.9385 2.38281 10 2.38281C8.06152 2.38281 6.48438 3.95996 6.48438 5.89844V7.07031C6.48438 9.00879 8.06152 10.5859 10 10.5859ZM7.65625 5.89844C7.65625 4.60602 8.70758 3.55469 10 3.55469C11.2924 3.55469 12.3438 4.60602 12.3438 5.89844V7.07031C12.3438 8.36273 11.2924 9.41406 10 9.41406C8.70758 9.41406 7.65625 8.36273 7.65625 7.07031V5.89844Z"
			fill="#CCCCCC"
      class="active-fill"
		/>
	</svg>
</template>
