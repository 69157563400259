import router from '@/router'
import axios from 'axios'

import { SET_MUT, DELETE_MUT } from '@/constants/store/auth/mutations'
import {
	SEND_CODE_VERIFICATION_ACT,
	REFRESH_TOKEN_ACT,
	CODE_IS_VALID_ACT,
	CHECK_PHONE_ACT,
	REGISTER_ACT,
	RECOVERY_ACT,
	AUTH_ACT,
	LOGOUT_ACT
} from '@/constants/store/auth/actions'
import {
	SET as SET_USER,
	CLEAR_ALL as CLEAR_ALL_USER
} from '@/constants/store/user/mutations'
import { CLEAR as CLEAR_TICKETS } from '@/constants/store/tickets/mutations'

export default {
	[SEND_CODE_VERIFICATION_ACT]: async function ({ state }, { type }) {
		const response = await this.$api.auth.sendCodeVerification({
			phone: state[type].values.phone,
			recovery: type !== 'register'
		})

		if (response && response.error) {
			return response
		}

		if (response && !response.status) {
			return response
		}
	},
	[CHECK_PHONE_ACT]: async function (_, payload) {
		return this.$api.auth.isUsePhone(payload)
	},
	[CODE_IS_VALID_ACT]: async function ({ state }, { type }) {
		const response = await this.$api.auth.codeIsValid({
			phone: state[type].values.phone,
			code: state[type].values.code
		})

		if (response && response.error) {
			return response
		}

		if (response && !response.response.is_valid) {
			return {
				error: {
					code: [response.response.message]
				}
			}
		}
	},
	[REGISTER_ACT]: async function ({ commit, state: { register } }) {
		const response = await this.$api.auth.register(register.values)

		if (response.errors) {
			return { error: response.errors }
		}

		commit(SET_MUT, { access: response.response.access_token })
		commit(SET_MUT, { refresh: response.response.refresh_token })

		commit(SET_USER, response.response.user, { root: true })
	},
	[RECOVERY_ACT]: async function ({ commit, state: { recovery } }) {
		const { response, errors } = await this.$api.auth.recovery(recovery.values)

		if (errors) {
			return { errors }
		}

		commit(SET_MUT, { access: response.access_token })
		commit(SET_MUT, { refresh: response.refresh_token })

		commit(SET_USER, response.user, { root: true })
	},
	[AUTH_ACT]: async function ({ commit }, payload) {
		const response = await this.$api.auth.auth(payload)

		if (!response || !response.status) {
			return response
		}

		commit(SET_MUT, { access: response.response.access_token })
		commit(SET_MUT, { refresh: response.response.refresh_token })

		commit(SET_USER, response.response.user, { root: true })
	},
	[REFRESH_TOKEN_ACT]: async function ({ commit, state: { tokens } }) {
		return await axios({
			method: 'post',
			url: `${process.env.VUE_APP_API_URL}/api/v1/account/user/refresh-token`,
			data: {
				refresh_token: tokens.refresh
			}
		}).then(({ data }) => {
			commit(SET_MUT, { access: data.response.access_token })
			commit(SET_MUT, { refresh: data.response.refresh_token })

			return Promise.resolve(data.response)
		})
	},
	[LOGOUT_ACT]: async function ({ commit }) {
		commit(DELETE_MUT, 'access')
		commit(DELETE_MUT, 'refresh')

		commit(CLEAR_ALL_USER, {}, { root: true })
		commit(CLEAR_TICKETS, {}, { root: true })

		await router.push('/')
	}
}
