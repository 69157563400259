<template>
	<component :is="type" v-bind="attributes" :class="classes" :style="styles">
		<span class="link-component__icon-before link-icon-before">
			<slot name="icon-before" />
		</span>
		<span class="link-component__content link-content">
			<slot>{{ $t('form.links.default_title') }}</slot>
		</span>
		<span class="link-component__icon-after link-icon-after">
			<slot name="icon-after" />
		</span>
	</component>
</template>

<script>
export default {
	name: 'Link',
	props: {
		kind: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		height: {
			type: Number,
			default: null
		},
		fontSize: {
			type: String,
			default: null
		},
		lineHeight: {
			type: String,
			default: null
		}
	},
	inheritAttrs: false,
	computed: {
		type() {
			return this.$attrs.to ? 'router-link' : 'a'
		},
		classes() {
			return `link-component ${this.kind}${this.$attrs.class ? ` ${this.$attrs.class}` : ''} ${this.disabled ? 'disabled' : ''}`
		},
		attributes() {
			const atrs = { ...this.$attrs }
			delete atrs.class
			return atrs
		},
		styles() {
			return {
				height: this.height ? this.height + 'px' : undefined,
				fontSize: this.fontSize ? this.fontSize : undefined,
				lineHeight: this.lineHeight ? this.lineHeight : undefined
			}
		}
	}
}
</script>