<template>
	<label :class="[classes, 'input-component']">
		<div class="input-component__container input-container">
			<span class="input-component__label" v-if="label">
				{{ label }}
			</span>

			<input
				v-bind="$attrs"
        :type="type"
				:class="[
					'input input-component__content',
					{ 'input-component__content--label': label },
					{ error: error && error.status }
				]"
				:value="modelValue"
				@input="updateValue"
        v-phone="{ isPhone }"
			/>

			<div
				class="input-component__clear-button"
				:aria-label="$t('form.buttons.clear_button')"
				v-if="isClearButton && modelValue"
				@click="clear"
			/>
      <div class="input-component__send-button" v-else-if="isSendButton && modelValue" @click="send" />
		</div>
		<transition name="fade" mode="out-in">
			<div v-if="error && error.status" class="input-component__error">
				{{ error.text }}
			</div>
		</transition>
	</label>
</template>

<script>
export default {
	props: {
		error: {
			type: Object,
			default: () => ({})
		},
		modelValue: {
			type: null
		},
		maska: {
			type: String,
			default: ''
		},
    type: {
      type: String,
      default: 'text'
    },
		label: {
			type: String,
			default: ''
		},
		class: {
			type: String,
			default: ''
		},
		isClearButton: {
			type: Boolean,
			default: true
		},
    isSendButton: {
      type: Boolean,
      default: false
    }
	},
	methods: {
		updateValue(event) {
			this.$emit('update:modelValue', event.target.value)
		},
		clear() {
			this.$emit('update:modelValue', null)
		},
    send() {
      this.$emit('send')
    }
	},
	computed: {
		classes() {
			return this.class
		},
    isPhone() {
      return this.type === 'tel'
    }
	}
}
</script>
