import ChatUser from "@/chat/ChatUser";
import ChatRoom from "@/chat/ChatRoom";
import methods from "@/chat/socket";
import io from "socket.io-client";
import ChatEventsEnum from "@/chat/chatEventsEnum";
import ChatEvent from "@/chat/ChatEvent";
import { decodeJWT } from "@/chat/utils";
import { reactive } from "vue";
import { sleep } from "@/utils/helpers";

/**
 * Модель-состояние чатика
 */
export default class Chat {
  /**
   * Грязная реализация синглтона. Здесь хранится реактивная ссылка
   * на единственный экземпляр чатика
   *
   * @private
   */
  static _instance

  /**
   * Пользователь чата
   *
   * @private
   */
  _userself

  /**
   * Список известных контактов
   *
   * @type {*}
   * @private
   */
  _users = new Map()

  /**
   * Список известных чат-комнат
   *
   * @type {*}
   * @private
   */
  _rooms = new Map()

  /**
   * id текущей чат-комнаты, т.е. сообщения которой отображаем и т.п.
   *
   * @type {null}
   * @private
   */
  _currentRoomId = null

  /**
   * соединений socket.io
   *
   * @private
   */
  _socket

  /**
   * token авторизации
   *
   * @private
   */
  _token

  /**
   * Последнее событие в чатике
   *
   * @type {ChatEvent}
   * @private
   */
  _lastEvent = null

  /**
   * Если нажали на кнопку "Связаться с хозяином" запоминаем
   * его id в этом поле вместе с сообщением (если есть)
   *
   * @type {null}
   * @private
   */
  _waitForConversationWith = {
    id: null,
    msg: null,
  }

  /**
   * Значение в строке поиска.
   * Обнулять при переходе в комнату и смене компоненты.
   *
   * @type {string}
   */
  roomQuery = ''

  /**
   * Грязная реализация синглтона
   *
   * @return {Chat}
   */
  constructor() {
    if (!Chat._instance) {
      Chat._instance = reactive(this)
    }
    return Chat._instance
  }

  /**
   * Последнее уведомление о новом действии в чате
   *
   * @return {ChatEvent}
   */
  get lastEvent() {
    return this._lastEvent
  }

  /**
   * Активно ли соединение?
   *
   * @returns {boolean}
   */
  get isConnected() {
    return this._socket?.connected
  }

  /**
   * Возвращаем объект пользователя чатика
   *
   * @returns {ChatUser}
   */
  get userSelf() {
    return this._userself
  }

  /**
   * Выбрана ли комната чатика для общения?
   *
   * @returns {boolean}
   */
  get isRoomSelected() {
    return this._currentRoomId != null
  }

  /**
   * Возвращает список контактов, что сейчас в сети
   *
   * @returns {ChatUser[]}
   */
  get usersOnline() {
    const usersOnline = []
    for (let user of this._users.values()) {
      if (user.is_online) usersOnline.push(user)
    }
    return usersOnline
  }

  /**
   * Количество известных комнат
   *
   * @returns {*}
   */
  get roomsCount() {
    return this._rooms.size
  }

  /**
   * Возвращает итератор по комнатам
   *
   * @returns {*}
   */
  get rooms() {
    let rooms
    if (this.roomQuery === '') {
      rooms = Array.from(this._rooms.values())
    } else {
      rooms = []
      for (let room of this._rooms.values()) {
        if (room.recipient?.name.toLowerCase().includes(this.roomQuery.toLowerCase())) {
          rooms.push(room)
        }
      }
    }

    rooms.sort((x, y) =>
    {
      const dateX = x.lastMessage?.created_at ?? x.created_at
      const dateY = y.lastMessage?.created_at ?? y.created_at
      return dateY - dateX
    })
    return rooms
  }

  /**
   * Сообщения из текущей комнаты чатика
   *
   * @returns {*}
   */
  get messages() {
    if (!this._currentRoomId) {
      throw 'не выбрана комната'
    }

    return this._rooms.get(this._currentRoomId).messages
  }

  /**
   * Возвращает объект текущей комнаты
   *
   * @returns {ChatRoom}
   */
  get room() {
    return this._rooms.get(this._currentRoomId)
  }

  /**
   * Обработчик system и chat событий. На основе типа передает
   * данные в нужный метод
   *
   * @remarks
   * TODO: возможно имеет смысл переделать на соотвествия
   *
   * @param event
   * @private
   */
  _handler(event) {
    switch (event.type) {
      case 'userDataEvent':
        this._userself = new ChatUser(event.data)
        break
      case 'userOnlineEvent':
        this._addOrUpdateUser(event.data)
        break
      case 'userOfflineEvent':
        this._addOrUpdateUser(event.data)
        break
      case 'roomDataEvent':
        this._addOrUpdateRoom(event.data)
        break
      case 'listRoomsEvent':
        this._addOrUpdateRooms(event.data.items)
        break
      case 'roomIsBlockedEvent':
        this._handleBlocking(event.data.room_id, event.data.block_type)
        break
      case 'newMessageEvent':
        this._handleNewMessage(event.data)
        break
      case 'listMessagesEvent':
        this._addMessages(event.data.items)
        break
      case 'messageReadedEvent':
        this._handleMessageRead(event.data)
        break
      default:
        throw 'unexcepted event ' + event.type
    }
  }

  /**
   * Добавляем комнату к известным нам {@link _rooms} или
   * обновляет информацию об уже известной.
   * Дополнительно обновляем список контактов.
   *
   * @param room
   * @private
   */
  _addOrUpdateRoom(room) {
    if (!room.id) {
      throw 'не передан идентификатор комнаты'
    }

    if (!room.users) {
      throw 'не передан список собеседников'
    }

    room.users.forEach((user) => {
      if (!this._users.has(user.id)) {
        this._addOrUpdateUser(user)
      }
    })

    // т.к. последнее сообщение преходящее с объектом
    // не валидно обнуляем
    const lastMessageId = room.last_message?.id
    room.last_message = undefined

    const newRoom = new ChatRoom(room, this)
    this._rooms.set(room.id, newRoom)

    // и запрашиваем снова
    if (lastMessageId)
      this._fetchMessages(lastMessageId, 1, room.id)

    // если мы ожидали добавления комнаты для общения с хозяином
    // переходим в нее и сбрасываем ожидание
    if(this._waitForConversationWith &&
      newRoom.recipient.id === this._waitForConversationWith.id)
    {
      this.joinRoom(room.id)
      this.sendMessage(this._waitForConversationWith.msg, room.id)
      this._waitForConversationWith.id = null
      this._waitForConversationWith.msg = null
    }
  }

  /**
   * Обычно вызывается в ответ на событие listRoomsEvent.
   * Добавляет комнаты к известным нам {@link _rooms} и / или
   * обноявлет уже известные
   * Дополнительно обновляет список контактов.
   *
   * @param rooms
   * @private
   */
  _addOrUpdateRooms(rooms) {
    rooms.forEach((room) => {
      this._addOrUpdateRoom(room)
    })
  }

  /**
   * Добавляет контакт к известным нам {@link _users} или
   * обновляет информацию об уже известном.
   *
   * @param user
   * @private
   */
  _addOrUpdateUser(user) {
    const u = new ChatUser(user)
    this._users.set(u.id, u)
  }

  /**
   * Добавляет контакты к известным нам {@link _users} и/или
   * обновляет информацию об уже известных.
   *
   * @param users
   * @private
   */
  _addOrUpdateUsers(users) {
    users.forEach((u) => {
      this._addOrUpdateUser(u)
    })
  }

  /**
   * Получаем новое сообщение (событие newMessageEvent), добавляем его
   * и регистрируем событие
   *
   * @param message
   * @private
   */
  _handleNewMessage(message) {
    const msg = this._addMessage(message)
    this._lastEvent = new ChatEvent(ChatEventsEnum.NEW_MESSAGE, msg)
  }

  /**
   * Вызывается при получении события messageReadedEvent :
   * помечает указанные сообщения прочитанными
   *
   * @param message
   * @private
   */
  _handleMessageRead(readedEvent) {
    if (!this._rooms.get(readedEvent.room_id)) {
      this._fetchRooms()
    }

    this._rooms.get(readedEvent.room_id).markReadMessageAndOldest(readedEvent.message_id, this._userself.id)
  }

  /**
   * Вызывается при получении события roomIsBlockedEvent :
   * помечает указанную комнату заблокированной с типом type
   * {@link ChatRoom.BLOCKING_TYPE}
   *
   * @param roomId
   * @param type
   * @private
   */
  _handleBlocking(roomId = this._currentRoomId, type) {
    if (!this._rooms.get(roomId)) {
      this._fetchRooms()
    }

    this._rooms.get(roomId).setBlock(type)
  }

  /**
   * Отправляем серверу событие getMessages с просьбой прислать сообщения
   *
   * @param from    - последний id который должен быть загружен
   * @param count   - количество сообщений
   * @param roomId  - id комнаты, сообщения которой будем загружать
   * @private
   */
  _fetchMessages(from = null, count = 10, roomId = this._currentRoomId)
  {
    if (!from) {
      if (this._rooms.get(roomId)?.lastMessage?.id) {
        from = this._rooms.get(roomId).lastMessage.id
      } else {
        return
      }
    }

    this._socket.emit(methods.GET_MESSAGES, {
      room_id: roomId,
      message_id: from,
      count: count,
      stream: 'up'
    })
  }

  /**
   * Отправляем серверу событие getRooms с просьбой прислать информацию
   * о чат-комнатах в которых состоим
   *
   * @param limit   - количество комнат, информацию о которых запрашиваем
   * @param offset
   * @private
   */
  _fetchRooms(limit = 100, offset = 0)
  {
    this._socket.emit(methods.GET_ROOMS, {
      limit: limit,
      offset: offset
    })
  }

  /**
   * Поиск комнаты по id собеседника
   *
   * @param id
   * @return {null|ChatRoom} - объект комнаты чата
   * @private
   */
  _findRoomByUserId(id) {
    for (let room of this._rooms.values()) {
      if (room.recipient.id === id) return room
    }
    return null
  }

  /**
   * Добавляем полученное от сообщение в нужную комнату
   *
   * @param message
   * @return {ChatMessage}
   * @private
   */
  async _addMessage(message) {
    if (!message.room_id) {
      throw 'сообщение из неизвестной комнаты'
    }

    if (!message.user_id) {
      return
      throw 'сообщение от неизвестного пользователя'
    }

    if (!this._rooms.get(message.room_id)) {
      this._fetchRooms()
      await sleep(500)
    }
    return this._rooms.get(message.room_id)?.addMessage(message)
  }

  /**
   * Добавляем полученные от сервера сообщение (событие listMessagesEvent)
   * в нужнуые комнаты
   *
   * @remarks
   * TODO: учитывая, что сообщения уже отсортированные, можно пользоваться
   * TODO: более быстрой версией добавления в {@link OrderedDLSet}
   *
   * @param messages
   * @private
   */
  _addMessages(messages) {
    messages.forEach((message) => {
      this._addMessage(message)
    })
  }

  /**
   * Подключаемся к чату с переданным нам token'ом.
   *
   * @remarks
   * Регистрирует обработчики событий, обновляет список комнат,
   * устанавливает ссылку на соедиинение {@link _socket}
   *
   * @param token
   */
  connect(token) {
    if (!token) {
      throw 'не передан token для подключения'
    }

    this._token = token

    const decoded = decodeJWT(this._token)

    // запоминаем свой id для сверки сообщений свой/чужой
    this._userself = new ChatUser({
      id: decoded.id
    })

    this._socket = io(process.env.VUE_APP_CHAT_URL, {
      query: {
        token: this._token
      },
      transports: ['websocket'],
      autoConnect: false
    })

    this._socket.on('system', (e) => this._handler(e))
    this._socket.on('chat', (e) => this._handler(e))
    this._socket.connect()

    this._fetchRooms()
  }

  /**
   * Отключение от чатика и сброс к начальным чатика
   */
  disconnect() {
    this._socket.disconnect()
    this._token = null
    this._lastEvent = null
    this._users = new Map()
    this._rooms = new Map()
    this._currentRoomId = null
    this.roomQuery = ''
  }

  /**
   * Посылаем event blockingRoom для блокировки комнаты
   * с указанным id
   *
   * @param id
   */
  blockRoomById(id = this._currentRoomId) {
    if (!id) {
      throw 'не передан параметр комнаты для блокировки'
    }

    this._socket.emit(methods.BLOCKING_ROOM, {
      room_id: id
    })
  }

  /**
   * Стараемся найти беседу среди известных нам либо
   * посылаем event createRoom для создания новой комнаты
   * с пользователем user_id = id
   *
   * @param id
   * @param msg
   */
  getOrCreateConversationWithUser(id, msg) {
    if (!id || id === this._userself.id) {
      return
    }

    // проверяем, известен ли нам пользователь
    if (this._users.has(id)) {
      const room = this._findRoomByUserId(id)
      this.joinRoom(room.id)
      this.sendMessage(msg, room.id)
      return
    }

    // если нет, сохраняем для ожидания
    this._waitForConversationWith.id = id
    this._waitForConversationWith.msg = msg

    this._socket.emit(methods.CREATE_ROOM, {
      to: id
    })
  }

  /**
   * Посылаем event unblockingRoom для разблокировки комнаты
   * с указанным id
   *
   * @param id
   */
  unblockRoomByUserId(id = this.room?.id) {
    if (!id) {
      throw 'не передан параметр комнаты для разблокировки'
    }

    this._socket.emit(methods.UNBLOCKING_ROOM, {
      room_id: id
    })
  }

  /**
   * Делаем активной комнату с id
   *
   * @param id
   */
  joinRoom(id) {
    if (!this._rooms.get(id)) {
      throw 'такой комнаты не существует'
    }

    this.roomQuery = ''
    this._currentRoomId = id
    this._fetchMessages()
  }

  /**
   * Выходим из текущей комнаты (т.е. далее не будут передаваться
   * данные о сообщениях до выбора комнаты вновь)
   */
  exitRoom() {
    this._currentRoomId = null
  }

  /**
   * Отправляем серверу событие sendMessage для добавления
   * сообщения в чатик
   *
   * @param text    - текст сообщения
   * @param roomId  - id комнаты
   */
  sendMessage(text, roomId = this._currentRoomId) {
    this._socket.emit(methods.SEND_MESSAGE, {
      message: text,
      room_id: roomId
    })
  }

  /**
   * Информируем сервер событием readMessage о прочитанном сообщении, если:
   * 1. Не было прочитано раннее
   * 2. Автор сообщения не мы
   *
   * @param message - сообщение
   */
  readMessage(message) {
    if ((message?.user_id === this._userself.id) || message?.is_read) {
      return
    }

    if (message) {
      this._socket.emit(methods.READ_MESSAGE, {
        message_id: message.id
      })
    } else {
      message = this.room.lastMessage
      if (!message) return
      if (this.room.hasUnreadMessages()) {
        this._socket.emit(methods.READ_MESSAGE, {
          message_id: this.room.lastMessage.id
        })
      }
    }

    message.room.markReadMessageAndOldest(message.id, message.room.recipient.id)
  }

  /**
   * Информируем сервер событием readMessage о прочитанном сообщении, если:
   * 1. Не было прочитано раннее
   * 2. Автор сообщения не мы
   *
   * @param messageId - id сообщения
   * @param authorId  - id автора
   * @param isRead    - было ли уже прочитано
   */
  readMessageById(messageId, authorId, isRead) {
    if ((authorId && authorId === this._userself.id) || isRead) {
      return
    }

    if (messageId) {
      this._socket.emit(methods.READ_MESSAGE, {
        message_id: messageId
      })
    } else {
      if (!this.room.lastMessage.is_read) {
        this._socket.emit(methods.READ_MESSAGE, {
          message_id: this.room.lastMessage.id
        })
      }
    }
  }

  /**
   * Получаем объект пользователя из известных контаков {@link _users}
   * по его id
   *
   * @param id
   * @returns {ChatUser}
   */
  getUserById(id) {
    return this._users.get(id)
  }

  /**
   * Получаем объект комнаты из известных нам {@link _rooms}
   * по его id
   *
   * @param id
   * @returns {*}
   */
  getRoomById(id) {
    return this._rooms.get(id)
  }

  /**
   * Обычно вызывается при прокручивании чатика.
   * Просим прислать сервер (событие getMessages) ранние сообщеия
   * из текущей чат-комнаты.
   */
  loadPreviousMessages() {
    this._fetchMessages(
      this.room.oldestLoadedMessage?.id ?? 0,
      10
    )
  }
}