export default (axios) => ({
	/**
	 * Получить аватарки
	 *
	*/
	get() {
		return axios.get(`account/user/avatars`)
	},
	/**
	 * Установить активный аватар
	 * 
	 * @param { string } - img_hash: Хэш картинки аватара
	 *
	*/
	set({ img_hash }) {
		return axios.patch(`account/user/avatars/${img_hash}`)
	},
	/**
	 * Загрузка аватара
	 * 
	 * @formdata { array } - upload[]: Обязательное. max:512 кб, mimetypes:image/jpeg,image/png
	 *
	*/
	upload (payload) {
		return axios.post(`account/user/avatars`, payload)
	},
	/**
	 * Удалить аватар
	 * 
	 * @formdata { array } - upload[]: Обязательное. max:512 кб, mimetypes:image/jpeg,image/png
	 *
	*/
	delete (img_hash) {
		return axios.delete(`account/user/avatars/${img_hash}`)
	}
})
