<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" rx="8" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9136 13.7678C23.0418 14.0883 22.8859 14.452 22.5655 14.5802L18.3988 16.2469C18.0783 16.3751 17.7146 16.2192 17.5864 15.8987C17.4582 15.5782 17.6141 15.2145 17.9346 15.0863L22.1012 13.4196C22.4217 13.2914 22.7854 13.4473 22.9136 13.7678Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.20011 15.2509C5.3187 15.1178 5.48845 15.0417 5.66669 15.0417H18.1667C18.3449 15.0417 18.5147 15.1178 18.6333 15.2509C18.7519 15.384 18.808 15.5613 18.7876 15.7384L18.6048 17.3227L18.6048 17.3227C18.3986 19.1098 16.8854 20.4584 15.0864 20.4584H8.74694C6.94799 20.4584 5.43482 19.1098 5.22861 17.3227L5.22861 17.3227L5.04581 15.7384C5.02538 15.5613 5.08152 15.384 5.20011 15.2509ZM6.36795 16.2917L6.47037 17.1794C6.47037 17.1794 6.47038 17.1794 6.47038 17.1794C6.6038 18.3358 7.58291 19.2084 8.74694 19.2084H15.0864C16.2505 19.2084 17.2296 18.3358 17.363 17.1794C17.363 17.1794 17.363 17.1794 17.363 17.1794L17.4654 16.2917H6.36795Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9167 7.54175C12.2619 7.54175 12.5417 7.82157 12.5417 8.16675V12.3334C12.5417 12.6786 12.2619 12.9584 11.9167 12.9584C11.5715 12.9584 11.2917 12.6786 11.2917 12.3334V8.16675C11.2917 7.82157 11.5715 7.54175 11.9167 7.54175Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.58331 8.375C8.92849 8.375 9.20831 8.65482 9.20831 9V11.5C9.20831 11.8452 8.92849 12.125 8.58331 12.125C8.23814 12.125 7.95831 11.8452 7.95831 11.5V9C7.95831 8.65482 8.23814 8.375 8.58331 8.375Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.25 8.375C15.5952 8.375 15.875 8.65482 15.875 9V11.5C15.875 11.8452 15.5952 12.125 15.25 12.125C14.9048 12.125 14.625 11.8452 14.625 11.5V9C14.625 8.65482 14.9048 8.375 15.25 8.375Z" fill="#767582"/>
  </svg>
</template>
