<template>
	<div class="timeout">
		{{ string }}
		<tk-timer
			:value="timer"
			@step="timer -= 1"
			@end="end"
		/>
		{{ secondsString }}
	</div>
</template>

<script>
import declension from '@/utils/declension'

export default {
	props: {
		data: {
			type: [String, Number],
			default: 0
		}
	},
	data() {
		return {
			timer: 0
		}
	},
	created() {
		if (this.data) {
			this.timer = parseInt(this.data)

			this.$emit('startTimer')
		}
	},	
	computed: {
		string() {
			return this.$tm('form.errors.timeout')[0]
		},
		secondsString() {
			return declension(this.$tm('form.errors.timeout')[1], this.timer)
		}
	},
	methods: {
		end() {
			this.$emit('endTimer')
		}
	}
}
</script>