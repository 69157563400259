<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M17.2871 0.902917C16.8271 0.902917 16.4538 1.27625 16.4538 1.73625V2.43083C14.6637 0.87 12.3808 0 9.94792 0C4.4625 0 0 4.47875 0 9.98333C0 10.4433 0.373333 10.8167 0.833333 10.8167C1.29333 10.8167 1.66667 10.4433 1.66667 9.98333C1.66667 5.3975 5.38167 1.66667 9.94792 1.66667C11.9508 1.66667 13.8325 2.37583 15.3158 3.64958H14.5521C14.0921 3.64958 13.7188 4.02292 13.7188 4.48292C13.7188 4.94292 14.0921 5.31625 14.5521 5.31625H17.2871C17.7471 5.31625 18.1204 4.94292 18.1204 4.48292V1.73625C18.1204 1.27625 17.7471 0.902917 17.2871 0.902917Z"
			fill="white"
		/>
		<path
			d="M19.1654 9.18359C18.7054 9.18359 18.3321 9.55693 18.3321 10.0169C18.3321 14.6028 14.6171 18.3336 10.0508 18.3336C8.07122 18.3336 6.20955 17.6419 4.73455 16.3961H5.53497C5.99497 16.3961 6.3683 16.0228 6.3683 15.5628C6.3683 15.1028 5.99497 14.7294 5.53497 14.7294H2.79955C2.79205 14.7294 2.78538 14.7315 2.7783 14.7315C2.74663 14.7323 2.71538 14.7365 2.68372 14.7411C2.66038 14.7444 2.63705 14.7465 2.61413 14.7519C2.58663 14.7582 2.55955 14.7682 2.53247 14.7773C2.50747 14.7861 2.48205 14.7936 2.45788 14.8044C2.43497 14.8148 2.4133 14.8282 2.39122 14.8407C2.36497 14.8557 2.33913 14.8703 2.31455 14.8878C2.3083 14.8923 2.30122 14.8948 2.29497 14.8998C2.2808 14.9107 2.26997 14.924 2.25663 14.9357C2.23455 14.9548 2.21247 14.974 2.19247 14.9957C2.17372 15.0157 2.15747 15.0365 2.14122 15.0578C2.12497 15.079 2.10913 15.1003 2.09455 15.1228C2.07997 15.1461 2.06747 15.1703 2.05538 15.1944C2.0433 15.2182 2.03205 15.2423 2.02247 15.2673C2.01288 15.2932 2.00538 15.319 1.9983 15.3457C1.99163 15.3711 1.98455 15.3965 1.98038 15.4228C1.97538 15.4523 1.9733 15.4819 1.97122 15.5119C1.96997 15.529 1.96622 15.5457 1.96622 15.5632V18.3098C1.96622 18.7698 2.33955 19.1432 2.79955 19.1432C3.25955 19.1432 3.63288 18.7698 3.63288 18.3098V17.6473C5.40913 19.1594 7.65788 20.0003 10.0508 20.0003C15.5362 20.0003 19.9987 15.5215 19.9987 10.0169C19.9987 9.55693 19.6254 9.18359 19.1654 9.18359Z"
			fill="white"
		/>
	</svg>
</template>