<template>
  <div class="info-modal">
    <div class="info-modal__logo">
      <img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
    </div>
    <p class="info-modal__text" v-html="content.text" />
    <div class="info-modal__buttons">
      <a @click="submit">ОК</a>
      <a @click="hide">Отмена</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonConfirm',
  props: {
    content: {
      type: Object,
      default: () => ({
        text: '',
        callback: () => {}
      })
    }
  },
  methods: {
    hide() {
      this.$store.commit('hideModal')
    },
    submit() {
      this.content.callback()

      setTimeout(() => {
        this.hide()
      })
    }
  }
}
</script>
