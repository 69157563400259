export default {
  data() {
    return {
      errors: {},
      error: {
        status: false,
        text: '',
        isRender: false,
        component: ''
      }
    }
  },
  methods: {
    clearMainError() {
      this.error.status = false
      this.error.text = ''
  
      this.error.isRender = false
      this.error.component = ''
    },
    clearError(type) {
      this.errors[type].status = false
      this.errors[type].text = ''
  
      this.error.value = ''
    },
    setError(response) {
      if (response && response.error) {
        if (response.error.message) {
          if (response.status === 429) {
            // Too Many Requests
  
            this.error.status = true
            this.error.isRender = true
            this.error.component = 'time-out'
            this.error.text = response.retryAfter || 59
  
            return
          }
  
          this.error.status = true
          this.error.value = response.error.message
  
          return
        }
  
        Object.entries(response.error).forEach(([key, value]) => {
          if (this.errors[key]) {
            this.errors[key].status = true
            this.errors[key].text = (Array.isArray(value)) ? value.join(', ') : value
          }
  
          // Need handle type file
        })
  
        return
      }
  
      if (response && response.errors) {
        Object.entries(response.errors).forEach(([key, value]) => {
          if (this.errors[key]) {
            this.errors[key].status = true
            this.errors[key].text = (Array.isArray(value)) ? value.join(', ') : value
          }
  
          // Need handle type file
        })
      }
    },
    _createFieldsError(fields) {
      this.errors = fields.reduce((obj, itm) => {
        obj[itm] = { status: false, text: '' }
        return obj
      }, {})
    }
    // const returnError = (response) => {
	// 	const error = { response }

	// 	let code, message, data
	// 	if (error.response.response) {
	// 		code = error.response.response.data.code
	// 		message = error.response.response.data.message
	// 		data = error.response.response.data.errors
	// 	} else {
	// 		code = 500
	// 		message = 'Сервер не доступен. Попробуйте позже!'
	// 	}
	// 	return { type: 'error', code, data, message }
	// }
  }
}
