<template>
	<div class="booking-toggle">
		<div class="booking-toggle__header">
			<img
				src="@/assets/img/svg/logo.svg"
				width="87"
				height="68"
				alt="Логотип Теремок"
			/>
		</div>

		<div class="booking-toggle__container">
			<GuestCard :is-confirm="content.isConfirm" :content="content.values" />
		</div>

		<div class="booking-toggle__footer">
			<button @click="submit(true)" class="btn booking-toggle__btn" type="button">ОК</button>
			<button @click="submit(false)" class="btn booking-toggle__btn" type="button">Отмена</button>
		</div>
	</div>
</template>

<script>
import GuestCard from '../cards/BookingGuestCard.vue'

export default {
	components: { GuestCard },
	props: {
		content: {
			type: Object,
			default: () => ({
				isConfirm: null,
				values: {},
				callback: () => ({})
			})
		}
	},
  methods: {
    submit(flag) {
      this.content?.callback(flag)

      setTimeout(() => {
        this.$store.commit('hideModal')
      })
    }
  }
}
</script>

<style lang="sass">
.booking-toggle
  position: relative
  padding: 20px 35px
  padding-bottom: 75px
  background: #F0F0F0
  border-radius: 12px

  &__header
    margin-bottom: 20px
    text-align: center

  &__footer
    position: absolute
    bottom: 0
    left: 0
    right: 0
    width: 100%

  &__btn
    width: 50%
    height: 44px
    font-weight: 600
    font-size: 17px
    line-height: 22px
    color: #5F5350
    border-top: 1px solid rgba(0, 0, 0, 0.2)

    &:last-child
      border-left: 1px solid rgba(0, 0, 0, 0.2)
</style>
