<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 3C1.25 2.58579 1.58579 2.25 2 2.25H15C15.4142 2.25 15.75 2.58579 15.75 3V21C15.75 21.4142 15.4142 21.75 15 21.75H2C1.58579 21.75 1.25 21.4142 1.25 21V3ZM2.75 3.75V20.25H14.25V3.75H2.75Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.25C6.41421 2.25 6.75 2.58579 6.75 3V21C6.75 21.4142 6.41421 21.75 6 21.75C5.58579 21.75 5.25 21.4142 5.25 21V3C5.25 2.58579 5.58579 2.25 6 2.25Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.25 3C17.25 2.58579 17.5858 2.25 18 2.25H22C22.4142 2.25 22.75 2.58579 22.75 3V19C22.75 19.1989 22.671 19.3897 22.5303 19.5303L20.5303 21.5303C20.2374 21.8232 19.7626 21.8232 19.4697 21.5303L17.4697 19.5303C17.329 19.3897 17.25 19.1989 17.25 19V3ZM18.75 3.75V18.6893L20 19.9393L21.25 18.6893V3.75H18.75Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.25 6C17.25 5.58579 17.5858 5.25 18 5.25H22C22.4142 5.25 22.75 5.58579 22.75 6C22.75 6.41421 22.4142 6.75 22 6.75H18C17.5858 6.75 17.25 6.41421 17.25 6Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 3C1.25 2.58579 1.58579 2.25 2 2.25H15C15.4142 2.25 15.75 2.58579 15.75 3C15.75 3.41421 15.4142 3.75 15 3.75H2C1.58579 3.75 1.25 3.41421 1.25 3Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 21C1.25 20.5858 1.58579 20.25 2 20.25H15C15.4142 20.25 15.75 20.5858 15.75 21C15.75 21.4142 15.4142 21.75 15 21.75H2C1.58579 21.75 1.25 21.4142 1.25 21Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 2.25C18.4142 2.25 18.75 2.58579 18.75 3V11C18.75 11.4142 18.4142 11.75 18 11.75C17.5858 11.75 17.25 11.4142 17.25 11V3C17.25 2.58579 17.5858 2.25 18 2.25Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22 2.25C22.4142 2.25 22.75 2.58579 22.75 3V11C22.75 11.4142 22.4142 11.75 22 11.75C21.5858 11.75 21.25 11.4142 21.25 11V3C21.25 2.58579 21.5858 2.25 22 2.25Z" fill="#767582"/>
  </svg>
</template>
