<template>
	<header class="header" :class="{ 'header-places': $route.name === 'Places' }">
		<div class="container">
			<div class="header__content">
				<div class="header__left">
					<router-link to="/">
						<img
								width="64"
								height="50"
								src="@/assets/img/svg/logo.svg"
								alt="Логотип"
						/>
					</router-link>
					<span></span>
					<p>Ты заходи, <br/> если что</p>
				</div>


				<!--				<div class="header__support-list header-support">-->
				<!--					&lt;!&ndash;					<div class="header-support__container">&ndash;&gt;-->
				<!--					&lt;!&ndash;						<span class="header-support__line" />&ndash;&gt;-->
				<!--					&lt;!&ndash;						<div class="header-support__item">&ndash;&gt;-->
				<!--					&lt;!&ndash;							<img&ndash;&gt;-->
				<!--					&lt;!&ndash;								src="@/assets/img/pages/home/goverment-icon.png"&ndash;&gt;-->
				<!--					&lt;!&ndash;								width="45"&ndash;&gt;-->
				<!--					&lt;!&ndash;								height="50"&ndash;&gt;-->
				<!--					&lt;!&ndash;								alt="Правительство РФ"&ndash;&gt;-->
				<!--					&lt;!&ndash;								class="header-support__icon"&ndash;&gt;-->
				<!--					&lt;!&ndash;							/>&ndash;&gt;-->
				<!--					&lt;!&ndash;							<span class="header-support__text">&ndash;&gt;-->
				<!--					&lt;!&ndash;								При поддержке<br />&ndash;&gt;-->
				<!--					&lt;!&ndash;								Правительства РФ&ndash;&gt;-->
				<!--					&lt;!&ndash;							</span>&ndash;&gt;-->
				<!--					&lt;!&ndash;						</div>&ndash;&gt;-->
				<!--					&lt;!&ndash;					</div>&ndash;&gt;-->
				<!--				</div>-->
				<SearchPanel
					id="search-panel-landing"
					v-if="$route.name === 'Places'"
					:class="{ navigation__places: $route.name === 'Places' }"
				/>

				<nav class="header__nav navigation">
<!--          <el-radio-group-->
<!--            class="mode-picker"-->
<!--            v-model="mode"-->
<!--            v-if="$route.name !== 'Places'"-->
<!--            size="large">-->
<!--            <el-radio-button label="Найти"  v-if="$route.name === 'Owner' " />-->
<!--          </el-radio-group>-->

					<tk-drop-down
						@update="toggleShowUserMenu"
						animation="none"
						ref="userMenuDropdown"
					>
						<template #toggle="{ active }">
							<button
								type="button"
								aria-label="toggle-show-menu"
								class="btn navigation__toggle"
							>
								<tk-svg
									type="landing-profile"
									class="navigation__toggle--user-icon"
								/>
								<tk-svg
									type="burger"
									:class="{ active: active }"
									class="navigation__toggle--burger"
								/>
							</button>
						</template>
						<template #content>
							<Menu class="navigation__menu" @close="closeUserMenu()" />
						</template>
					</tk-drop-down>
				</nav>
			</div>
		</div>
	</header>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { ElRadioGroup, ElRadioButton } from "element-plus";
import mods from "@/constants/mods";

export default {
	components: {
		Menu: defineAsyncComponent(() => import('@/components/UserMenu')),
		SearchPanel: defineAsyncComponent(() =>
			import('@/components/views/home/guest/SearchPanel')
		),
    ElRadioButton, ElRadioGroup
	},
	computed: {
    mode: {
      get() {
        return this.$store.getters.mode === mods.GUEST ? 'Найти' : 'Сдать'
      },
      set() {
        this.changeMode()
      }
    }
	},
	methods: {
		changeMode() {
			if (this.$route.name === 'Guest') {
				this.$router.push({ name: 'Owner' })
			} else {
				this.$router.push({ name: 'Guest' })
			}
		},
		closeUserMenu() {
			this.$refs.userMenuDropdown.hide()
		},
		toggleShowUserMenu(payload) {
			if (payload) {
				document.getElementsByTagName('body')[0].classList.add('stop-scrolling')
			} else {
				document
					.getElementsByTagName('body')[0]
					.classList.remove('stop-scrolling')
			}
		}
	}
}
</script>

<style lang="sass">
.mode-picker
  margin-right: 30px

  label.is-active
    span
      background-color: transparent !important
      border-color: rgb(220, 223, 230) !important

  label:first-child
    span
      border-radius: 50px !important

  label:last-child
    span
      border-radius: 50px !important

  label
    span
      box-shadow: -1px 0 0 0 #1AC386 !important
      color: #606266 !important
    span:hover
      color: #1AC386 !important
      border-color: #1AC386 !important
  @media screen and (max-width: 479px)
    margin-right: 10px
.header
	&__left
		display: flex
		align-items: center
		gap: 16px
		& > span
			width: 2px
			height: 48px
			background: #B0AEAE
			align-self: flex-start
		& > p
			color: #B0AEAE
			line-height: 20px
			font-size: 16px
			margin: 0
		@media screen and (max-width: 479px)
			gap: 12px
			& > p
				font-size: 14px
				line-height: 16px
	&__content
		display: flex
		align-items: center
		padding: 30px 0

	&__logo
		display: flex
		align-items: center
	.header-support
		&__container
			display: inline-flex
			align-items: center

		&__line
			height: 48px
			width: 1px
			margin-right: 16px
			background-color: #B0AEAE

		&__item
			display: flex
			align-items: center
			margin-right: 16px

		&__text
			margin-left: 12px
			font-style: normal
			font-weight: 400
			font-size: 16px
			line-height: 20px
			color: #B0AEAE

	.navigation
		display: flex
		margin-left: auto
		&__toggle-type
			display: block
			margin-left: auto
			margin-right: 20px
			width: 142px
			height: 38px
			font-style: normal
			font-weight: 700
			font-size: 16px
			line-height: 19px
			color: #6D6D6D
			border: 1px solid #E0E0E0
			border-radius: 20px

		&__toggle
			display: flex
			justify-content: space-between
			align-items: center
			width: 75px
			padding: 3px 3px 3px 7px
			border-radius: 120px
			background-color: #1AC386
			cursor: pointer

		&__menu
			margin-top: 20px
			transform: translateX(-70%)

			.menu
				&__list + .menu__footer 
					margin-top: 60px
				
				&__footer
					margin-top: 0


	@media screen and (max-width: 912px)
		&__content
			flex-wrap: wrap

	@media screen and (max-width: 550px)
		&__content
			padding: 15px 0

		.header-support
			flex-wrap: wrap

	@media screen and (max-width: 768px)
		&__logo img
			width: 44px
			height: 34px

		.header-support
			display: flex

			&__icon
				width: 31px
				height: 34px

				&--skolkovo
					width: 50px
					height: 14px

			&__text
				margin-left: 0
				font-size: 11px
				line-height: 14px

		.navigation
			align-items: center
			&__menu
				transform: unset

			&__toggle
				width: 26px
				height: 26px
				padding: 0

				&--user-icon
					display: none

				&--burger
					width: 26px
					height: 26px
</style>
<style lang="scss">
.header-places {
	.header__content {
		display: grid;
		grid-template-columns: min-content 1fr min-content;
		justify-items: center;
		grid-gap: 20px;
	}

	.navigation__places {
		box-shadow: none;
		height: 90px;
		padding: 5px;
		border: 1px solid #5f5350;
		max-width: 790px;
		gap: 10px;
		@media screen and (max-width: 1025px) {
			height: 50px;
			max-width: 500px;
		}
		.searchbox__submit {
			flex-shrink: 0;
		}
		.search-city {
			@media screen and (max-width: 1025px) {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			&__label {
				display: flex;
				flex-direction: row;
				align-items: center;

				svg {
					vertical-align: middle;
					flex-shrink: 0;
				}
			}
			@media screen and (max-width: 1025px) {
				&__select {
					padding-left: 0;
				}
				&__label-txt {
					display: none;
				}
			}
		}
		.datepicker {
			@media screen and (max-width: 1025px) {
				&__content {
					display: none;
				}
				&__label {
					width: 15px;
					display: flex;
					justify-content: center;
				}
				&--end {
					display: none;
				}
				&__range-separator {
					display: none;
				}
				&__label-txt {
					display: none;
				}
			}
		}
		.input-search {
			@media screen and (max-width: 1025px) {
				&__content {
					display: none;
				}
				&__text {
					display: none;
				}
			}
		}
		.searchbox__line {
			margin: 0 15px;
		}
		@media screen and (max-width: 700px) {
			grid-column: 1 / -1;
			margin-top: 30px;
		}
		@media screen and (max-width: 500px) {
			height: auto;
			padding: 20px;
			gap: 10px;
			flex-direction: column;
			background-color: rgba(128, 128, 128, 0.3);
			border: none;
			.datepicker {
				&__labels {
					align-items: center;
					display: none;
				}
				&__labels-mobile {
					display: flex;
				}
				&__label {
					width: 100%;
					display: flex;
					align-items: center;
				}
				&--end {
					&::before {
						content: '';
						display: block;
						width: 1px;
						height: 15px;
						margin-left: 10px;
						background-color: #2a3043;
					}
				}
				&__content {
					display: block;
				}
				&__label-txt {
					display: block;
					font-size: 16px;
					color: #2a3043;
				}
			}
			.search-guests {
				&__content {
					margin-left: 70px;
				}
			}
			.input-search {
				display: flex;
				&__label {
					display: flex;
					align-items: center;
				}
				&__text {
					display: block;
					color: #2a3043;
					font-size: 16px;
					margin-left: 8px;
					&::first-letter {
						text-transform: uppercase;
					}
					span {
						display: none;
					}
				}
				&__content {
					display: block;
					margin-left: 5px;
					max-width: 30px;
				}
			}
		}
	}
	@media screen and (max-width: 700px) {
		.header__nav {
			grid-row: 1/ 3;
			grid-column: 2 / 3;
		}
	}
}
</style>
