<template>
  <div class="info-modal-reviews">
    <div class="rating">
      <tk-svg type="star"/> {{ content.rating.toFixed(2) }} ({{ content.count }} отзывов)
    </div>
    <div class="info-modal-reviews__body">
      <div class="info-modal-reviews__left">
        <span class="info-modal-reviews__title">О хозяине</span>
        <RatingList :ratings="mappedRatings" />
      </div>
      <div class="info-modal-reviews__right">
        <ul class="reviews__list">
          <ReviewModalItem v-for="review in result" :key="review.id" :review="review" />
          <!--      <li class="reviews__item" v-for="review in result" :key="review.id">-->
          <!--        <div class="reviews__item_header">-->
          <!--&lt;!&ndash;          <div class="guest-img">&ndash;&gt;-->
          <!--&lt;!&ndash;            <tk-image :src="latestReview.author_avatar" :alt="latestReview.author_name" />&ndash;&gt;-->
          <!--&lt;!&ndash;          </div>&ndash;&gt;-->
          <!--          <div class="header-wrap">-->
          <!--&lt;!&ndash;            <div class="guest-name">{{ latestReview.author_name }}</div>&ndash;&gt;-->
          <!--            <div class="date">{{ getDateInFormat(review.created_at) }}</div>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--        <p class="reviews__item_text" :class="{ shown: latestReview.isShown }" ref="text">-->
          <!--          {{ review.text }}-->
          <!--        </p>-->
          <!--        <div v-if="showMoreBtn" class="read-more-btn" @click="latestReview.isShown = !review.isShown">-->
          <!--          {{ review.isShown ? 'Свернуть' : 'Читать далее' }}-->
          <!--        </div>-->
          <!--      </li>-->
        </ul>
      </div>
    </div>
    <div class="info-modal-reviews__close" @click="hide()">
      <tk-svg type="new-modal-close"/>
    </div>
  </div>
</template>

<script>
import { getDateInFormat } from "@/utils/dateTime";
import ReviewModalItem from "@/components/modals/ReviewsModal/ReviewModalItem";
import RatingList from "@/components/cards/review/RatingList";
import TkSvg from "@/components/global/tkSvg";

export default {
  name: "ReviewsModal",
  components: {TkSvg, RatingList, ReviewModalItem },
  props: {
    content: {
      type: Object,
      default: () => ({
        placeId: 0,
        callback: () => {}
      })
    }
  },
  data() {
    return {
      result: [],
      meta: null,

      getAbortController: new AbortController(),
    }
  },
  computed: {
    mappedRatings() {
      return [
        { target: '201', value: this.content.ratings.sociality },
        { target: '202', value: this.content.ratings.hospitable },
        { target: '301', value: this.content.ratings.convenience_location },
        { target: '302', value: this.content.ratings.purity }
      ]
    }
  },
  methods: {
    hide() {
      this.$store.commit('hideModal')
    },

    /**
     * Получаем отзывы для отображаения
     * @returns {Promise<void>}
     */
    async get() {
      this.getAbortController.abort()
      this.getAbortController = new AbortController()

      this.result = []

      // Сами отзывы
      let response = await this.$api.reviews.getByPlaceId({
        place_id: this.content.placeId
      }, { controller: this.getAbortController })

      if (response && response.status) {
        this.meta = response.response.meta
        this.result = response.response.items
      } else {
        return
      }

      // Собираем id авторов комментариев
      const userIds = [... new Set(this.result.map((r) => r.user_id))]
      // Чтобы получить информацию для отображения аватарок и имен
      const users = await this.$api.usersList.getNamesAndAvatars(userIds, { controller: this.getAbortController })
      // в каждый комментарий добавляем отображаемое имя и аватарку автора
      this.result.forEach((r) => {
        r.author_name = users[r.user_id].name
        r.avatar = users[r.user_id].avatar
      })
    },
    getDateInFormat
  },
  async beforeMount() {
    await this.get()
  },
  beforeUnmount() {
    this.getAbortController.abort()
  }
};
</script>

<style lang="scss" >
.info-modal-reviews{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 240px;
  background: #F0F0F0;
  backdrop-filter: blur(54.3656px);
  border-radius: 14px;
  padding: 40px 35px;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
  max-width: 650px;
  max-height: 700px;
  overflow: hidden;
  &__close{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  .rating{
    margin-top: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #3B3F47;
  }
  ulded-card__criteria{
    padding: 0;
    margin: 11px 0 30px 0;
    border-top: none;
  }
  &__body{
    display: flex;
    gap: 40px;
    width: 100%;
    overflow: hidden;
  }
  &__left{
    position: sticky;
  }
  &__right{
    padding-right: 20px;
    overflow: auto;
  }
  &__title{
    color: #3B3F47;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
  @media screen and (max-width: 800px) {
    padding: 30px 20px;
  }
  @media screen and (max-width: 479px) {
    &__body{
      flex-direction: column;
    }
  }
}

</style>
