<template>
	<svg
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="15" cy="15" r="14.75" stroke="#3B3F47" stroke-width="0.5" />
		<path
			d="M15.4688 16.1875V19.7656H14.2031V16.1875H10.8672V14.9219H14.2031V11.4297H15.4688V14.9219H18.8047V16.1875H15.4688Z"
			fill="#3B3F47"
		/>
	</svg>
</template>
