import {
	GET_BOOKING_LISTS_ACT,
	GET_HOUSING_OPTIONS_ACT
} from '@/constants/store/lists/actions'
import { SET_MUT } from '@/constants/store/lists/mutations'

export default {
	[GET_BOOKING_LISTS_ACT]: async function ({ commit, state }) {
		if (state.booking) {
			return { status: true }
		}

		const response = await this.$api.lists.getRejectAndComplaint()

		if (!response || !response.status) {
			return { status: false }
		}

		commit(SET_MUT, { key: 'booking', value: response.response })
	},
	[GET_HOUSING_OPTIONS_ACT]: async function ({ commit }) {
		if (state.housingOptions) {
			return { status: true }
		}

		const response = await this.$api.lists.getHousingOptions()

		if (!response || !response.status) {
			return { status: false }
		}

		commit(SET_MUT, { key: 'housingOptions', value: response.response })
	}
}
