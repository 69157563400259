<template>
	<div class="wrapper">
		<div class="amenities-modal">
			<div class="amenities-modal__container">
				<button type="button" class="amenities-modal__close-btn" @click="hide">
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.3 10.0006L19.4872 3.81336C20.1709 3.12973 20.1709 2.0214 19.4872 1.33884L18.6624 0.514003C17.9786 -0.169839 16.8702 -0.169839 16.1877 0.514003L10.0006 6.70105L3.81336 0.512721C3.12973 -0.170907 2.0214 -0.170907 1.33884 0.512721L0.512721 1.33756C-0.170907 2.0214 -0.170907 3.12973 0.512721 3.81229L6.70105 10.0006L0.514003 16.1877C-0.169839 16.8715 -0.169839 17.9798 0.514003 18.6624L1.33884 19.4872C2.02247 20.1709 3.1308 20.1709 3.81336 19.4872L10.0006 13.3L16.1877 19.4872C16.8715 20.1709 17.9798 20.1709 18.6624 19.4872L19.4872 18.6624C20.1709 17.9786 20.1709 16.8702 19.4872 16.1877L13.3 10.0006Z"
							fill="#3B3F47"
						/>
					</svg>
				</button>
				<template v-for="item in list" :key="item.title_group">
					<h3 v-if="item.options?.length" class="section-title">
						{{ item.title_group }}
					</h3>
					<ul v-if="item.options?.length" class="amenities-modal__list">
						<li
							v-for="unit in item.options"
							:key="unit.id"
							class="amenities-modal__item amenities-modal-item"
						>
              <tk-svg :type="icons[unit.id]" />
							<p class="amenities-modal-item__text">
								{{ unit.title }}

								<svg
									v-if="+unit.price"
									width="12"
									height="12"
									viewBox="0 0 12 12"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.3125 0.09375C9.48633 0.09375 11.25 1.79364 11.25 3.89062C11.25 5.98682 9.48633 7.6875 7.3125 7.6875H4.25V8.53125H5.625C6.10898 8.53125 6.5 8.9083 6.5 9.375C6.5 9.8417 6.10898 10.2188 5.625 10.2188H4.25V11.0625C4.25 11.5292 3.85898 11.9062 3.375 11.9062C2.89184 11.9062 2.5 11.5292 2.5 11.0625V10.2188H1.625C1.14184 10.2188 0.75 9.8417 0.75 9.375C0.75 8.9083 1.14184 8.53125 1.625 8.53125H2.5V7.6875H1.625C1.14184 7.6875 0.75 7.31045 0.75 6.84375C0.75 6.37705 1.14184 6 1.625 6H2.5V0.9375C2.5 0.471592 2.89184 0.09375 3.375 0.09375H7.3125ZM9.5 3.89062C9.5 2.7252 8.52109 1.78125 7.3125 1.78125H4.25V6H7.3125C8.52109 6 9.5 5.05605 9.5 3.89062Z"
										fill="#1AC386"
									/>
								</svg>
							</p>
						</li>
					</ul>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import amentities from "@/constants/amentities";

export default {
	name: 'AmentititesModal',
	props: {
		content: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		list() {
			return this.content.map((item) => ({
				...item,
				options: item.options.filter((itm) => itm.value)
			}))
		},
    icons() {
      return amentities
    }
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		}
	}
}
</script>

<style scoped lang="sass">
.wrapper
	display: flex
	align-items: center
	justify-content: center
	padding-left: 15px
	padding: 15px
</style>

<style lang="sass">
.amenities-modal
  position: relative
  align-items: center
  padding: 40px 110px
  max-width: 860px
  border-radius: 14px
  background: #ffffff

  &__container
    height: 600px
    overflow-y: auto

  &__close-btn
    position: absolute
    top: 20px
    right: 20px

    font-size: 20px
    color: #3b3f47

    border: none
    cursor: pointer
    background: transparent

  .section-title
    font-size: 22px
    line-height: 22px
    margin-bottom: 30px

  &__wrap
    display: flex
    justify-content: space-between

  &__list
    padding-left: 0
    margin-bottom: 30px
    list-style: none
    column-count: 2

  &__item:not(:last-child)
    margin-bottom: 20px

  .amenities-modal-item
    display: flex
    justify-self: flex-start

    font-size: 18px
    line-height: 22px

    &__text
      margin: 0
      padding-left: 10px

@media screen and (max-width: 800px)
  .amenities-modal
    padding: 30px 20px

    &__wrap
      flex-direction: column

    &__list
      column-count: 1
</style>
