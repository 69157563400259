<template>
	<ElConfigProvider :locale="ru">
		<div  class="main-container layout" :class="{'main-container-map' : $route.name === 'Places'}">
			<Header />
			<router-view class="main-container__main" />
			<Footer class="main-container__footer" />
		</div>
		<Modal />
		<Notification :time="4000" />
	</ElConfigProvider>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import { GET_AVATAR } from '@/constants/store/avatar/actions'
import { GET as GET_USER } from '@/constants/store/user/actions'
import ru from 'element-plus/lib/locale/lang/ru'

import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Modal from '@/components/Modal'
import Notification from '@/components/Notification'
import Chat from "@/chat/Chat";

export default {
	components: { Header, Footer, Modal, Notification, ElConfigProvider },
  inject: ['chat'],
	computed: {
		ru() {
			return ru
		},
	},
	async mounted() {
		const { getters, dispatch } = this.$store

		if (getters.isAuth) {
			// init action
      await dispatch('init')
			await dispatch(GET_USER)
			await dispatch(GET_AVATAR)

      const access = this.$store.state?.auth?.tokens?.access
      if (access) this.chat.connect(access)
		}
	}
}
</script>

<style lang="sass">
@import @/styles/index.sass

.main-container
  min-height: 100vh
  display: flex
  flex-direction: column
  &__main
    flex: 1 1 auto
  &-map
    .main-container__footer
      padding-top: 0
  &__footer
    margin-top: auto
    margin-bottom: 0
</style>
