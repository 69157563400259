<template>
	<svg
		width="16"
		height="14"
		viewBox="0 0 16 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.6673 13V2.33333C10.6673 1.97971 10.5268 1.64057 10.2768 1.39052C10.0267 1.14048 9.68761 1 9.33398 1H6.66732C6.3137 1 5.97456 1.14048 5.72451 1.39052C5.47446 1.64057 5.33398 1.97971 5.33398 2.33333V13M2.66732 3.6665H13.334C14.0704 3.6665 14.6673 4.26346 14.6673 4.99984V11.6665C14.6673 12.4029 14.0704 12.9998 13.334 12.9998H2.66732C1.93094 12.9998 1.33398 12.4029 1.33398 11.6665V4.99984C1.33398 4.26346 1.93094 3.6665 2.66732 3.6665Z"
			stroke="#CBCBCB"
      class="active-stroke"
			stroke-width="1.67"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
