import axios from 'axios'

import store from '@/store'

import { REFRESH_TOKEN } from '@/constants/store/auth/actions'
import { LOGOUT } from '@/constants/store/auth/actions'

const httpInstance = axios.create({
	baseURL: process.env.VUE_APP_API_URL + '/api/v1/',
	timeout: 20000,
	headers: {
		Accept: 'application/json'
	},
	withCredentials: false
})

// Interceptors
httpInstance.interceptors.request.use(
	(config) => {
		config.headers.common = {
			...config.headers.common,
			Locale: store.state.language
		}

		if (store.state.auth.tokens.access) {
			config.headers.common = {
				...config.headers.common,
				Authorization: `Bearer ${store.state.auth.tokens.access}`
			}
		}

		return config
	},
	function (error) {
		if (error.request) {
			return {
				error: error.request
			}
		} else {
			return {
				error
			}
		}
	}
)

httpInstance.interceptors.response.use(
	({ data }) => data,
	async function (error) {
		
		if (error.response?.status === 429) {
			return {
				status: 429,
				error: error.response.data.errors,
				retryAfter: error.response.headers['retry-after']
			}
		}

		if (error.response?.status === 404) {
			return {
				code: 404,
				...error.response.data
			}
		}

		if (error.response?.status === 401) {
			// need testing. Сan skip multiple requests
			return store
				.dispatch(REFRESH_TOKEN)
				.then((response) => {
					error.response.config.headers['Authorization'] =
						'Bearer ' + response.access_token

					return httpInstance(error.response.config)
				})
				.catch(() => {
					store.commit('hideModal')
					
					return store.dispatch(LOGOUT)
				})
		}

		if (error.response && error.response.data && error.response.data.errors) {
			return error.response.data
		}

		return {
			error
		}
	}
)
// Interceptors

// create services object
const requireComponent = require.context('../services', false, /[a-z].js/)

let api = {}

requireComponent.keys().forEach((fn) => {
	const createServiceApi = requireComponent(fn).default
	const serviceName = fn
		.split('/')
		.pop()
		.replace(/\.\w+$/, '')

	api = Object.assign(api, {
		[serviceName]: createServiceApi(httpInstance)
	})
})
// create services object

export { httpInstance, api }
