export default (axios) => ({
	create({
		place_id,
		guests,
		children,
		date_from,
		date_to,
		amenities,
		room_id,
		bed_ids,
		dry = 0
	}) {
		bed_ids = bed_ids.join(',')
		room_id = room_id ? (Array.isArray(room_id) ? room_id.join(',') : room_id) : ''
		amenities = amenities ? (Array.isArray(amenities) ? amenities.join(',') : amenities) : ''
		return axios.post(`place/client/reserves`, {
			place_id,
			guests,
			children,
			date_from,
			date_to,
			amenities,
			room_id,
			bed_ids,
			dry
		})
	},
	get({ page = 1, type }, { controller } = { controller: new AbortController() }) {
		return axios.get(`place/client/reserves?page=${page}&type=${type}`, { signal: controller.signal })
	},
	getReserve({ reserve_id }) {
		return axios.get(`place/client/reserves/${reserve_id}`)
	},
	update({ reserve_id, guests, children, date_from, date_to }) {
		return axios.patch(`place/client/reserves/${reserve_id}`, {
			guests,
			children,
			date_from,
			date_to
		})
	},
	getUsersReservesByDate({ place_id, date_from, date_to }) {
		return axios.get(`place/client/reserves/place`, {
			params: { place_id, date_from, date_to }
		})
	},
	getReservesDatesPlace({ place_id }) {
		return axios.get(
			`place/client/reserves/booking-dates/?place_id=${place_id}`
		)
	},
  cancelBooking({ reserve_id, status = 7, reason_code, message }) {
		const payload = {
			status,
			reason_code: [reason_code]
		}

		if (message) {
			payload.message = message
		}

		return axios.post(`place/client/reserves/${reserve_id}/status`, payload)
	},
  getReasonRejectClient({ reserve_id }) {
		return axios.get(`place/client/reserves/${reserve_id}/reject_status`)
	},

  // Owner
  getReserves({ page, type = 'past' }, { controller } = { controller: new AbortController() }) {
		return axios.get(`place/owner/reserves/type/${type}?page=${page}`, { signal: controller.signal })
	},
  getOwnerReserve({ reserve_id }) {
    return axios.get(`place/owner/reserves/${reserve_id}`)
  },
	getNewBookingsCount(type = 'future') {
		return axios.get(`place/owner/reserves/type/${type}?page=1&limit=1&status=1`)
	},
	toggleBookingOwner({ reserve_id, reason_code, message, isAllow = false }) {
    // isAllow: true/false
		const payload = {
			status: isAllow ? 2 : 6
		}

		if (message) {
			payload.message = message
		}

    if (reason_code) {
      payload.reason_code = [reason_code]
    }

		return axios.post(`place/owner/reserves/${reserve_id}/status`, payload)
	},
	getReasonRejectOwner({ reserve_id }) {
		return axios.get(`place/owner/reserves/${reserve_id}/reject_status`)
	},
	getFreeDates({ place_id, date_from, date_to }) {
		return axios.get(`place/client/reserves/free`, {
			params: { place_id, date_from, date_to }
		})
	},
})
