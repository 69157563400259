export default (axios) => ({
	/**
	 * Получить список статей(с поиском по заголовку)
	 *
	 * @param { string } - search (optional): поиск по заголовкам новостей
	 * @param { array } - type[]: роли статей: 1 - общие; 2 - для гостя; 3 - для хозяина
	 * @param { string } - page (optional)
	 * @param { string } - limit (optional)(default: 9)
	 * @param { string } - descriptionField (optional)(default: content) - Какое поле выводить в description:
	 * 										content - выдается поле полного описания статьи
	 * 										description - выдается поле описание (по умолчанию)
	 * 
	 * @headers { string } - service-lang
	 *
	 */
	getAll ({ limit = 9, page = 1, type = 1, descriptionField = 'description' },
					{ controller } = { controller: new AbortController() }) {
		return axios.get(`account/articles?page=${page}&limit=${limit}&type[]=${type}&description_field=${descriptionField}`,
			{ signal: controller.signal })
	},
	/**
	 * Получить статью
	 *
	 * @param { string|integer } - alias_or_id: Показывает новость либо по id, либо по alias
	 *
	*/
	get ({ id }, { controller } = { controller: new AbortController() }) {
		return axios.get(`account/articles/${id}`, { signal: controller.signal })
	}
})
