export const socials = [
	{ title: 'Ссылка на телеграм', icon: 'tg', link: 'https://t.me/glitch999999' },
	{ title: 'Ссылка на ютуб', icon: 'yt', link: 'https://www.youtube.com/@teremok_' }
]

export const menu = [
	{
		title: 'О НАС',
		isShow: true,
		links: [
			{
				title: 'О нас',
				link: '/about',
				isDisabled: false,
				isShow: true
			},
			// {
			// 	title: 'Правила объявлений',
			// 	link: '',
			// 	isDisabled: false,
			// 	isShow: true
			// },
			{ title: 'Контакты', link: '/contacts', isDisabled: false, isShow: true },
			{ title: 'Реквизиты', link: '/organisation-details', isDisabled: false, isShow: true },
		]
	},
	// {
	// 	title: 'СООБЩЕСТВО',
	// 	isShow: true,
	// 	links: [
	// 		{
	// 			title: 'Личностное многообразие и принятие',
	// 			link: '',
	// 			isDisabled: false,
	// 			isShow: true
	// 		},
	// 		{ title: 'Доступность', link: '', isDisabled: false, isShow: true },
	// 		{
	// 			title: 'Жилье на время кризиса',
	// 			link: '',
	// 			isDisabled: false,
	// 			isShow: true
	// 		},
	// 		{ title: 'Пригласите друзей', link: '', isDisabled: false, isShow: true }
	// 	]
	// },
	// {
	// 	title: 'ПРИНИМАЙТЕ ГОСТЕЙ',
	// 	isShow: true,
	// 	links: [
	// 		{ title: 'Сдайте жилье', link: '', isDisabled: false, isShow: true },
	// 		{
	// 			title: 'Организуйте онлайн-впечатление',
	// 			link: '',
	// 			isDisabled: false,
	// 			isShow: true
	// 		},
	// 		{
	// 			title: 'Организуйте Впечатление',
	// 			link: '',
	// 			isDisabled: false,
	// 			isShow: true
	// 		},
	// 		{
	// 			title: 'Обращение генерального директора Брайана Чески',
	// 			link: '',
	// 			isDisabled: false,
	// 			isShow: true
	// 		},
	// 		{
	// 			title: 'Ответственный прием гостей',
	// 			link: '',
	// 			isDisabled: false,
	// 			isShow: true
	// 		},
	// 		{ title: 'Open Homes', link: '', isDisabled: false, isShow: true },
	// 		{ title: 'Центр ресурсов', link: '', isDisabled: false, isShow: true },
	// 		{ title: 'Центр общения', link: '', isDisabled: false, isShow: true }
	// 	]
	// },
	// {
	// 	title: 'ПОДДЕРЖКА',
	// 	isShow: true,
	// 	links: [
	// 		{
	// 			title: 'Новости о коронавирусе',
	// 			link: '',
	// 			isDisabled: false,
	// 			isShow: true
	// 		},
	// 		{ title: 'Центр помощи', link: '', isDisabled: false, isShow: true },
	// 		{ title: 'Варианты отмены', link: '', isDisabled: false, isShow: true },
	// 		{ title: 'Поддержка соседей', link: '', isDisabled: false, isShow: true },
	// 		{
	// 			title: 'Доверие и безопасность',
	// 			link: '',
	// 			isDisabled: false,
	// 			isShow: true
	// 		}
	// 	]
	// }
]

export const ymaps_config = {
	apiKey: process.env.VUE_APP_YMAPS_KEY,
	lang: 'ru_RU',
	coordorder: 'latlong',
	version: '2.1'
}
