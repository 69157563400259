<template>
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.8337 1.75H1.16699L5.83366 7.26833V11.0833L8.16699 12.25V7.26833L12.8337 1.75Z"
			stroke="#3B3F47"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
