import { GET_ACT, GET_ADDITIVE_ACT, GET_MARKERS_ACT } from "@/constants/store/search/actions";
import { SET_ADDITIVE_MUT, SET_MUT } from "@/constants/store/search/mutations";

export default {
  [GET_ACT]: async function ({ commit }, payload) {
    const places = await this.$api.search.getPlaces(payload)

    if (places && places.status) {
      commit(SET_MUT, { key: 'places', value: places.response })
    } else {
      return places.response
    }
  },
  [GET_ADDITIVE_ACT]: async function ({ commit }, payload) {
    const places = await this.$api.search.getPlaces(payload)

    if (places && places.status) {
      commit(SET_ADDITIVE_MUT, { key: 'places', value: places.response })
    } else {
      return places.response
    }
  },
  [GET_MARKERS_ACT]: async function ({ commit }, payload) {
    const response = await this.$api.search.getMarkers(payload)

    if (response.status) {
      commit(SET_MUT, { key: 'markers', value: response.response })
    } else {
      return response
    }
  }
}