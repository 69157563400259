<template>
	<div v-if="content && content.last_page > 1" class="pagination">
		<ul class="pagination__list">
			<li
				v-show="content.current_page > 1"
				class="pagination__item pagination__toggle pagination__toggle--prev"
				@click.prevent="change(content.current_page - 1)"
			/>
			<template v-for="page in paginationRange" :key="page">
				<li
					@click="change(page)"
					:class="[
						'pagination__item',
						{ 'pagination__item--active': page === content.current_page },
						{ 'pagination__item--dots': page === DOTS }
					]"
				>
					{{ page === DOTS ? `&#8230;` : page }}
				</li>
			</template>
			<li
				v-show="content.current_page < content.last_page"
				class="pagination__item pagination__toggle pagination__toggle--next"
				@click.prevent="change(content.current_page + 1)"
			/>
		</ul>
		<!-- <div class="pagination__additional">
			{{ content.current_page }} - {{ content.from }} Объявлений на странице из {{ content.total }}
		</div> -->
	</div>
</template>

<script>
const DOTS = '...'

export default {
	props: {
		content: {
			type: Object,
			required: true,
			default: () => ({
				current_page: 0,
				from: 0,
				last_page: 0,
				per_page: 0,
				to: 0,
				total: 0
			})
		},
		offsetPage: {
			type: [Number, String],
			default: 0
		},
    /**
     * Количество номеров страниц всегда отображаемых в начале и конце
     */
    tailSize: {
      type: Number,
      default: 1,
    },
    /**
     * 2n+1, где n = midSize
     * Количество номеров страниц, которые отображаются между пропусками
     */
    midSize: {
      type: Number,
      default: 1,
    },
	},
	computed: {
		DOTS() {
			return DOTS
		},
    paginationRange() {
      const pagination = []
      const pages = this.content.last_page
      const currentPage = this.content.current_page

      for(let i = 1; i <= pages; i++) {
        pagination.push(i)

        // проверяем на первое место постановки точечек:
        // после начальных номеров страниц, если следующая за ними
        // не принадлежит центральному диапазону (окружности
        // с центром в currentPage радиуса midSize;
        // в противном случае на второе:
        // в конце центрального диапазона, если за ним не следует
        // номер, принадлежащий последним всегда отображаемым номерам
        if (i === this.tailSize
          && this.tailSize < currentPage - this.midSize - 1)
        {
          pagination.push(DOTS)
          i = currentPage - this.midSize - 1
        } else if (i === currentPage + this.midSize
          && pages - this.tailSize > currentPage + this.midSize)
        {
          pagination.push(DOTS)
          i = pages - this.tailSize
        }
      }

      return pagination
    }
	},
	methods: {
		change(page) {
			if (page === this.DOTS) {
				return
			}

			this.$emit('next', page)
		}
	}
}
</script>

<style lang="sass">
.pagination
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  max-width: 310px
  margin: 0 auto

  &__list
    display: flex
    align-items: center
    margin-bottom: 10px
    padding: 0

    list-style: none

  &__item
    display: flex
    justify-content: center
    align-items: center
    width: 35px
    height: 35px
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 18px
    border-radius: 50%
    cursor: pointer
    transition: 0.1s linear

    &:not(:last-child):not(:first-child)
      margin-right: 15px

      &:hover
        background-color: $green

    &--active
      color: #ffffff

      background: $brown
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2)

      &:hover
        color: #ffffff

    &--dots
      padding-bottom: 10px

      font-weight: 700
      font-size: 18px
      line-height: 14px

  &__toggle
    background-repeat: no-repeat
    border: none

    background-position: center
    background-repeat: no-repeat
    background-image: url("~@/assets/images/svg/pagination/arrow.svg")

    &--next
      transform: rotate(180deg)

  &__additional
    font-size: 1.4rem
    font-weight: 300
    line-height: 1.8rem

@media screen and (max-width: 912px)
  .pagination
    order: 2

@media screen and (max-width: 800px)
  .pagination
    &__page
      width: 30px
      height: 30px

      &:not(:last-child)
        margin-right: 10px
</style>
