export default class DLListIterator {
  constructor(firstNode) {
    this._current = firstNode
  }

  next() {
    if (!this._current) {
      return {
        value: undefined,
        done: true
      }
    }

    const result = {
      value: this._current.value,
      done: false
    }
    this._current = this._current.next
    return result
  }
}