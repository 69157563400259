<template>
	<div class="info-modal">
		<div class="info-modal__logo">
			<img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
		</div>
		<p class="info-modal__text">
			Вы успешно изменили номер телефона учётной записи! Теперь для входа
			используйте новый номер телефона.
		</p>
		<a class="info-modal__button" @click="hide">Ок</a>
	</div>
</template>

<script>
export default {
	name: 'SuccessChangePhone',
	methods: {
		hide() {
			this.$store.commit('hideModal')
		}
	}
}
</script>
