import {
	SET_MUT,
	SET_FIELD_MUT,
	CLEAR_ALL_MUT
} from '@/constants/store/user/mutations'

export default {
	[SET_MUT]: (state, data) => (state = Object.entries(data).forEach(
		([key, value]) => (state[key] = value)
	)),
	[SET_FIELD_MUT]: (state, payload) => (state[payload.field] = payload.value),
	[CLEAR_ALL_MUT]: (state) => 
		Object.keys(state).forEach((key) => (state[key] = null))
}
