export function objToArr(obj, keys, values) {
	return obj
		? Object.entries(obj).map(([key, value]) =>
				values ? { [values]: value, [keys]: key } : { ...value, [keys]: key }
		  )
		: null
}

export function arrToObj(arr, key) {
	if (!Array.isArray(arr)) return arr

	const obj = {}

	arr.forEach((itm) => {
		obj[itm[key]] = itm
	})

	return obj
}

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}