<template>
	<div class="authmodal">
		<div class="authmodal__header">
			<h3 class="title">{{ $t('authmodal.title.login') }}</h3>
			<tk-button
				class="authmodal__close"
				kind="close-modal"
				type="button"
				@click="hide"
			>
				Кнопка закрытия модального окна
			</tk-button>
		</div>

		<div class="authmodal__access">
			<form @submit.prevent="submit" class="authmodal__form authform">
				<div class="authform__item authform__item--login">
					<tk-input
            type="tel"
						:label="$t('form.fields.labels.login')"
						placeholder="+7 (000) 000 00 00"
						@input="clearError('phone')"
						v-model="phone"
						:error="errors.phone"
            v-phone
						required
					/>
				</div>

				<div class="authform__item authform__item--password">
					<tk-input
						:label="$t('form.fields.labels.password')"
						type="password"
						@input="clearError('password')"
						v-model="password"
						:error="errors.password"
						required
					/>
				</div>

				<a
					href="#"
					class="authmodal-button-link authform__forgot-password"
					@click.prevent="showRecovery"
				>
					{{ $t('authmodal.button.forget-password') }}
				</a>

				<tk-button
					type="submit"
					class="authform__submit"
					aria-label="Кнопка входа"
				>
					{{ $t('form.buttons.login') }}
				</tk-button>
			</form>

			<div class="authmodal__registration">
				{{ $t('authmodal.text.is-register') }}
				<a
					href="#"
					class="authmodal-button-link"
					@click.prevent="showRegistration"
				>
					{{ $t('form.buttons.register') }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import modals from '@/constants/modals'
import { AUTH } from '@/constants/store/auth/actions'
import { GET_AVATAR } from '@/constants/store/avatar/actions'

import errorHandler from '@/utils/errorHandler'

// refactoring handle errors

export default {
	name: 'Auth',
	mixins: [errorHandler],
  inject: ['chat'],
	data() {
		return {
			phone: '',
			password: ''
		}
	},
	created() {
		this._createFieldsError(['phone', 'password'])
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		},
		showRegistration() {
			this.$store.commit('showModal', {
				name: modals.REGISTRATION,
				isShow: true
			})
		},
		showRecovery() {
			this.$store.commit('showModal', {
				name: modals.RECOVERY,
				isShow: true
			})
		},
		filter(payload) {
			return payload.replace(/[^+\d]/g, '')
		},
		async submit() {
			const { dispatch } = this.$store

			const response = await dispatch(AUTH, {
				phone: this.filter(this.phone),
				password: this.password
			})

			if (response && response.errors) {
				this.setError({ error: response.errors })

				return
			}

			await dispatch(GET_AVATAR)
			localStorage.removeItem('flagsChangeAndSaveData')

      const access = this.$store.state.auth.tokens.access
      this.chat.connect(access)

			this.hide()

      this.$store.commit('showNotification', {
        type: 'success',
        text: 'Вы успешно авторизованы'
      })
		}
	}
}
</script>

<style lang="sass">
.authmodal
  .input-component__error
    position: static
    bottom: 0
    margin: 15px auto
  .authform__item--login
    .input-component__container
      order: 2
    .input-component__error
      margin: -20px auto 15px auto

</style>
