<template>
	<div class="guest-card">
    <p v-if="isConfirm !== null" class="guest-card__question">
      {{ question }}
    </p>
		<div class="guest-card__head">
			<div v-if="content.avatars?.length" class="userpic">
				<tk-image
          width="70"
          height="70"
          :src="content.avatars?.find((avatar) => avatar.is_active) ?? '/images/default-avatar.svg'"
        />
			</div>
			<div class="username-wrapper">
				<p class="username">{{ name }}</p>
				<router-link
					to="/profile/chat"
					@click.prevent="handlerContactWithGuest(content.user_id)"
					class="chat-link"
				>
					Написать
				</router-link>
			</div>
		</div>
		<h3 class="guest-card__room-name">
			{{ content.title }}
		</h3>
		<p class="guest-card__date">
			Дата: <span>{{ dates[0] }} – {{ dates[1] }}</span>
		</p>
		<p class="guest-card__payment">
			Оплачено: <span>{{ content.price?.toLocaleString() }} ₽</span>
		</p>
		<p class="guest-card__guests">
			Гостей:
			<span>
				{{ getGuestsTextFormat(content.guests) }},
				{{ getChildrenTextFormat(content.children) }}
			</span>
		</p>
	</div>
</template>

<script>
import { getUserName } from '@/utils/helpers'
import declension from '@/utils/declension'
import { getDateInFormat } from '@/utils/dateTime'

export default {
  inject: ['chat'],
	props: {
		isConfirm: {
			type: Boolean,
			default: null
		},
		content: {
			type: Object,
			default: () => ({
				isConfirm: null
			})
		}
	},
	computed: {
    question() {
      return this.isConfirm ? 'Принять гостей?' : 'Отклонить бронирование?'
    },
		name() {
			return getUserName(this.content)
		},
		dates() {
			if (this.content.date_from) {
				return [
					getDateInFormat(this.content.date_from),
					getDateInFormat(this.content.date_to)
				]
			} else {
				return []
			}
		}
	},
	methods: {
    handlerContactWithGuest(id) {
      this.chat.getOrCreateConversationWithUser(id)

      this.$router.push('/profile/chat')
    },
		getGuestsTextFormat(payload) {
			return `${payload} ${declension(
				['взрослый', 'взрослых', 'взрослые'],
				+payload
			)}`
		},
		getChildrenTextFormat(payload) {
			return `${payload} ${declension(['ребёнок', 'дети', 'детей'], +payload)}`
		}
	}
}
</script>

<style lang="sass">
.guest-card
  position: relative
  width: 345px
  padding: 18px 37px
  background: #FFFFFF
  border: 0.5px solid #CBCBCB
  box-sizing: border-box
  border-radius: 12px
  &__question
    margin: 0
    margin-bottom: 10px
    margin-left: -37px
    margin-right: -37px
    margin-top: -18px
    padding-bottom: 7px
    padding-top: 13px
    font-weight: 700
    font-size: 18px
    line-height: 21px
    color: #3B3F47
    text-align: center
    border-bottom: 0.5px solid #CBCBCB
    
  &__head
    display: flex
    align-items: center
    margin-bottom: 20px
    .userpic
      width: 70px
      height: 70px
      margin-right: 15px
      img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover
    .username-wrapper
      width: 60%
    .username
      font-size: 16px
      line-height: 16px
      font-weight: 600
      margin-bottom: 7px
    .chat-link
      position: relative
      font-weight: 500
      font-size: 14px
      line-height: 14px
      color: #CBCBCB
      padding-right: 30px
      &:hover
        color: #1ac386
        &:after
          content: url('~@/assets/images/svg/chats-green.svg')
      &:after
        content: url('~@/assets/images/svg/chats.svg')
        position: absolute
        right: 0
  &__room-name
    font-weight: 600
    font-size: 16px
    line-height: 18px
    margin-bottom: 15px
  &__date,
  &__payment,
  &__guests,
  &__reason
    margin: 0
    font-size: 12px
    line-height: 14px
    margin-bottom: 5px
    span
      font-weight: bold
  &__guests
    margin-bottom: 11px

@media screen and (max-width: 1024px)
  .guest-card
    width: 285px
    padding: 18px

@media screen and (max-width: 912px)
  .guest-card
    width: 345px
    padding: 18px 37px

// @media screen and (max-width: 800px)
//   .guest-card
// 			width: 96%
// 			margin: 0 7px
// 			&__head,
// 			&__room-name,
// 			&__date,
// 			&__payment,
// 			&__guests
// 				margin-left: 25px
// 				margin-right: 25px
</style>
