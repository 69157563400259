import setModuleName from '@/utils/setModuleName'

import { MODULE_NAME } from '.'

export const SEND_CODE_VERIFICATION_ACT = 'SEND_CODE_VERIFICATION'
export const CODE_IS_VALID_ACT = 'CODE_IS_VALID'
export const CHECK_PHONE_ACT = 'CHECK_PHONE'
export const REGISTER_ACT = 'REGISTER'
export const RECOVERY_ACT = 'RECOVERY'
export const AUTH_ACT = 'AUTH'
export const LOGOUT_ACT = 'LOGOUT'
export const REFRESH_TOKEN_ACT = 'REFRESH_TOKEN'

export const SEND_CODE_VERIFICATION = setModuleName(
	MODULE_NAME,
	SEND_CODE_VERIFICATION_ACT
)
export const CODE_IS_VALID = setModuleName(MODULE_NAME, CODE_IS_VALID_ACT)
export const CHECK_PHONE = setModuleName(MODULE_NAME, CHECK_PHONE_ACT)
export const REGISTER = setModuleName(MODULE_NAME, REGISTER_ACT)
export const RECOVERY = setModuleName(MODULE_NAME, RECOVERY_ACT)
export const AUTH = setModuleName(MODULE_NAME, AUTH_ACT)
export const REFRESH_TOKEN = setModuleName(MODULE_NAME, REFRESH_TOKEN_ACT)
export const LOGOUT = setModuleName(MODULE_NAME, LOGOUT_ACT)
