<template>
	<div class="thumb-example">
		<!-- <div class="thumb-example__buttons">
			<button
				@click="prev"
				type="button"
				class="thumb-example__button thumb-example__button--prev"
			/>
			<button
				@click="next"
				type="button"
				class="thumb-example__button thumb-example__button--next"
			/>
		</div> -->
		<!-- swiper1 -->
		<div class="owl-carousel gallery-top">
			<div v-for="item in content" :key="item.hash" class="gallery-top__item">
				<figure>
          <img
            :src="item.jpg[1920]['1x']"
            height="600"
            class="gallery-top__image"
          />
          <figcaption v-if="item.description">{{ item.description }}</figcaption>
        </figure>
			</div>
		</div>
		<!-- swiper2 Thumbs -->
		<!-- <div class="owl-carousel gallery-thumbs">
			<div
				v-for="item in content"
				:key="item.hash"
				class="gallery-thumbs__item"
			>
				<img
					:src="item.jpg[1920]['1x']"
					width="200"
					height="130"
					class="gallery-thumbs__image"
				/>
			</div>
		</div> -->

		<button type="button" @click="hide" class="thumb-example__close" />
	</div>
</template>

<script>
let owl

export default {
	name: 'PlaceCarousel',
	props: {
		content: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		},
    owlKyeboardNavigation(event) {
      if (event.keyCode === 37) {
        owl.trigger('prev.owl')
      }
      if (event.keyCode === 39) {
        owl.trigger('next.owl')
      }
    }
	},
	mounted() {
		owl = $('.gallery-top').owlCarousel({
			items: 1,
			nav: true,
			dots: false,
			margin: 2
		})

    window.addEventListener('keydown', this.owlKyeboardNavigation)
	},
  beforeUnmount() {
    window.removeEventListener('keydown', this.owlKyeboardNavigation)
  }
}
</script>

<style scoped>
figure {
  display: flex;
  flex-flow: column;
}


figcaption {
  background-color: #222;
  color: #fff;
  font: italic smaller sans-serif;
  padding: 3px;
  text-align: center;
  position: absolute;
  z-index: auto;
  bottom: 0;
  align-self: center;
  width: 100%;
}
</style>
