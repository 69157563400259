<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.267 10.7991C10.654 10.9466 10.8483 11.3799 10.7008 11.767L6.70082 22.267C6.55337 22.6541 6.12004 22.8483 5.73296 22.7009C5.34588 22.5534 5.15163 22.1201 5.29909 21.733L9.29909 11.233C9.44655 10.8459 9.87988 10.6517 10.267 10.7991Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.733 10.7991C14.12 10.6517 14.5534 10.8459 14.7008 11.233L18.7008 21.733C18.8483 22.1201 18.654 22.5534 18.267 22.7009C17.8799 22.8483 17.4466 22.6541 17.2991 22.267L13.2991 11.767C13.1516 11.3799 13.3459 10.9466 13.733 10.7991Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 17C7.25 16.5858 7.58579 16.25 8 16.25H16C16.4142 16.25 16.75 16.5858 16.75 17C16.75 17.4142 16.4142 17.75 16 17.75H8C7.58579 17.75 7.25 17.4142 7.25 17Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2.75C9.86192 2.75 9.75 2.86192 9.75 3V6C9.75 6.41421 9.41421 6.75 9 6.75H7.75V9.41665C7.75 9.85052 7.90258 10.1641 8.1318 10.3742C8.36693 10.5897 8.74281 10.75 9.27275 10.75H14.7273C15.2572 10.75 15.6331 10.5897 15.8682 10.3742C16.0974 10.1641 16.25 9.85052 16.25 9.41665V6.75H15C14.5858 6.75 14.25 6.41421 14.25 6V3C14.25 2.86192 14.1381 2.75 14 2.75H10ZM8.25 3C8.25 2.03351 9.03348 1.25 10 1.25H14C14.9665 1.25 15.75 2.03351 15.75 3V5.25H17C17.4142 5.25 17.75 5.58579 17.75 6V9.41665C17.75 10.2328 17.448 10.9609 16.8818 11.4799C16.3215 11.9936 15.561 12.25 14.7273 12.25H9.27275C8.43904 12.25 7.67854 11.9936 7.11821 11.4799C6.55197 10.9609 6.25 10.2328 6.25 9.41665V6C6.25 5.58579 6.58579 5.25 7 5.25H8.25V3Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 6C14.25 5.58579 14.5858 5.25 15 5.25H19C19.4142 5.25 19.75 5.58579 19.75 6C19.75 6.41421 19.4142 6.75 19 6.75H15C14.5858 6.75 14.25 6.41421 14.25 6Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 6C4.25 5.58579 4.58579 5.25 5 5.25H9C9.41421 5.25 9.75 5.58579 9.75 6C9.75 6.41421 9.41421 6.75 9 6.75H5C4.58579 6.75 4.25 6.41421 4.25 6Z" fill="#767582"/>
  </svg>
</template>
