<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10 15.3325H16M13 4.33218C13.2652 4.06697 13.6249 3.91797 14 3.91797C14.1857 3.91797 14.3696 3.95455 14.5412 4.02562C14.7128 4.09669 14.8687 4.20086 15 4.33218C15.1313 4.4635 15.2355 4.61941 15.3066 4.79099C15.3776 4.96257 15.4142 5.14647 15.4142 5.33218C15.4142 5.5179 15.3776 5.7018 15.3066 5.87338C15.2355 6.04496 15.1313 6.20086 15 6.33218L6.66667 14.6655L4 15.3322L4.66667 12.6655L13 4.33218Z"
			stroke="white"
			stroke-width="1.67"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
