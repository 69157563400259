import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  state: {
    booking: null,
    housingOptions: null
  },
  actions,
  mutations,
  getters,
  namespaced: true
}