<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.61414 18.6441C3.97285 13.7079 0 11.0932 0 6.93418C0 3.78914 2.24219 1.21094 5.3125 1.21094C7.44312 1.21094 9.0241 2.51941 10 4.37559C10.9758 2.51953 12.5568 1.21094 14.6875 1.21094C17.7582 1.21094 20 3.78965 20 6.93418C20 11.0927 16.0323 13.7034 10.3859 18.6441C10.1649 18.8374 9.83508 18.8374 9.61414 18.6441Z"
			fill="white"
      class="active"
			fill-opacity="0.9"
		/>
		<path
			d="M14.6875 1.21094C12.5569 1.21094 10.9759 2.51941 10 4.37559C9.02418 2.51953 7.4432 1.21094 5.3125 1.21094C2.24184 1.21094 0 3.78965 0 6.93418C0 11.0927 3.96766 13.7034 9.61414 18.6441C9.83512 18.8374 10.1649 18.8373 10.3859 18.6441C16.0271 13.7079 20 11.0932 20 6.93418C20 3.78914 17.7578 1.21094 14.6875 1.21094ZM10 17.4258C4.79992 12.9088 1.17188 10.3996 1.17188 6.93418C1.17188 4.33949 2.95195 2.38281 5.3125 2.38281C7.58797 2.38281 8.99809 4.49012 9.43524 6.05453C9.59289 6.62523 10.4052 6.62777 10.5648 6.05453C10.9961 4.51086 12.3899 2.38281 14.6875 2.38281C17.048 2.38281 18.8281 4.33949 18.8281 6.93418C18.8281 10.4016 15.1941 12.914 10 17.4258Z"
      fill="#1AC386"
		/>
	</svg>
</template>
