import { SEARCH_MODULE } from '.'
import setModuleName from '@/utils/setModuleName'

export const SET_MUT = 'SET'
export const UPDATE_MUT = 'UPDATE'
export const SET_ADDITIVE_MUT = 'SET_ADDITIVE'

export const SET = setModuleName(SEARCH_MODULE, SET_MUT)
export const SET_ADDITIVE = setModuleName(SEARCH_MODULE, SET_ADDITIVE_MUT)
export const UPDATE = setModuleName(SEARCH_MODULE, UPDATE_MUT)
