<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" rx="8" fill="white"/>
    <g clip-path="url(#clip0_4614_21674)">
      <path d="M14 5.66675V7.33341M14 20.6667V22.3334M7.33334 14.0001H5.66667M9.26177 9.26184L8.08326 8.08333M18.7382 9.26184L19.9168 8.08333M9.26177 18.7417L8.08326 19.9203M18.7382 18.7417L19.9168 19.9203M22.3333 14.0001H20.6667M18.1667 14.0001C18.1667 16.3013 16.3012 18.1667 14 18.1667C11.6988 18.1667 9.83334 16.3013 9.83334 14.0001C9.83334 11.6989 11.6988 9.83341 14 9.83341C16.3012 9.83341 18.1667 11.6989 18.1667 14.0001Z" stroke="#767582" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_4614_21674">
        <rect width="20" height="20" fill="white" transform="translate(4 4)"/>
      </clipPath>
    </defs>
  </svg>
</template>
