export default (axios) => ({
	/**
	 * Добавить контактное лицо
	 *
	 * @param { string } - name: Обязательное. Имя
	 * @param { string } - phone: Обязательное. Телефон
	 * @param { string } - relation: Необязательное. Кем приходится (мама, папа, друг и т.д.)
	 *
	 */
	setContactPerson(payload) {
		return axios.post(`account/user/contact-person`, payload)
	},
	/**
	 * Обновить профиль
	 *
	 * @param { string } - name: Имя
	 * @param { string } - surname: Фамилия
	 * @param { string } - patronymic: Отчество
	 * @param { string } - gender: Пол
	 * @param { string } - birth: Дата рождения - "1990-10-25",
	 * @param { string } - email: Email
	 *
	 *
	 */
	update(payload) {
		return axios.patch(`account/user`, payload)
	},
	get(mode = 'GUEST') {
		return axios.get(`account/user?user_role=${mode}`)
	},
  getDescriptionUser({ user }, { controller } = { controller: new AbortController() }) {
    return axios.get(`account/users?users[]=${user}&with=hobbies`, { signal: controller.signal })
  },
	uploadUserPhoto(payload) {
		return axios.post(`account/user/confirmation-images/upload-face`, payload)
	},
	uploadDoc(payload) {
		return axios.post(
			`account/user/confirmation-images/upload-document`,
			payload
		)
	},
	changePhone({ old_phone, old_phone_code, new_phone, new_phone_code }) {
		return axios.patch('account/user/change-phone', {
			old_phone,
			old_phone_code,
			new_phone,
			new_phone_code
		})
	}
})
