<template>
	<svg
		width="22"
		height="24"
		viewBox="0 0 22 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21.3338 11.5116L11.761 21.0844V21.074C9.31307 23.5115 5.35478 23.5115 2.9173 21.074C0.469409 18.6261 0.469409 14.6678 2.90688 12.2304L12.4797 2.65754H12.4693C14.0943 1.02214 16.7297 1.02214 18.3651 2.64713C19.99 4.27211 19.99 6.9075 18.3651 8.5429L8.78183 18.1157V18.1053C7.95892 18.9178 6.64643 18.9178 5.83394 18.1053C5.01103 17.2824 5.01103 15.9699 5.82352 15.1574L14.6672 6.32417"
			stroke="white"
			stroke-width="1.33332"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
