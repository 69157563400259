<template>
	<img
		class="image-component"
		decoding="async"
    loading="lazy"
		v-bind="$attrs"
    :src="main"
    :srcset="srcset"
		:sizes="sizes"
    :width="width ?? 'auto'"
    :height="height ?? 'auto'"
    ref="img"
	/>
</template>

<script setup>
import { objToArr } from '@/utils/normalizeData'
import { computed, onMounted, ref, watchEffect } from "vue";
import { useStore } from "vuex";

// refactoring

const props = defineProps({
  src: {
    type: [Object, String],
    default: null
  },
  isDependsOnOriginal: {
    // if true - load original field
    type: Boolean,
    default: false
  },
  width: [Number, String],
  height: [Number, String]
})

const store = useStore()

const main = ref('')
const srcset = ref([])
const sizes = ref([])
const img = ref(null)

const isString = computed(() => {
  return typeof props.src === 'string'
})

const isSupportWebp = computed(() => {
  return store.state.isSupportWebp
})

watchEffect(() => {
  if (!props.src) {
    main.value = ''
  } else if (isString.value) {
    main.value = props.src
  } else if (props.isDependsOnOriginal && props.src.origin) {
    main.value = props.src.origin
  } else {
    const arrImage = objToArr(
      isSupportWebp.value ? props.src.webp : props.src.jpg,
      'key',
      'value'
    )

    const newsrcset = []
    const newsizes = []

    let targetWidth;
    if (props.width) {
      let width, height
      if (typeof props.width === 'number' ||
        parseInt(props.width).toString() === props.width.toString()
      ) {
        width = parseInt(props.width)
        height = parseInt(props.height)
      } else {
        width = props.width
        height = props.height
      }
      if (typeof width === 'number') {
        targetWidth = parseInt(Math.max(width, height * 16 / 9).toFixed(0))
      } else {
        targetWidth = props.width
      }
    }

    if (arrImage) {
      arrImage.forEach((item) => {
        if (parseInt(item.key) === 0) item.key = 128
        newsrcset.push(`${item.value['1x']} ${item.key}w`)
        if (!targetWidth) {
          newsizes.push(`(max-width: ${item.key}px) ${item.key}px`)
        } else if (typeof targetWidth === 'number') {
          newsizes.push(`(max-width: ${item.key}px) ${Math.min(parseInt(item.key), targetWidth)}px`)
        }
      })
      if (targetWidth && typeof targetWidth !== 'number') newsizes.push(targetWidth)
      main.value = ''
      srcset.value = newsrcset
      sizes.value = newsizes
    }
  }
})

onMounted(() => {
  img.value.addEventListener('error', () => {
    if (props.src?.origin) {
      main.value = props.src.origin
      srcset.value = []
      sizes.value = []
    }
  })
})
</script>
