<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.9477 7.24179C19.816 6.83681 19.4568 6.5501 19.0335 6.5118L13.2601 5.98765L10.9784 0.645763C10.81 0.2533 10.4267 0 10 0C9.57338 0 9.18992 0.2533 9.02253 0.645763L6.74085 5.98765L0.96652 6.5118C0.543234 6.55086 0.184799 6.83758 0.0523506 7.24179C-0.0793349 7.64676 0.0422796 8.09096 0.362414 8.37172L4.72665 12.1985L3.43986 17.8661C3.34571 18.2828 3.50746 18.7137 3.85323 18.9636C4.03908 19.0987 4.25744 19.1661 4.47656 19.1661C4.66485 19.1661 4.8533 19.1161 5.02161 19.0154L10 16.0386L14.9775 19.0154C15.3427 19.2336 15.8018 19.2136 16.1468 18.9636C16.4926 18.7137 16.6543 18.2828 16.5602 17.8661L15.2734 12.1985L19.6376 8.37172C19.9576 8.09096 20.0794 7.64768 19.9477 7.24179Z"
			fill="#1AC386"
		/>
	</svg>
</template>
