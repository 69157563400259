<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.1667 9.16667C18.7067 9.16667 18.3333 9.54 18.3333 10C18.3333 14.595 14.595 18.3333 10 18.3333C5.405 18.3333 1.66667 14.595 1.66667 10C1.66667 5.405 5.405 1.66667 10 1.66667C12.2371 1.66667 14.3371 2.54083 15.9133 4.12833C16.2375 4.45458 16.7654 4.45667 17.0917 4.1325C17.4183 3.80833 17.42 3.28042 17.0958 2.95375C15.2046 1.04917 12.6842 0 10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5137 20 20 15.5142 20 10C20 9.54 19.6267 9.16667 19.1667 9.16667Z"
			fill="white"
		/>
		<path
			d="M11.1765 9.9973L13.0874 8.08646C13.4128 7.76104 13.4128 7.23354 13.0874 6.90813C12.762 6.58271 12.2345 6.58271 11.909 6.90813L9.99821 8.81896L8.08738 6.90813C7.76237 6.58271 7.23404 6.58271 6.90904 6.90813C6.58362 7.23354 6.58362 7.76104 6.90904 8.08646L8.81988 9.9973L6.90904 11.9081C6.58362 12.2335 6.58362 12.761 6.90904 13.0865C7.07154 13.2494 7.28487 13.3306 7.49821 13.3306C7.71154 13.3306 7.92488 13.2494 8.08738 13.0865L9.99821 11.1756L11.909 13.0865C12.072 13.2494 12.2849 13.3306 12.4982 13.3306C12.7115 13.3306 12.9245 13.2494 13.0874 13.0865C13.4128 12.761 13.4128 12.2335 13.0874 11.9081L11.1765 9.9973Z"
			fill="white"
		/>
	</svg>
</template>