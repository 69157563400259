<template>
	<svg
		width="10"
		height="6"
		viewBox="0 0 10 6"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.54783 5.67939C9.80049 5.4162 9.80049 5.00055 9.54783 4.73736L5 -2.18557e-07L0.452168 4.73736C0.199512 5.00055 0.199511 5.4162 0.452167 5.67939C0.71984 5.95822 1.16578 5.95822 1.43345 5.67939L5 1.9642L8.56655 5.67939C8.83422 5.95822 9.28016 5.95822 9.54783 5.67939Z"
			fill="#3B3F47"
		/>
	</svg>
</template>
