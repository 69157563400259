<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19 2.25C19.4142 2.25 19.75 2.58579 19.75 3V22C19.75 22.4142 19.4142 22.75 19 22.75C18.5858 22.75 18.25 22.4142 18.25 22V3C18.25 2.58579 18.5858 2.25 19 2.25Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 20C4.25 19.5858 4.58579 19.25 5 19.25H19C19.4142 19.25 19.75 19.5858 19.75 20C19.75 20.4142 19.4142 20.75 19 20.75H5C4.58579 20.75 4.25 20.4142 4.25 20Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 17C4.25 16.5858 4.58579 16.25 5 16.25H19C19.4142 16.25 19.75 16.5858 19.75 17C19.75 17.4142 19.4142 17.75 19 17.75H5C4.58579 17.75 4.25 17.4142 4.25 17Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 6C2.25 5.58579 2.58579 5.25 3 5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41421 21.4142 6.75 21 6.75H3C2.58579 6.75 2.25 6.41421 2.25 6Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.75 3C16.75 2.58579 17.0858 2.25 17.5 2.25H20.5C20.9142 2.25 21.25 2.58579 21.25 3C21.25 3.41421 20.9142 3.75 20.5 3.75H17.5C17.0858 3.75 16.75 3.41421 16.75 3Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 3C2.75 2.58579 3.08579 2.25 3.5 2.25H6.5C6.91421 2.25 7.25 2.58579 7.25 3C7.25 3.41421 6.91421 3.75 6.5 3.75H3.5C3.08579 3.75 2.75 3.41421 2.75 3Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 2.25C5.41421 2.25 5.75 2.58579 5.75 3V22C5.75 22.4142 5.41421 22.75 5 22.75C4.58579 22.75 4.25 22.4142 4.25 22V3C4.25 2.58579 4.58579 2.25 5 2.25Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 5.25C8.91421 5.25 9.25 5.58579 9.25 6V14C9.25 14.4142 8.91421 14.75 8.5 14.75C8.08579 14.75 7.75 14.4142 7.75 14V6C7.75 5.58579 8.08579 5.25 8.5 5.25Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14V6C11.25 5.58579 11.5858 5.25 12 5.25Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 5.25C15.9142 5.25 16.25 5.58579 16.25 6V14C16.25 14.4142 15.9142 14.75 15.5 14.75C15.0858 14.75 14.75 14.4142 14.75 14V6C14.75 5.58579 15.0858 5.25 15.5 5.25Z" fill="#767582"/>
  </svg>
</template>
