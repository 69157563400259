<template>
	<div v-click-outside="outside" ref="dropdown" class="dropdown" :class="kind">
		<div @click="toggle" class="dropdown__toggle dropdown-tgl">
			<slot name="toggle" :active="isShow">
				<button type="button">close</button>
			</slot>
		</div>

		<transition :name="animation">
			<div v-if="isShow" @hide="hide" class="dropdown__content dropdown-content">
				<slot name="content" :active="isShow" />
			</div>
		</transition>
	</div>
</template>

<script>
import eventBus from '@/plugins/eventBus'

export default {
	props: {
		animation: {
			type: String,
			default: 'slide-fade'
		},
    kind: {
      type: String,
      default: ''
    },
    isDisabledOutsideHide: {
      type: Boolean,
      default: false
    }
	},
	data() {
		return {
			isShow: false
		}
	},
	watch: {
    isShow: function() {
      this.$emit('update', this.isShow)
    },
		'$route.name': function () {
			this.hide()
		}
	},
	methods: {
		toggle() {
			this.isShow = !this.isShow
		},
    outside() {
      if (!this.isDisabledOutsideHide) {
        this.hide()
      }
    },
		hide() {
			if (this.isShow) {
				this.isShow = false
			}
		}
	},
	beforeMount() {
		eventBus.on('dropdown-hide', this.hide)
	},
	unmounted() {
		eventBus.off('dropdown-hide', this.hide)
	}
}
</script>

<style lang="sass">
.dropdown
  position: relative

  &__toggle
    cursor: pointer

  &__content
    position: absolute
    left: 0
    right: 0
    margin: 0 auto
    z-index: 9999

  // Animation
  .slide-fade-enter-active
    transition: all 0.3s linear

  .slide-fade-leave-active
    transition: all 0.3s linear

  .slide-fade-enter-from,
  .slide-fade-leave-to
    transform: translateY(-10px)
    opacity: 0

  // kinds
  &.answer
    .dropdown-content
      position: static

@media screen and (max-width: 500px)
	.search-guests
		.dropdown
			&__content
				margin-top: 15px
				left: 50%
				right: unset
				transform: translateX(-50%)
</style>
