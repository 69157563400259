import {
	SET_MUT,
	CLEAR_MUT,
	UPDATE_MUT,
	DELETE_MUT
} from '@/constants/store/favourites/mutations'

export default {
	[SET_MUT]: (state, { key, value }) => (state[key] = value),
	[CLEAR_MUT]: (state, { key }) => (state[key] = null),
	[UPDATE_MUT]: (state, { id }) =>
		(state.content.items = state.content?.items.filter(
			(item) => item.id !== id
		)),
	[DELETE_MUT]: (state, { key, id }) =>
		(state[key] = state[key].filter((itm) => itm.id !== id))
}
