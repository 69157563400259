<template>
	<div class="info-modal">
		<div class="info-modal__logo">
			<img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
		</div>
		<p class="info-modal__text">
			Заявка на бронирование жилья успешно отправлена хозяину. Ожидайте
			подтверждения заявки хозяином.
		</p>
		<p class="info-modal__text info-modal__text--additional">
			*если хозяин отклонит заявку на бронирование, вы это увидите в статусе
			заявки, в разделе “мои поездки”.
		</p>
		<a class="info-modal__button" @click="submit">Ок</a>
	</div>
</template>

<script>
export default {
	name: 'BookingRequestSuccess',
	methods: {
		submit() {
			this.$store.commit('hideModal')

			setTimeout(() => this.$router.push('/'))
		}
	}
}
</script>
