import {
	MARKERS_GETTERS,
	CITY_CENTER_GETTERS
} from '@/constants/store/search/getters'
import { SET_ADDITIVE_MUT, SET_MUT, UPDATE_MUT } from "@/constants/store/search/mutations";

import actions from './actions'

export default {
	state: {
		places: {
			meta: {
				total: 0
			}
		},
		markers: { points: []}
	},
	mutations: {
		[SET_MUT]: (state, { key, value }) => {
			state[key] = value
		},
		// для подзагрузки при прокрутке
		[SET_ADDITIVE_MUT]: (state, { key, value }) => {
			if (!state.places.items) {
				state.places.items = []
			}

			// слияние массисов; при нем убираем повторения
			let i, j = 0
			for(; i < state.places.items.length; i++) {
				if (state.places.items[i] === value.items[j]) {
					j++
				}
			}

			for(; j < value.items.length; j++) {
				state.places.items.push(value.items[j])
			}

			state.places.meta = value.meta
		},
		[UPDATE_MUT]: (state, { id }) => {
			state.markers.points = state.markers?.points.filter((item) => item.place_id !== id)
		}
	},
	actions,
	getters: {
		[MARKERS_GETTERS]: (state) => {
			if (!Array.isArray(state.markers.points)) {
				state.markers.points = []
			}

			return state.markers.points.map((itm, i) => ({
				id: itm.place_id,
				price: itm.price,
				placemarkId: i,
				geometry: {
					type: 'Point',
					coordinates: [itm.location.lat, itm.location.lng]
				}
			}))},
		[CITY_CENTER_GETTERS]: (state) =>
			state.markers.city_center
				? [state.markers.city_center.lat, state.markers.city_center.lng]
				: [55.76, 37.64]
	},
	namespaced: true
}
