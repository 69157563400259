<template>
	<svg
		width="15"
		height="15"
		viewBox="0 0 15 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.4577 1.83464H11.041V1.1263C11.041 0.93844 10.9664 0.758273 10.8336 0.625435C10.7007 0.492596 10.5205 0.417969 10.3327 0.417969C10.1448 0.417969 9.96465 0.492596 9.83182 0.625435C9.69898 0.758273 9.62435 0.93844 9.62435 1.1263V1.83464H5.37435V1.1263C5.37435 0.93844 5.29972 0.758273 5.16688 0.625435C5.03404 0.492596 4.85388 0.417969 4.66602 0.417969C4.47815 0.417969 4.29799 0.492596 4.16515 0.625435C4.03231 0.758273 3.95768 0.93844 3.95768 1.1263V1.83464H2.54102C1.97743 1.83464 1.43693 2.05852 1.03841 2.45703C0.639899 2.85555 0.416016 3.39605 0.416016 3.95964V12.4596C0.416016 13.0232 0.639899 13.5637 1.03841 13.9622C1.43693 14.3608 1.97743 14.5846 2.54102 14.5846H12.4577C13.0213 14.5846 13.5618 14.3608 13.9603 13.9622C14.3588 13.5637 14.5827 13.0232 14.5827 12.4596V3.95964C14.5827 3.39605 14.3588 2.85555 13.9603 2.45703C13.5618 2.05852 13.0213 1.83464 12.4577 1.83464V1.83464ZM13.166 12.4596C13.166 12.6475 13.0914 12.8277 12.9586 12.9605C12.8257 13.0933 12.6455 13.168 12.4577 13.168H2.54102C2.35315 13.168 2.17299 13.0933 2.04015 12.9605C1.90731 12.8277 1.83268 12.6475 1.83268 12.4596V7.5013H13.166V12.4596ZM13.166 6.08464H1.83268V3.95964C1.83268 3.77177 1.90731 3.59161 2.04015 3.45877C2.17299 3.32593 2.35315 3.2513 2.54102 3.2513H3.95768V3.95964C3.95768 4.1475 4.03231 4.32766 4.16515 4.4605C4.29799 4.59334 4.47815 4.66797 4.66602 4.66797C4.85388 4.66797 5.03404 4.59334 5.16688 4.4605C5.29972 4.32766 5.37435 4.1475 5.37435 3.95964V3.2513H9.62435V3.95964C9.62435 4.1475 9.69898 4.32766 9.83182 4.4605C9.96465 4.59334 10.1448 4.66797 10.3327 4.66797C10.5205 4.66797 10.7007 4.59334 10.8336 4.4605C10.9664 4.32766 11.041 4.1475 11.041 3.95964V3.2513H12.4577C12.6455 3.2513 12.8257 3.32593 12.9586 3.45877C13.0914 3.59161 13.166 3.77177 13.166 3.95964V6.08464Z"
			fill="#BEBEBE"
		/>
	</svg>
</template>
