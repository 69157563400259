import { GET_ACT } from "@/constants/store/tickets/actions"

import { SET_MUT } from "@/constants/store/tickets/mutations"

export default {
	[GET_ACT]: async function ({ commit }) {
		const response = await this.$api.tickets.get()

		if (!response.status) {
			return
		}

		commit(SET_MUT, { key: 'themes', value: response.response.themes })
		commit(SET_MUT, { key: 'tickets', value: response.response.tickets })
	}
}