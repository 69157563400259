import { CANCEL_BY_CLIENTS_GETTERS } from '@/constants/store/lists/getters'

export default {
	[CANCEL_BY_CLIENTS_GETTERS]: (state) => {
		const typeId = 2

		if (!state.booking.codes) {
			return
		}

		return state.booking.codes.filter((itm) => itm.type_id === typeId)
	}
}
