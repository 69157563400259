export default (axios) => ({
	/**
	 * Получить список вопросов
	 *
	 * @param { string } - user_role: GUEST or MASTER
	 *
	*/
	get ({ payload }, { controller } = { controller: new AbortController() }) {
		return axios.get(`account/questions?user_role=${payload}`, { signal: controller.signal })
	},
	/**
	 * Добавить ответ на вопрос
	 *
	 * Верхний уровень массива response это табы
	 *
	 * @param { string } - id
	 * @param { array } - answer: ['simple']
	 *
	*/
	set ({ id, answer }) {
		return axios.post(`account/questions/${id}`, { answer: [answer] })
	},
	getQuestionsForMainPage() {
		return axios.get(`account/faq`)
	}
})