import ChatMessage from "@/chat/ChatMessage";
import OrderedDLSet from "@/utils/orderedDLSet";

/**
 * Чат-комната
 */
export default class ChatRoom {
  /**
   * enum для типа блокировки:
   * 0 - нет
   * 1 - блокировал пользователь
   * 2 - блокировал собеседник
   * 3 - оба заблокировали
   *
   * @type {{Mutual: number, Recipient: number, Self: number, None: number}}
   */
  static BLOCKING_TYPE = {
    None: 0,
    Self: 1,
    Recipient: 2,
    Mutual: 3
  }

  /**
   * соотвествующие {@link BLOCKING_TYPE} сообщения о блокировке
   *
   * @type {string[]}
   */
  static BLOCKING_TEXT = [
    'Блокировки нет',
    'Вы решили больше не общаться с пользователем',
    'Пользователь решил больше не общаться с Вами',
    'Вы совместе решили прекратить общение'
  ]

  constructor(room, chat) {
    this._chat = chat
    // упорядочивание ведется по id сообщений чатика
    this._messages = new OrderedDLSet((msg1, msg2) => {
      return msg1.id >= msg2.id
    })
    this._lastMessageId

    this.id = room.id
    this.block_type = room.block_type
    this.unread_count = room.unread_count
    this.recipient_id =
      room.users[0].id === this._chat.userSelf.id ? room.users[1].id : room.users[0].id
    if (room.last_message) {
      const lastMessage = new ChatMessage(room.last_message, this._chat)
      this._messages.insert(lastMessage)
      this._lastMessageId = lastMessage.id
    }
    this.created_at = room.created_at
  }

  /**
   * Заблокирована ли чат-комната?
   *
   * @returns {boolean}
   */
  get isBlocked() {
    return this.block_type !== 0
  }

  /**
   * Комната заблокирована самим пользователем?
   *
   * @returns {*}
   */
  get isBlockedByUser() {
    return this.block_type & ChatRoom.BLOCKING_TYPE.Self
  }

  /**
   * Возвращает id собеседника
   *
   * @returns {*}
   */
  get recipient() {
    return this._chat.getUserById(this.recipient_id)
  }

  /**
   * Возвращает последнее сообщение в чате
   *
   * @returns {*}
   */
  get lastMessage() {
    return this._messages.head
  }

  /**
   * Возвращает раннее из уже загруженных сообщений {@link _messages}
   *
   * @returns {ChatMessage}
   */
  get oldestLoadedMessage() {
    return this._messages.tail
  }

  /**
   * Возвращает коллекцию сообщений (итератор по ней)
   *
   * @returns {OrderedDLSet}
   */
  get messages() {
    return this._messages
  }

  /**
   * Возвращает текст - причину блокировки комнаты {@link BLOCKING_TYPE}
   *
   * @returns {string}
   */
  get blockingReason() {
    return ChatRoom.BLOCKING_TEXT[this.block_type]
  }

  /**
   * Возвращает количество уже загруженных сообщений
   *
   * @return {number}
   */
  get loadedMessagesCount() {
    return this._messages.size
  }

  /**
   * Возвращает сообщенис с указанным id
   *
   * @param id
   * @returns {any}
   */
  getMessageById(id) {
    let message
    for (let msg of this._messages) {
      if (msg.id === id) {
        message = msg
        break
      }
    }
    return message
  }

  /**
   * Возвращает истину если есть непрочитанные сообщения
   *
   * @return {boolean}
   */
  hasUnreadMessages() {
    return !this.lastMessage?.is_read &&
      this.lastMessage?.user_id === this.recipient_id
  }

  /**
   * Добавляет новое сообщение в чат-комнату
   *
   * @param message
   * @return {ChatMessage}
   */
  addMessage(message) {
    const msg = new ChatMessage(message, this._chat)
    this._messages.insert(msg)
    // не забываем изменить id последнего сообщения в чатике
    if (this._lastMessageId < msg.id) {
      this._lastMessageId = msg.id
    }
    return msg
  }

  /**
   * Устанавливает значение блокировки {@link BLOCKING_TYPE}
   *
   * @param type
   */
  setBlock(type) {
    this.block_type = type
  }

  /**
   * Помечаем сообщение с указанным Id и все до него прочитанными
   *
   * @param messageId
   */
  markReadMessageAndOldest(messageId, userId = null) {
    for (let message of this._messages) {
      if (message.id <= messageId) {
        if (userId === null) {
          message.is_read = true
        } else {
          if (message.user_id === userId) message.is_read = true
        }
      } else {
        break
      }
    }
  }
}