import { CLIENT_ACT, OWNER_ACT, GET_ACT } from "@/constants/store/progress/actions"
import { SET_MUT } from "@/constants/store/progress/mutations"

import mods from '@/constants/mods'

export default {
  [CLIENT_ACT]: async function({ commit }) {
    const response = await this.$api.progress.client()

    if (response?.status) {
      commit(SET_MUT, { key: 'client', value: response.response })
    }
  },
  [OWNER_ACT]: async function({ commit }) {
    const response = await this.$api.progress.owner()

    if (response?.status) {
      commit(SET_MUT, { key: 'owner', value: response.response })
    }
  },
  [GET_ACT]: async function({ rootState, commit }) {
    let response

    if (mods.GUEST === rootState.mode) {
      response = await this.$api.progress.client()
    } else {
      response = await this.$api.progress.owner()
    }

    if (response && response.status) {
      commit(SET_MUT, { key: 'content', value: response.response })

      return response.response
    }
  }
}