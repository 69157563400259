import {
	INCREMENT_STEP_MUT,
	DECREMENT_STEP_MUT,
	UPDATE_VALUE_MUT,
	CLEAR_ALL_MUT
} from '@/constants/store/auth/recovery/mutations'

export default {
	[INCREMENT_STEP_MUT]: (state) => (state.step += 1),
	[DECREMENT_STEP_MUT]: (state) => (state.step -= 1),
	[UPDATE_VALUE_MUT]: (state, payload) =>
		(state.values[payload.field] = payload.value),
	[CLEAR_ALL_MUT]: function (state) {
		state.step = 1
		Object.keys(state.values).forEach((key) => {
			state.values[key] = ''
		})
	}
}
