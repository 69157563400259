<template>
  <ul class="reviews-sended-card__criteria">
    <li
      v-for="rate in ratings"
      :key="rate.id"
      class="criteria-item hospitality"
    >
      <p class="criteria-item__text">{{ fields[rate.target] }}:</p>
      <div class="criteria-item__wrapper">
					<span
            v-for="itm of 5"
            :key="itm"
            :class="{ active: itm <= rate.value }"
          />
      </div>
    </li>
  </ul>
</template>

<script>

const FIELDS = {
  101: 'Соблюдение правил дома',
  102: 'Общительность',
  103: 'Соблюдение чистоты в жилье',
  201: 'Общительность хозяина',
  202: 'Гостеприимство',
  301: 'Удобство расположения',
  302: 'Чистота жилья'
}

export default {
  name: "RatingList",
  props: {
    ratings: {
      type: Object,
      required: true
    }
  },
  computed: {
    fields() {
      return FIELDS
    }
  }
};
</script>

<style scoped>

</style>