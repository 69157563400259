<template>
	<div class="info-modal">
		<div class="info-modal__logo">
			<img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
		</div>
		<p class="info-modal__text">
			Здесь можно быстро и удобно найти или сдать жилье, познакомиться с гостем
			и хозяином до встречи, а также поделиться друг с другом впечатлениями и
			интересами.
		</p>
		<a class="info-modal__button" @click="hide">Ок</a>
	</div>
</template>

<script>
export default {
	name: 'InfoRegister',
	methods: {
		hide() {
			this.$store.commit('hideModal')
		}
	}
}
</script>
