export default {
	created() {
		setImage(...arguments)
	},
	beforeUpdate() {
		setImage(...arguments)
	}
}

function setImage(el, { arg, value }) {
	function loadImage() {
		if (!el) {
			return false
		}

		if (!value) {
			return
		}

		if (arg === 'background') {
      el.style.backgroundImage = `url(${value})`
		} else if (arg === 'srcset') {
      el.srcset = value
    } else if (el.nodeName === 'IMG') {
			el.src = value
		}
	}

	function handleIntersect(entries, observer) {
		entries.forEach((entry) => {
			if (!entry.isIntersecting) {
				return null
			} else {
				loadImage()
				observer.unobserve(el)
			}
		})
	}

	function createObserver() {
		const options = {
			root: null,
			threshold: '0'
		}
		const observer = new IntersectionObserver(handleIntersect, options)

		observer.observe(el)
	}

	if (!window.IntersectionObserver) {
		loadImage()
	} else {
		createObserver()
	}
}
