import setModuleName from '@/utils/setModuleName'

import { MODULE_NAME } from '.'

export const CLIENT_ACT = 'CLIENT'
export const OWNER_ACT = 'OWNER'
export const GET_ACT = 'GET'

export const CLIENT = setModuleName(MODULE_NAME, CLIENT_ACT)
export const OWNER = setModuleName(MODULE_NAME, OWNER_ACT)
export const GET = setModuleName(MODULE_NAME, GET_ACT)
