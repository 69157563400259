export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export function unique() {
	return Date.now() + Math.floor((1 + Math.random()) * 0x10000)
}

export function checkWebpCanvas() {
	const elem = document.createElement('canvas')

	if (elem.getContext && elem.getContext('2d')) {
		return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
	} else {
		return false
	}
}

export function getUserName(payload) {
	if (!payload.surname && !payload.name && !payload.patronymic) {
		return 'Пользователь'
	} else {
		return `${payload.surname || ''} ${payload.name || ''}${
			payload.patronymic ? ` ${payload.patronymic}` : ''
		}`
	}
}
