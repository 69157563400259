<template>
	<div class="wrapper">
		<div class="ok-cancel-modal">
			<div class="modal__logo">
				<img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
			</div>
			<p class="modal__text">
				Что бы просматривать статьи, нужно авторизоваться.
			</p>
			<div class="registration">
				Уже есть аккаунт?
				<a href="#" @click="showAuth">Войти</a>
			</div>
			<div class="buttons">
				<a @click.prevent="hide">Ок</a>
				<a @click.prevent="showRegistration">Зарегистрироваться</a>
			</div>
		</div>
	</div>
</template>

<script>
import modals from '@/constants/modals'

export default {
	name: 'NotAuth',
	methods: {
		hide() {
			this.$store.commit('hideModal')
		},
		showAuth() {
			this.$store.commit('showModal', {
				name: modals.AUTH,
				props: null,
				isShow: true
			})
		},
		showRegistration() {
			this.$store.commit('showModal', {
				name: modals.REGISTRATION,
				props: null,
				isShow: true
			})
		}
	}
}
</script>
