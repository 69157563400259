import setModuleName from '@/utils/setModuleName'

import { MODULE_NAME } from '.'

export const CREATE_ACT = 'CREATE'
export const GET_ACT = 'GET'
export const DELETE_ACT = 'DELETE'

export const CREATE = setModuleName(MODULE_NAME, CREATE_ACT)
export const GET = setModuleName(MODULE_NAME, GET_ACT)
export const DELETE = setModuleName(MODULE_NAME, DELETE_ACT)
