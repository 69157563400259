<template>
	<svg
		width="17"
		height="17"
		viewBox="0 0 17 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.1886 15.375H1.59996L1.59918 15.3749C1.26795 15.3742 1 15.1062 1 14.7758V8.18712C0.999219 4.21706 4.21698 1.00008 8.18626 1.00008C8.18626 1 8.18626 1 8.18626 1H8.18548C12.1548 0.999219 15.3725 4.21698 15.3725 8.18704C15.3725 12.1563 12.1548 15.3733 8.18548 15.3733C8.1847 15.3733 8.1847 15.3732 8.1847 15.3732L8.1886 15.375Z"
			stroke="#CBCBCB"
      class="active-stroke"
			stroke-width="1.67"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
