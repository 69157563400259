<template>
  <li class="reviews__item">
    <div class="reviews__item_header">
      <div class="guest-img">
        <tk-image :src="review.avatar" :alt="review.author_name" />
      </div>
      <div class="header-wrap">
        <div class="guest-name">{{ review.author_name }}</div>
        <div class="date">{{ getDateInFormat(review.created_at) }}</div>
      </div>
    </div>
    <p class="reviews__item_text" :class="{ hide: !isShown }" ref="text">
      {{ review.text }}
    </p>
    <div v-if="showMoreBtn" class="read-more-btn" @click="isShown = !isShown">
      {{ isShown ? 'Свернуть' : 'Читать далее' }}
    </div>
    <RatingList :ratings="review.ratings" />
  </li>
</template>

<script>
import { getDateInFormat } from "@/utils/dateTime";
import RatingList from "@/components/cards/review/RatingList";

export default {
  name: "ReviewModalItem",
  components: { RatingList },
  props: {
    review: Object
  },
  data() {
    return {
      showMoreBtn: false,
      isShown: false
    }
  },
  computed: {
    fields() {
      return {
        201: 'Общительность хозяина',
        202: 'Гостеприимство',
        301: 'Удобство расположения',
        302: 'Чистота жилья'
      }
    },
  },
  methods: {
    getDateInFormat
  },
  /**
   * Т.к. "размер" текстового блока комменатрия изначально ограничен
   * (css overflow), проверяем, вмещается ли весь текст в него
   * если да, то не будем отображать кнопку "показать больше", что изменяет
   * видимость / размеры.
   */
  mounted() {
    this.showMoreBtn = (this.$refs.text.offsetHeight < this.$refs.text.scrollHeight)
  }
};
</script>

<style lang='sass'>
.reviews
	&__item
		&_header
			display: flex
			align-items: center
			gap: 20px
			.guest-img
				width: 50px
				height: 50px
				border-radius: 50%
				overflow: hidden
			& img
        height: 100%
        width: 100%
        object-fit: cover
			.header-wrap
				.guest-name
					font-weight: 500
					font-size: 16px
					line-height: 16px
					margin-bottom: 5px
				.date
					font-size: 11px
					line-height: 13px
					color: #CBCBCB

.hide
  overflow: hidden
  max-height: 3.6em
  line-height: 1.2em
</style>
