import { CREATE_ACT, GET_ACT } from '@/constants/store/complaints/actions'

import types from '@/constants/types'

export default {
  [CREATE_ACT]: async function(_, payload) {
    return this.$api.complaints.create(payload)
  },
  [GET_ACT]: async function() {
    const response = await this.$api.lists.getRejectAndComplaint()

    if (!response.status) {
      return
    }

    return response.response.codes.filter(itm => itm.type_id === types[3].code).reverse()
  }
}