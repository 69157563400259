<template>
	<svg
		width="18"
		height="16"
		viewBox="0 0 18 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.04313 12.7698L5.58036 13.409C5.78813 13.2344 5.89807 12.9697 5.87515 12.6993C5.85223 12.4288 5.6993 12.1864 5.4651 12.0492L5.04313 12.7698ZM2.52689 14.8845L3.06311 15.5246L3.06411 15.5237L2.52689 14.8845ZM1.64491 14.8079L1.00598 15.3455L1.00656 15.3462L1.64491 14.8079ZM1.49805 1.99781H2.33305L2.33305 1.99592L1.49805 1.99781ZM15.8713 1.37207V2.20707L15.8732 2.20707L15.8713 1.37207ZM5.44077 12.6206L5.43635 13.4556H5.44077V12.6206ZM5.03767 12.7666L4.5005 12.1273C4.2927 12.3019 4.18273 12.5666 4.20565 12.8371C4.22856 13.1075 4.38149 13.35 4.6157 13.4871L5.03767 12.7666ZM4.50591 12.1305L1.98967 14.2453L3.06411 15.5237L5.58036 13.409L4.50591 12.1305ZM1.99067 14.2444C2.07881 14.1706 2.20954 14.1822 2.28327 14.2697L1.00656 15.3462C1.52557 15.9617 2.44531 16.0421 3.06311 15.5246L1.99067 14.2444ZM2.28384 14.2703C2.31538 14.3078 2.33305 14.3561 2.33305 14.4056H0.663049C0.663049 14.7488 0.783836 15.0815 1.00598 15.3455L2.28384 14.2703ZM2.33305 14.4056V1.99781H0.663049V14.4056H2.33305ZM2.33305 1.99592C2.33331 2.11295 2.23919 2.20707 2.12223 2.20707V0.537072C1.31624 0.537072 0.661223 1.1921 0.663051 1.9997L2.33305 1.99592ZM2.12223 2.20707H15.8713V0.537072H2.12223V2.20707ZM15.8732 2.20707C15.7534 2.20734 15.6605 2.11117 15.6605 1.99625H17.3305C17.3305 1.19231 16.6783 0.53524 15.8695 0.537074L15.8732 2.20707ZM15.6605 1.99625V11.9956H17.3305V1.99625H15.6605ZM15.6605 11.9956C15.6605 11.879 15.7539 11.7856 15.8706 11.7856V13.4556C16.6762 13.4556 17.3305 12.8013 17.3305 11.9956H15.6605ZM15.8706 11.7856H5.44077V13.4556H15.8706V11.7856ZM5.44518 11.7856C5.09771 11.7837 4.76322 11.9065 4.5005 12.1273L5.57484 13.4059C5.5371 13.4376 5.48852 13.4558 5.43635 13.4556L5.44518 11.7856ZM4.6157 13.4871L4.62117 13.4903L5.4651 12.0492L5.45963 12.046L4.6157 13.4871Z"
			fill="#CBCBCB"
      class="active-fill"
		/>
	</svg>
</template>
