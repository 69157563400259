<template>
	<svg
		width="16"
		height="13"
		viewBox="0 0 16 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14 2.01562L5.75 11.0156L2 6.92472"
			stroke="white"
			stroke-width="3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
