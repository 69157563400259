import mutations from './mutations'

export default {
	state: {
		step: 1,
		values: {
			phone: '',
			password: '',
			code: ''
		}
	},
	mutations,
	namespaced: true
}
