<template>
	<div class="modal" @click="hide">
		<div v-if="currentModal.isShow" class="modal__backfrop">
			<div class="modal__wrapper" :class="{'modal-middle': currentModal.name === 'PlaceCarousel'}">
				<component
					:is="currentModalComponent"
					:content="currentModal.props"
					@click.stop
				/>
			</div>
		</div>
	</div>
</template>

<script>
const modals = {}

require
	.context('./modals', false, /[A-Z]\w+\.(vue|js)$/)
	.keys()
	.forEach(async (fileName) => {
		const componentName = fileName
			.split('/')
			.pop()
			.replace(/\.\w+$/, '')

		modals[componentName] =
			(await import(`@/components/modals/${componentName}.vue`)).default ||
			(await import(`@/components/modals/${componentName}.vue`))
	})

export default {
	name: 'Modal',
	methods: {
		hide() {
			this.$store.commit('hideModal')
		}
	},
	computed: {
		currentModal() {
			return this.$store.state.currentModal
		},
		currentModalComponent() {
			return modals[this.currentModal.name]
		}
	},
}
</script>
