<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.3938 7.46875H20.0125V13.4988H17.3938V7.46875Z" fill="white"/>
    <path d="M17.3938 14.8175H20.0125V15.8488H17.3938V14.8175Z" fill="white"/>
    <path d="M16.1601 6.22501H21.2888V17.0488H2.7113V6.22501H16.1601Z" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.16 6.22501V17.0488" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.9326 14.8213L15.5188 14.4075C15.1488 14.0375 14.9401 13.535 14.9401 13.0113V10.3325C14.9401 9.80876 15.1476 9.30626 15.5188 8.93626L16.0026 8.45251" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.3938 14.8175H20.0125V15.8488H17.3938V14.8175Z" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.3938 7.46875H20.0125V13.4988H17.3938V7.46875Z" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.4901 9.29376H19.8701" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.4901 10.6963H19.8701" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.4901 12.0988H19.8701" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.7025 9.33377V13.4788" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.77136 15.2338C4.77136 14.7675 6.61011 14.39 8.87886 14.39C11.1476 14.39 12.9864 14.7675 12.9864 15.2338" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.0575 7.52499H13.7V15.4412H4.0575V7.52499Z" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.95508 17.7738V17.1738" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.045 17.7738V17.1738" stroke="#767582" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>