<template>
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.7431 11.7418L9.9101 8.1611C10.5972 7.28603 10.9652 6.22809 10.9601 5.14302C10.947 2.31063 8.49372 0.0164112 5.4606 5.20299e-05C4.00908 -0.00608058 2.61522 0.530136 1.58953 1.48925C0.563846 2.44836 -0.00846493 3.7507 9.46503e-05 5.10613C0.0132044 7.93878 2.4667 10.2332 5.5001 10.2496C6.66681 10.2543 7.80388 9.90658 8.7416 9.25833L8.7456 9.25552L12.5751 12.8334C12.7822 13.0363 13.0907 13.1184 13.3811 13.0479C13.6716 12.9775 13.8983 12.7656 13.9735 12.4943C14.0487 12.223 13.9605 11.935 13.7431 11.7418ZM5.4961 9.22423C3.06954 9.2112 1.10679 7.37585 1.09614 5.10986C1.08959 4.02565 1.54747 2.98399 2.36786 2.21676C3.18825 1.44952 4.30305 1.0204 5.4641 1.0249C7.89067 1.03793 9.85341 2.87328 9.86407 5.13927C9.87062 6.22348 9.41273 7.26514 8.59235 8.03237C7.77196 8.79961 6.65716 9.22873 5.4961 9.22423Z"
			fill="white"
		/>
	</svg>
</template>
