<template>
  <label :for="$attrs.id" class="radio">
    <input
        v-bind="$attrs"
        type="radio"
    />
    <span v-if="label">{{label}}</span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    modelValue: {
      type: String
    }
  },
  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
.radio{
  display: flex;
  cursor: pointer;
  align-items: center;
  transition: background .2s ease;
  gap: 12px;
  padding: 12px 12px 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-tap-highlight-color: transparent;
  span{
    font-size: 14px;
    line-height: 12px;
  }

  input{
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background: none;
    border: 0;
    appearance: none;
    box-shadow: inset 0 0 0 1.5px #9F9F9F;
    padding: 0;
    margin: 0;
    transition: box-shadow 150ms cubic-bezier(.95,.15,.5,1.25);
    pointer-events: none;

    &:focus{
      outline: none;
    }

    &:checked{
      box-shadow: inset 0 0 0 4.5px #1AC386;
    }
  }
}

</style>
