const checkPayload = (payload) => {
	const values = { ...payload }

	Object.entries(values).forEach(([key, value]) => {
		if (value === null) {
			delete values[key]
		}
	})

	return values
}

export default (axios) => ({
	get({ id }) {
		return axios.get(`place/show/${id}`)
	},
	getPlacesOwner({ page = 1 }, { controller } = { controller: new AbortController() }) {
		return axios.get(`place/owner/places?page=${page}`, { signal: controller.signal })
	},
	delete({ place_id }) {
		return axios.delete(`place/owner/places/${place_id}`)
	},
	toggle({ place_id, is_show }) {
		return axios.put(`place/owner/places/${place_id}/show`, { is_show })
	},
	calculate({
		place_id,
		date_from,
		date_to,
		guests,
		children,
		amenities,
		room_id,
		bed_ids
	}, { controller } = { controller: new AbortController() }) {
		bed_ids = bed_ids.join(',')
		room_id = room_id ? (Array.isArray(room_id) ? room_id.join(',') : room_id) : ''
		amenities = amenities ? (Array.isArray(amenities) ? amenities.join(',') : amenities) : ''
		return axios.get(`place/client/reserves/calculate`, {
			params: {
				place_id,
				date_from,
				date_to,
				guests,
				children,
				amenities,
				room_id,
				bed_ids
			}, signal: controller.signal
		})
	},
	getStatuses({ place_id }) {
		return axios.get(`place/places/creation/step/statuses?place_id=${place_id}`)
	},
	getTypes({ place_id }) {
		return axios.get(
			`place/places/creation/step/type${
				place_id ? `/?place_id=${place_id}` : ''
			}`
		)
	},
	setTypes({ place_id, value: { type, room_type, room_type_options } }) {
		const payload = checkPayload({
			place_id,
			type,
			room_type_options,
			room_type
		})

		return axios.post(`place/places/creation/step/type`, payload)
	},
	getAmentities({ place_id }) {
		return axios.get(
			`place/places/creation/step/amenities${
				place_id ? `/?place_id=${place_id}` : ''
			}`
		)
	},
	setAmentities({ place_id, value: { options } }) {
		return axios.post(
			`place/places/creation/step/amenities`,
			checkPayload({ place_id, options })
		)
	},
	getGuests({ place_id }) {
		return axios.get(
			`place/places/creation/step/guests${
				place_id ? `/?place_id=${place_id}` : ''
			}`
		)
	},
	setGuests({ place_id, value: { options, children, guests } }) {
		return axios.post(
			`place/places/creation/step/guests`,
			checkPayload({ place_id, options, children, guests })
		)
	},
	getLocation({ place_id }) {
		return axios.get(
			`place/places/creation/step/location${
				place_id ? `/?place_id=${place_id}` : ''
			}`
		)
	},
	setLocation({
		place_id,
		value: { flat_number, fias_id, address_description }
	}) {
		return axios.post(
			`place/places/creation/step/location`,
			checkPayload({ place_id, flat_number, fias_id, address_description })
		)
	},
	getDescription({ place_id }) {
		return axios.get(
			`place/places/creation/step/description${
				place_id ? `/?place_id=${place_id}` : ''
			}`
		)
	},
	setDescription({ place_id, value: { title, description, images } }) {
		return axios.post(
			`place/places/creation/step/description`,
			checkPayload({ place_id, title, description, images })
		)
	},
	getRules({ place_id }) {
		return axios.get(
			`place/places/creation/step/rules${
				place_id ? `/?place_id=${place_id}` : ''
			}`
		)
	},
	setRules({ place_id, value: { options } }) {
		return axios.post(
			`place/places/creation/step/rules`,
			checkPayload({ place_id, options })
		)
	},
	getBooking({ place_id }) {
		return axios.get(
			`place/places/creation/step/conditions${
				place_id ? `/?place_id=${place_id}` : ''
			}`
		)
	},
	setBooking({
		place_id,
		value: {
			unwanted_dates,
			in_time,
			out_time,
			auto_booking_confirmation,
			guests_with_verified_documents_only,
			min_days_booking,
			free_cancellation_days,
			percentage_forfeit
		}
	}) {
		return axios.post(
			`place/places/creation/step/conditions`,
			checkPayload({
				place_id,
				unwanted_dates,
				in_time,
				out_time,
				auto_booking_confirmation,
				guests_with_verified_documents_only,
				min_days_booking,
				free_cancellation_days,
				percentage_forfeit
			})
		)
	},
	getPrice({ place_id }) {
		return axios.get(
			`place/places/creation/step/price${
				place_id ? `/?place_id=${place_id}` : ''
			}`
		)
	},
	setPrice({
		place_id,
		value: { price, cleaning_amount, additional_guest_amount }
	}) {
		return axios.post(
			`place/places/creation/step/price`,
			checkPayload({
				place_id,
				price,
				cleaning_amount,
				additional_guest_amount
			})
		)
	},
	sendToVerification({ place_id }) {
		return axios.post(`place/owner/places/${place_id}/update-request`)
	},
	getReviewRejectedReason({ place_id }) {
		return axios.get(`place/owner/places/${place_id}/reason-rejection`)
	},
	getRooms({ place_id }) {
		return axios.get(
			`place/places/creation/step/rooms${
				place_id ? `/?place_id=${place_id}` : ''
			}`
		)
	},
	setRooms({ place_id, value: { rooms } }) {
		return axios.post(
			'place/places/creation/step/rooms',
			checkPayload({
				place_id,
				rooms
			})
		)
	}
})
