<template>
	<div
		class="authmodal"
		:style="[{ width: step.width ? step.width + 'px' : '420px' }]"
	>
		<div class="authmodal__header">
			<h3 class="title">{{ step.header.title }}</h3>
			<tk-button
				class="authmodal__close"
				kind="close-modal"
				type="button"
				aria-label="Кнопка закрытия модального окна"
				@click="hide"
			>
				Кнопка закрытия модального окна
			</tk-button>
		</div>

		<p v-if="step.isIntro" class="authmodal__intro">
			{{ step.intro }}
			<template v-if="step.step === 2">
				<br />
				{{ register.values.phone }}
			</template>
		</p>

		<div class="authmodal__container">
			<template v-if="step.content.type">
				<component
					:is="step.content.type"
					:error="errors[step.field]"
					v-bind="step.content.payload"
					autocomplete="off"
					v-model="values[step.field]"
					@input="clearError(step.field, errors)"
					@next="next"
				/>
			</template>
			<div v-else v-html="step.content.value" :class="step.content.class" />
		</div>

		<tk-error
			v-if="error.status"
			class="authmodal__invalid-register"
			@startTimer="isTimeOut = true"
			@endTimer=";(isTimeOut = false), clearMainError()"
			:data="error"
		/>

		<div v-if="isShowFooter" class="authmodal__footer">
			<button
				v-if="step.sendOneMore"
				:disabled="isDisabled"
				type="button"
				class="authmodal__repeat"
				@click="oneMoreCode"
			>
				Отправить код повторно
			</button>

			<div v-if="step.isPersonalData" class="authmodal__agree">

          <el-checkbox v-model="accept.privacy" />
        <div class="agree-term">
          Я принимаю условия
          <router-link class="authmodal-button-link" target="_blank" to="/privacy">
            пользовательского соглашения
          </router-link>
        </div>

          <el-checkbox v-model="accept.licenseGuest" />
        <div class="agree-term">
          Я принимаю условия
          <router-link class="authmodal-button-link" target="_blank" to="/license-guest">
            лицензионного соглашения для гостей
          </router-link>
        </div>

          <el-checkbox v-model="accept.licenseHousekeeper" />
        <div class="agree-term">
          Я принимаю условия
          <router-link class="authmodal-button-link" target="_blank" to="/license-housekeeper">
            лицензионного соглашения для арендодателей
          </router-link>
        </div>
			</div>

			<form @click.prevent autocomplete="off" class="authmodal__form authform">
				<tk-button
					v-if="step.isNextButton"
					:disabled="isDisabled"
					class="authform__submit"
					type="button"
					aria-label="Кнопка регистрации"
					@click="next"
				>
					{{ step.nextButtonText }}
				</tk-button>
			</form>
		</div>

		<div v-if="step.isHaveAccount" class="authmodal__registration">
			Уже есть аккаунт?
			<a href="#" class="authmodal-button-link" @click.prevent="showAuth">
				Войти
			</a>
		</div>
	</div>
</template>

<script>
import modals from '@/constants/modals'
import mods from '@/constants/mods'

import errorHandler from '@/utils/errorHandler'

import password from '@/components/Password'
import types from '@/components/RegistrationModal/types.vue'

import {
	SEND_CODE_VERIFICATION,
	CODE_IS_VALID,
	CHECK_PHONE,
	REGISTER
} from '@/constants/store/auth/actions'
import {
	INCREMENT_STEP,
	UPDATE_VALUE,
	CLEAR_ALL
} from '@/constants/store/auth/register/mutations'
import { GET_AVATAR } from '@/constants/store/avatar/actions'
import TkRadioButton from "@/components/global/tkRadioButton.vue";
import { ElCheckbox } from "element-plus";

const _config = [
	{
		step: 1,
		form: 'register',
		header: {
			title: 'Регистрация'
		},
		field: 'phone',
		action: SEND_CODE_VERIFICATION,
		filter: /[^+\d]/g,
		content: {
			type: 'tkInput',
			payload: {
				type: 'tel',
				placeholder: '+7 (000) 000 00 00',
				'aria-label': 'Поле для ввода телефона',
				maska: '+7 (###) ###-##-##'
			}
		},
		isPersonalData: true,
		isNextButton: true,
		nextButtonText: 'Далее',
		isHaveAccount: true
	},
	{
		step: 2,
		form: 'register',
		isIntro: true,
		sendOneMore: true,
		intro: 'Код отправлен в смс на номер',
		header: {
			title: 'Регистрация'
		},
		field: 'code',
		action: CODE_IS_VALID,
		content: {
			type: 'tkSmsCode',
			payload: {}
		},
		isNextButton: false,
		isHaveAccount: true
	},
	{
		step: 3,
		form: 'register',
		action: REGISTER,
		isIntro: true,
		intro: 'Установите пароль для входа',
		header: {
			title: 'Регистрация'
		},
		content: {
			type: 'password',
			payload: {}
		},
		field: 'password',
		isNextButton: false,
		nextButtonText: 'Сохранить',
		isHaveAccount: true
	},
	// {
	// 	step: 4,
	// 	width: 640,
	// 	form: 'register',
	// 	header: {
	// 		title: 'Пользовательское соглашение'
	// 	},
	// 	content: {
	// 		class: 'authmodal__terms',
	// 		value: `
	// 		<p class="text">Сайт – совокупность размещенных в сети электронных документов (файлов), объединенных единой темой, дизайном и единым адресным пространством домена Стартовая страница Сайта размещена в сети Интернет по адресу</p>
	// 		<p class="text">
	// 			Пользователь Сайта (Пользователь) – лицо, прошедшее Процедуру регистрации, получившее индивидуальный логин и/или пароль, а также имеющее свой Профиль. Для целей Пользовательского соглашения
	// 			под Пользователем понимается также лицо, которое не прошло Процедуру регистрации, но осуществляет доступ к Сайту и/или использует и/или использовало его. Любое лицо, осуществляющее доступ к Сайту, этим автоматически подтверждает, что оно полностью согласно с положениями Пользовательского соглашения, и что
	// 			в отношении него применимы требования, установленные Пользовательским соглашением.
	// 		</p>
	// 		`,
	// 		payload: {}
	// 	},
	// 	isNextButton: true,
	// 	nextButtonText: 'Принимаю',
	// 	isHaveAccount: false
	// },
	{
		step: 4,
		form: 'register',
		header: {
			title: 'Регистрация'
		},
		content: {
			type: 'tkTypes',
			payload: {}
		},
		isNextButton: false,
		isHaveAccount: false
	}
]

// Refactoring handle errors

export default {
	name: 'Registration',
	components: {
    TkRadioButton,
		tkTypes: types,
		password, ElCheckbox
	},
  inject: ["metricsService"],
	mixins: [errorHandler],
	watch: {
		values: {
			handler() {
				// need to process data for backend format. Result variant save in store
				this.$store.commit(UPDATE_VALUE, {
					field: this.step.field,
					value: this._filter(this.values[this.step.field])
				})
			},
			deep: true
		}
	},
	data() {
		return {
			isTimeOut: false,
			values: {
				phone: '',
				password: '',
				code: ''
			},
      accept: {
        privacy: false,
        licenseGuest: false,
        licenseHousekeeper: false,
      }
		}
	},
	created() {
		this._createFieldsError(['phone', 'code', 'password'])
	},
	computed: {
		config() {
			return _config
		},
		register() {
			return this.$store.state.auth.register
		},
		step() {
			return this.config.find((itm) => itm.step === this.register.step)
		},
		isDisabled() {
      let condition = true;
      if (this.step.isPersonalData) {
        condition = this.accept.privacy && this.accept.licenseGuest && this.accept.licenseHousekeeper
      }
			return this.isTimeOut || !condition
		},
		isShowFooter() {
			return (
				this.step.sendOneMore ||
				this.step.isPersonalData ||
				this.step.isNextButton
			)
		}
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		},
		showAuth() {
			this.$store.commit('showModal', {
				name: modals.AUTH,
				props: null,
				isShow: true
			})
		},
		guestInfoRegister() {
			this.$store.commit('showModal', {
				name: modals.GUEST_INFO_REGISTER,
				props: null,
				isShow: true
			})
		},
		infoRegister() {
			this.$store.commit('showModal', {
				name: modals.INFO_REGISTER,
				props: null,
				isShow: true
			})
		},
		_filter(payload) {
			// Filtered data for backend(set in config)
			return this.step.filter && payload
				? payload.replace(this.step.filter, '')
				: payload
		},
		async oneMoreCode() {
			const response = await this.$store.dispatch(SEND_CODE_VERIFICATION, {
				type: 'register'
			})

			if (response) {
				this.setError(response)
			}
		},
		async next() {
			if (this.step.action) {
				if (this.step.step === 1) {
					const res = await this.$store.dispatch(CHECK_PHONE, {
						phone: this.register.values.phone
					})

					if (res && res.error) {
						return this.setError(res)
					}

					if (res && res.response && res.response.is_used) {
						this.setError({ error: { phone: ['Телефон уже используется'] } })
						return
					}
				}

				const response = await this.$store.dispatch(this.step.action, {
					type: 'register'
				})

				// last step. Fix error on backend
				if (this.register.step === 3) {
					if (response && response.error.phone) {
						this.$store.commit('showNotification', { type: 'error', text: response.error.phone[0] })
					}
				}

				if (response && (response.error || response.errors)) {
					return this.setError(response)
				}
			}

			if (this.config.length > this.register.step) {
				// next step
				this.$store.commit(INCREMENT_STEP)
			} else {
				// last step. Show info modal and clear all data
				if (mods.GUEST === this.$store.getters.mode) {
					this.guestInfoRegister()
				} else {
					this.infoRegister()
				}

        this.metricsService.sendEvent("registration")
				await this.$store.dispatch(GET_AVATAR)
				localStorage.removeItem('flagsChangeAndSaveData')

				this.$store.commit(CLEAR_ALL) // clear data auth/register store

        this.$store.commit('showNotification', {
          type: 'success',
          text: 'Вы успешно авторизованы'
        })
			}
		}
	}
}
</script>

<style lang="sass">
.authmodal
	&__container
		.input-component
			&__error
				bottom: unset
				top: -23px

.authmodal__agree
  display: grid
  grid-template-columns: min-content auto
  column-gap: 10px
  align-items: center

  label
    margin-right: 0

.el-checkbox__inner:hover
  border-color: #1AC386

.el-checkbox__input.is-checked .el-checkbox__inner
  border-color: #1AC386
  background-color: #1AC386
</style>
