export default (axios) => ({
  /**
   * Получить название города по идентификатору ФИАС
   *
   * @param { string } - идентификатор
   *
   */
  async city ({ fias }, { controller } = { controller: new AbortController() }) {
    const response = await axios.get(`address/city/${fias}`,
      { signal: controller.signal })
    const city = response.response.city;
    const prefixPosition = city.indexOf(" ");
    return city.substring(prefixPosition + 1);
  },
})