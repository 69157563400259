<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.50343 10.0277C9.91444 9.97631 10.2893 10.2679 10.3407 10.6789L11.5002 19.9551C11.6857 21.4391 10.5285 22.75 9.0329 22.75C7.77891 22.75 6.72117 21.8163 6.5656 20.5719L6.5656 20.5719L5.3788 11.0775C5.32742 10.6665 5.61896 10.2916 6.02998 10.2402C6.44099 10.1889 6.81584 10.4804 6.86721 10.8914L8.05401 20.3859C8.05401 20.3859 8.05401 20.3859 8.05401 20.3859C8.11575 20.8796 8.5354 21.25 9.0329 21.25C9.62621 21.25 10.0853 20.7299 10.0117 20.1411L8.85225 10.8649C8.80087 10.4539 9.09241 10.0791 9.50343 10.0277Z" fill="#767582"/>
    <path d="M1.25 6.5C1.25 3.60051 3.60051 1.25 6.5 1.25C6.53605 1.25 6.57206 1.2526 6.60774 1.25778L22.1077 3.50778C22.4765 3.5613 22.75 3.87741 22.75 4.25V8.75C22.75 9.11764 22.4835 9.43108 22.1207 9.49023L9.71716 11.5121C9.70544 11.514 9.69367 11.5157 9.68187 11.517C8.44802 11.6584 7.33297 11.75 6.5 11.75C6.35236 11.75 6.206 11.7439 6.06111 11.7319C3.36663 11.5084 1.25 9.25179 1.25 6.5ZM6.44826 2.75035C4.40104 2.77804 2.75 4.44621 2.75 6.5C2.75 8.4649 4.26171 10.0775 6.18499 10.237M6.18499 10.237C6.28868 10.2456 6.39376 10.25 6.5 10.25C7.24143 10.25 8.28139 10.1673 9.49348 10.0288L21.25 8.11236V4.89899L6.44826 2.75035" fill="#767582"/>
    <path d="M18.5 3.35028C18.9142 3.35028 19.25 3.68607 19.25 4.10028V8.89975C19.25 9.31396 18.9142 9.64975 18.5 9.64975C18.0858 9.64975 17.75 9.31396 17.75 8.89975V4.10028C17.75 3.68607 18.0858 3.35028 18.5 3.35028Z" fill="#767582"/>
    <path d="M22.7402 8.62933C22.8068 9.03815 22.5294 9.42359 22.1206 9.49023L15.9189 10.5012C15.5101 10.5678 15.1246 10.2904 15.058 9.88161C14.9913 9.4728 15.2687 9.08736 15.6775 9.02072L21.8793 8.00977C22.2881 7.94313 22.6735 8.22052 22.7402 8.62933Z" fill="#767582"/>
    <path d="M13.5078 3.01726C13.5673 2.60734 13.9478 2.32327 14.3577 2.38278L22.1077 3.50778C22.5176 3.56728 22.8017 3.94782 22.7422 4.35774C22.6827 4.76766 22.3022 5.05172 21.8922 4.99222L14.1422 3.86722C13.7323 3.80772 13.4483 3.42717 13.5078 3.01726Z" fill="#767582"/>
    <path d="M6.5 5.75C6.08576 5.75 5.75 6.08576 5.75 6.5C5.75 6.91424 6.08576 7.25 6.5 7.25C6.91424 7.25 7.25 6.91424 7.25 6.5C7.25 6.08576 6.91424 5.75 6.5 5.75ZM4.25 6.5C4.25 5.25734 5.25734 4.25 6.5 4.25C7.74266 4.25 8.75 5.25734 8.75 6.5C8.75 7.74266 7.74266 8.75 6.5 8.75C5.25734 8.75 4.25 7.74266 4.25 6.5Z" fill="#767582"/>
  </svg>
</template>
