<template>
  <div class="progress-status">
    <img
      class="progress-status__image"
      :src="achievements.client?.icon"
      height="20"
      width="20"
      alt="Медаль"
    />
    <p class="progress-status__text">
      {{ achievements.client?.status }} ({{ achievements.client?.count }})
    </p>
  </div>
  <div class="progress-status">
    <img
      class="progress-status__image"
      :src="achievements.owner?.icon"
      height="20"
      width="20"
      alt="Медаль"
    />
    <p class="progress-status__text">
      {{ achievements.owner?.status }} ({{ achievements.owner?.count }})
    </p>
  </div>
</template>

<script>
export default {
  name: "Progress",
  props: {
    achievements: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="sass">
.progress-status
  display: flex
  align-items: center
  justify-content: center

  &__image
    width: 20px
    height: 20px

  &__text
    margin: 0
    font-weight: 400
    font-size: 12px
    line-height: 14px
    color: $black-secondary
</style>