import {
	GET_AVATAR_ACT,
	SAVE_AVATAR_ACT,
	SET_AVATAR_ACT,
	DEL_AVATAR_ACT
} from '@/constants/store/avatar/actions'
import { SET_FIELD } from '@/constants/store/user/mutations'
import { SET_MUT } from '@/constants/store/avatar/mutations'

export default {
	[GET_AVATAR_ACT]: async function ({ commit }) {
		const response = await this.$api.avatar.get()

		if (!response || !response.status) {
			return response
		}

		commit(
			SET_MUT,
			response.response.find((itm) => itm.is_active)
		)
	},
	[SAVE_AVATAR_ACT]: async function ({ commit, rootState: { user } }, payload) {
		const data = new FormData()
		data.append('upload[0]', payload)

		const upload = await this.$api.avatar.upload(data)

		if (!upload.status) {
			return upload
		}

		const avatar = upload.response[0]

		for (const av of user.avatars) {
			if (av.hash === avatar.hash) return null;
		}

		commit(
			SET_FIELD,
			{ field: 'avatars', value: [...user.avatars, avatar] },
			{ root: true }
		)

		return avatar
	},
	[SET_AVATAR_ACT]: async function ({ commit }, payload) {
		const response = await this.$api.avatar.set({ img_hash: payload.hash })

		if (!response.status) {
			return response
		}

		commit(
			SET_FIELD,
			{ field: 'avatars', value: response.response },
			{ root: true }
		)
		commit(SET_MUT, response.response.find(itm => itm.is_active))
	},
	[DEL_AVATAR_ACT]: async function ({ commit }, payload) {
		const response = await this.$api.avatar.delete(payload.hash)

		if (!response.status) {
			return response
		}

		commit(
			SET_FIELD,
			{ field: 'avatars', value: response.response },
			{ root: true }
		)
		commit(SET_MUT, response.response.find(itm => itm.is_active))
	}
}
