<template>
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M26.5 1.5L12.75 15.25M26.5 1.5L17.75 26.5L12.75 15.25M26.5 1.5L1.5 10.25L12.75 15.25"
			stroke="white"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>