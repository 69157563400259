export default (axios) => ({
	/**
	 * Регистрация
	 *
	 * @param { Object: { phone: string, password: string, code: string } }
	 *
	 * @return { Object } - user
	 * @return { String } - access_token
	 * @return { String } - refresh_token
	 *
	 */
	register(payload) {
		return axios.post(`account/user/register`, payload)
	},

	/**
	 * Восстановление доступа
	 *
	 * @param { Object: { phone: string, password: string, code: string } }
	 *
	 * @return { Object } - user
	 * @return { String } - access_token
	 * @return { String } - refresh_token
	 *
	 */
	recovery(payload) {
		return axios.post(`account/user/recovery`, payload)
	},

	/**
	 * Логин
	 *
	 * @param { Object: { phone: string, password: string } }
	 *
	 * @return { Object } - user
	 * @return { String } - access_token
	 * @return { String } - refresh_token
	 *
	 */
	auth(payload) {
		return axios.post(`account/user/authorization`, payload)
	},

	/**
	 * Обновление токена
	 *
	 * @param { Object: { refresh_token: string } }
	 *
	 * @return { String } - access_token
	 * @return { String } - refresh_token
	 *
	 */
	refresh(payload) {
		return axios.post(`account/user/refresh-token`, payload)
	},

	/**
	 * Отправка кода верификации
	 * 
	 * Роут принимает запросы с переодичностью:
			2 запрос - через 1 мин,
			4 запрос - через 30 мин,
			6 запрос - через 60 мин,
			8 запрос - через 180 мин.		
	 * Далее цикл сбрасывается.
	 *
	 * @param { Object: { phone: string, recovery?: boolean } }
	 * 
	 * @return { Boolean } - status_send
	 * 
	 */
	sendCodeVerification(payload) {
		return axios.post(`account/notifications/phone/verification`, payload)
	},

	/**
	 * Проверка существования номера телефона в базе
	 *
	 * @param { Object: { phone: string } }: телефон в формате +71231231231
	 *
	 * @return { Boolean } - is_used: использован или нет
	 * @return { String } - message: системное сообщение
	 *
	 */
	isUsePhone(payload) {
		return axios.post(`account/checks/phone/is-use`, payload)
	},

	/**
	 * Проверка существования кода верификации телефона
	 *
	 * @param { Object: { phone: string, code: string } }
	 *
	 * @return { Boolean } - is_valid: валидная комбинация телефона и кода
	 * @return { String } - message: системное сообщение
	 *
	 */
	codeIsValid(payload) {
		return axios.post(`account/checks/phone/code-is-valid`, payload)
	}
})
