import dayjs from 'dayjs'
import 'dayjs/locale/ru'

let updateLocale = require('dayjs/plugin/updateLocale')

dayjs.extend(updateLocale)

dayjs.updateLocale('ru', {
  weekStart: 1
})

dayjs.locale('ru')