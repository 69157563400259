<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.1192 9.07812H15.0732C13.9908 9.07812 13.0927 9.88031 12.9411 10.9213C12.6406 10.762 12.2983 10.6713 11.9351 10.6713H10.8891C9.71211 10.6713 8.75305 11.62 8.73504 12.7929C8.4398 12.6407 8.10547 12.5541 7.75109 12.5541H6.70516C5.52809 12.5541 4.56898 13.5029 4.55098 14.6757C4.25578 14.5234 3.92148 14.437 3.56707 14.437H2.52113C1.33289 14.437 0.366211 15.4037 0.366211 16.5919V19.4161C0.366211 19.7396 0.628555 20.002 0.952148 20.002H5.13609H9.32012H13.5041H13.5041H13.5041H17.6881C18.0117 20.002 18.274 19.7396 18.274 19.4161V11.2331C18.2741 10.0448 17.3074 9.07812 16.1192 9.07812ZM1.53809 16.5918C1.53809 16.0498 1.97906 15.6087 2.52113 15.6087H3.56711C4.10918 15.6087 4.55016 16.0498 4.55016 16.5918V18.8301H1.53809V16.5918ZM5.72203 16.5918V14.709C5.72203 14.167 6.16305 13.7259 6.70516 13.7259H7.75105C8.29316 13.7259 8.73418 14.167 8.73418 14.709V18.8301H5.72203V16.5918ZM12.9182 18.8301H9.90609V14.709V12.8262C9.90609 12.2841 10.3471 11.8432 10.8891 11.8432H11.9351C12.4772 11.8432 12.9182 12.2841 12.9182 12.8262V18.8301ZM17.1022 18.8301H14.0901V11.2331C14.0901 10.691 14.5311 10.25 15.0732 10.25H16.1192C16.6612 10.25 17.1022 10.691 17.1022 11.2331V18.8301Z"
			fill="#CCCCCC"
      class="active-fill"
		/>
		<path
			d="M19.6044 2.9127C19.5355 2.70059 19.3521 2.54598 19.1314 2.51395L17.0512 2.21172L16.1208 0.326602C16.022 0.126602 15.8184 0 15.5953 0C15.3723 0 15.1686 0.126602 15.0699 0.326602L14.1396 2.21172L12.0593 2.51395C11.8386 2.54598 11.6552 2.70059 11.5863 2.9127C11.5174 3.1248 11.5749 3.35766 11.7346 3.51336L13.2398 4.98074L12.8845 7.05254C12.8468 7.27238 12.9372 7.49453 13.1176 7.62563C13.2197 7.6998 13.3406 7.73754 13.4621 7.73754C13.5553 7.73754 13.6489 7.71531 13.7346 7.67023L15.5953 6.69207L17.4561 7.67023C17.5418 7.71527 17.6355 7.73754 17.7287 7.73754H17.7305C18.0537 7.73703 18.3155 7.47488 18.3155 7.1516C18.3155 7.10664 18.3104 7.06281 18.3009 7.02066L17.9509 4.9807L19.4562 3.51332C19.6159 3.3577 19.6734 3.12484 19.6044 2.9127ZM16.9123 4.35664C16.7742 4.49125 16.7111 4.6852 16.7437 4.87527L16.9505 6.08055L15.868 5.51148C15.6973 5.42176 15.4934 5.42176 15.3227 5.51148L14.2402 6.08055L14.4469 4.87527C14.4795 4.6852 14.4165 4.49125 14.2784 4.35664L13.4027 3.50297L14.6129 3.32711C14.8038 3.29941 14.9687 3.17953 15.0541 3.0066L15.5953 1.90988L16.1366 3.0066C16.222 3.17953 16.387 3.29941 16.5778 3.32711L17.788 3.50297L16.9123 4.35664Z"
			fill="#CCCCCC"
      class="active-fill"
		/>
	</svg>
</template>
