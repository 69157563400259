<template>
	<div class="info-modal">
		<div class="info-modal__logo">
			<img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
		</div>
		<p class="info-modal__text">
			<!-- Ваша заявка на публикацию объявления подана. Как только ваше объявление
				пройдёт модерацию, оно будет опубликовано в результатах поиска. Спасибо! -->
			<!-- Объявление находится на проверке у модераторов. Как только мы проверим
			объявление, оно будет автоматически активировано и попадёт в результаты
			поиска. Не редактируйте объявление снова, пока оно не пройдёт проверку,
			иначе, срок проверки существенно увеличится. -->
			<!-- Объявление успешно сохранено! -->
			{{ content.text }}
		</p>
		<a class="info-modal__button" @click="hide">Ок</a>
	</div>
</template>

<script>
export default {
	name: 'NotAuth',
	props: {
		content: {
			type: Object,
			default: () => ({
				text: ''
			})
		}
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		}
	}
}
</script>
