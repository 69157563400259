<template>
	<label class="input-number">
		<span v-if="label" class="input-number__text">{{ label }}</span>

		<span class="input-number__content">
			<button
        type="button"
				class="btn input-number__btn input-number__btn--decrease"
				aria-label="Уменьшить количество"
        @click="decrease"
			>
				<tk-svg type="btn-minus" />
			</button>
			<input
        ref="input"
				type="number"
				:value="modelValue"
        readonly
				@input="update"
				class="input-number__input"
			/>
			<button
        type="button"
				class="btn input-number__btn input-number__btn--increase"
				aria-label="Увеличить количетсво"
        @click="increase"
			>
				<tk-svg type="btn-plus" />
			</button>
		</span>
	</label>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ''
		},
		modelValue: {
			type: Number,
			required: true,
			default: 0
		},
    disabled: {
      type: Boolean,
      default: false
    }
	},
	methods: {
		update(evt) {
			this.$emit('update:modelValue', evt.target.value)
			this.$emit('change', evt.target.value)
		},
    increase() {
      if (this.disabled) return
      this.$emit('update:modelValue', +this.$refs.input.value + 1)
      this.$emit('change', +this.$refs.input.value + 1)
    },
    decrease() {
      if (this.disabled) return
      if (+this.$refs.input.value > 0) {
        this.$emit('update:modelValue', +this.$refs.input.value - 1)
        this.$emit('change', +this.$refs.input.value - 1)
      }
    }
	}
}
</script>

<style lang="sass">
.input-number
  display: flex
  max-width: 60px

  &__content
    display: flex
    justify-content: space-between

  &__input
    width: 24px
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 14px
    text-align: center
    letter-spacing: -0.01em
    color: #3B3F47
    border: 0
    box-sizing: border-box
    background-color: transparent

    &:hover,
    &:focus,
    &:active
      outline: 0

  &__btn
    width: 18px
    height: 18px

    svg
      width: 18px
      height: 18px
</style>
