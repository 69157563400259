const methods = {
  CREATE_ROOM: 'createRoom',
  GET_ROOMS: 'getRooms',
  BLOCKING_ROOM: 'blockingRoom',
  UNBLOCKING_ROOM: 'unblockingRoom',
  CHECK_BLOCKING_ROOM: 'checkBlockingRoom',
  SEND_MESSAGE: 'sendMessage',
  GET_MESSAGES: 'getMessages',
  READ_MESSAGE: 'readMessage',
}

export default methods