<template>
	<div class="info-modal">
		<div class="info-modal__logo">
			<img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
		</div>
		<p class="info-modal__text">
			<strong>Указанная причина:</strong><br />
			{{ content.message }}
		</p>
		<a class="info-modal__button" @click="hide">Ок</a>
	</div>
</template>

<script>
export default {
	name: 'ReasonRejectBookingModal',
	props: {
		content: {
			type: Object,
			default: () => ({
				message: ''
			})
		}
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		}
	}
}
</script>
