<template>
  <input
    ref="input"
    :value="modelValue"
    @input="(e) => update(e)"
  />
</template>

<script>
export default {
  name: "tkSimpleInputNumber",
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
      default: 0
    }
  },
  emits: ['update:modelValue'],
  methods: {
    update(e) {
      if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
        this.$emit('update:modelValue', e.target.value)
      } else {
        e.target.value = this.modelValue
      }
    }
  }
};
</script>

<style scoped>

</style>