<template>
	<form
		:class="['sms-code', error.status && 'invalid']"
		id="sms-code-form"
		ref="form"
		:key="keys"
	>
		<template v-for="(itm, idx) in values" :key="idx">
			<input
				type="number"
				:id="idx"
				v-bind="$attrs"
				@keydown="keydown(idx, $event.target.value, $event.keyCode)"
				@input="input(idx, $event.target.value)"
				:value="itm"
				class="sms-code__input"
			/>
		</template>
	</form>

	<transition name="fade" mode="out-in">
		<div v-if="error && error.status" class="sms-code__invalid">
			{{ error.text }}
		</div>
	</transition>
</template>

<script>
export default {
	props: {
		error: {
			type: Object,
			default: () => ({})
		},
		modelValue: {
			type: null
		}
	},
	inheritAttrs: false,
	data() {
		return {
			keys: 0,
			isKeyDown: false,
			form: null,
			values: ['', '', '', '']
		}
	},
	watch: {
		value: function () {
			this.$emit('update:modelValue', this.value)

			// preserve the overall structure of the input processing. since the input is 4
			this.$emit('input', { target: { value: this.value } })

			if (this.value.length > 3) {
				setTimeout(() => this.$emit('next'))
			}
		}
	},
	computed: {
		value() {
			return this.values.reduce((sum, cur) => sum + cur, '')
		}
	},
	methods: {
		keydown(idx, val, code) {
			this.isKeyDown = false

			if (code === 8 || code === 46) {
				if (!val && idx > 0) {
					this.isKeyDown = true
					this.$refs.form.children[idx - 1].focus()
					this.values[idx] = ''
				}
			}
		},
		input(key, val) {
			if (this.isKeyDown) {
				return
			}

			if (key < 3) {
				this.$refs.form.children[key + 1].focus()
			}

			if (val && val.length > 1) {
				this.values[key] = val.slice(0, 1)
				this.keys += 1

				return
			}

			this.values[key] = val
		}
	}
}
</script>
