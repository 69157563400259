export default (axios) => ({
	get({ options, ...payload }) {
		if (options && Object.keys(options).length) {
			Object.entries(options).forEach(([key, value]) => {
				payload[`options[${key}]`] = value
			})
		}

		return axios.get(`place/client/favorites`, {
			params: { ...payload }
		})
	},
	create({ places }) {
		return axios.post(`place/client/favorites`, { places: [places] })
	},
	delete({ place_id }) {
		return axios.delete(`place/client/favorites/${place_id}`)
	}
})
