export function getDateInFormat(payload) {
  const date = new Date(payload)
  return (
    ('0' + date.getDate()).slice(-2) +
    '.' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '.' +
    date.getFullYear()
  )
}

export function getDateInQueryFormat(payload) {
  const date = new Date(payload)
  return (
    date.getFullYear()
    +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2)
  )
}

export function isDateInBookingInterval(date) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const last = new Date()
  last.setDate(last.getDate() + 180)
  last.setHours(23, 59, 59, 999)
  return (today <= date && date <= last)
}