<template>
	<div class="info-modal">
		<div class="info-modal__logo">
			<img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
		</div>
		<p class="info-modal__text">
			Спасибо за оставленный комментарий! <br>
      Мы рассмотрим обращение в ближайшее
			время и уведомим Вас.
		</p>
		<a class="info-modal__button" @click="hide">Ок</a>
	</div>
</template>

<script>
export default {
	name: 'SuccessComplainOnAd',
	methods: {
		hide() {
			this.$store.commit('hideModal')
		}
	}
}
</script>
