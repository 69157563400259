export default (axios) => ({
	get({ query }) {
		return axios.get(`account/user/hobby?q=${query}`)
	},
	add({ title, id }) {
		return axios.post('account/user/hobby', { title, id })
	},
  del({ id }) {
    return axios.delete(`account/user/hobby/${id}`)
  },
  update({ title, id }) {
    return axios.patch(`user/hobby/${id}`, { title })
  }
})
