<template>
	<div class="authmodal">
		<div class="authmodal__header">
			<h3 class="title">{{ step.header.title }}</h3>
			<tk-button
				class="authmodal__close"
				kind="close-modal"
				type="button"
				aria-label="Кнопка закрытия модального окна"
				@click="hide"
			>
				Кнопка закрытия модального окна
			</tk-button>
		</div>

		<p v-if="step.isIntro" class="authmodal__intro">
			{{ step.intro }}
			<template v-if="step.step === 2">
				<br />
				{{ recovery.values.phone }}
			</template>
		</p>

		<div class="authmodal__container">
			<template v-if="step.content.type">
				<component
					:is="step.content.type"
					:error="passwordStageError()"
					v-bind="step.content.payload"
					v-model="values[step.field]"
					@input="clearError(step.field, errors)"
					@next="next"
				/>
			</template>
			<div v-else v-html="step.content.value" :class="step.content.class" />
		</div>

		<tk-error
			v-if="error.status"
			@startTimer="isTimeOut = true"
			@endTimer=";(isTimeOut = false), clearMainError()"
			:data="error"
			class="authmodal__common-error"
		/>

		<div v-if="isShowFooter" class="authmodal__footer">
			<button
				v-if="step.sendOneMore"
				:disabled="isDisabled"
				type="button"
				class="authmodal__repeat"
				@click="oneMoreCode"
			>
				Отправить код повторно
			</button>

			<p v-if="step.isPersonalData" class="authmodal__agree">
				Используя приложение,
				<a href="#" class="authmodal-button-link" @click="toPage">
					Вы принимаете условия обработки персональных данных
				</a>
			</p>

			<tk-button
				v-if="step.isNextButton"
				:disabled="isDisabled"
				class="authform__submit"
				type="button"
				aria-label="Кнопка восстановления пароля"
				@click="next"
			>
				{{ step.nextButtonText }}
			</tk-button>
		</div>

		<div v-if="step.isHaveAccount" class="authmodal__registration">
			Уже есть аккаунт?
			<a href="#" class="authmodal-button-link" @click.prevent="showAuth"
				>Войти</a
			>
		</div>
	</div>
</template>

<script>
import modals from '@/constants/modals'

import errorHandler from '@/utils/errorHandler'

import password from '@/components/Password'
import types from '@/components/RegistrationModal/types.vue'

import {
	SEND_CODE_VERIFICATION,
	CODE_IS_VALID,
	RECOVERY
} from '@/constants/store/auth/actions'
import {
	INCREMENT_STEP,
	UPDATE_VALUE,
	CLEAR_ALL
} from '@/constants/store/auth/recovery/mutations'

const _config = [
	{
		step: 1,
		form: 'recovery',
		header: {
			title: 'Восстановление пароля'
		},
		field: 'phone',
		action: SEND_CODE_VERIFICATION,
		filter: /[^+\d]/g,
		content: {
			type: 'tkInput',
			payload: {
				type: 'tel',
				placeholder: '+7 (000) 000 00 00',
				'aria-label': 'Поле для ввода телефона',
				maska: '+7 (###) ###-##-##'
			}
		},
		isPersonalData: true,
		isNextButton: true,
		nextButtonText: 'Далее',
		isHaveAccount: true
	},
	{
		step: 2,
		form: 'recovery',
		isIntro: true,
		sendOneMore: true,
		intro: 'Код отправлен в смс на номер',
		header: {
			title: 'Восстановление пароля'
		},
		field: 'code',
		action: CODE_IS_VALID,
		content: {
			type: 'tkSmsCode',
			payload: {}
		},
		isNextButton: false,
		isHaveAccount: true
	},
	{
		step: 3,
		form: 'recovery',
		action: RECOVERY,
		isIntro: true,
		intro: 'Установите новый пароль для входа ',
		header: {
			title: 'Восстановление пароля'
		},
		content: {
			type: 'password',
			payload: {}
		},
		field: 'password',
		isNextButton: false,
		nextButtonText: 'Установить',
		isHaveAccount: true
	}
]

export default {
	name: 'Recovery',
	components: {
		tkTypes: types,
		password
	},
	mixins: [errorHandler],
	watch: {
		values: {
			handler() {
				// need to process data for backend format. Result variant save in store
				this.$store.commit(UPDATE_VALUE, {
					field: this.step.field,
					value: this._filter(this.values[this.step.field])
				})
			},
			deep: true
		}
	},
	data() {
		return {
			isTimeOut: false,
			values: {
				phone: '',
				password: '',
				code: ''
			}
		}
	},
	created() {
		this._createFieldsError(['phone', 'code', 'password'])
	},
	computed: {
		config() {
			return _config
		},
		recovery() {
			return this.$store.state.auth.recovery
		},
		step() {
			return this.config.find((itm) => itm.step === this.recovery.step)
		},
		isDisabled() {
			return this.isTimeOut
		},
		isShowFooter() {
			return (
				this.step.sendOneMore ||
				this.step.isPersonalData ||
				this.step.isNextButton
			)
		}
	},
	methods: {
    passwordStageError() {
      if (this.step.step === 3 &&
        this.errors.phone.status &&
        this.errors.phone.text === 'Данный аккаунт заблокирован'
      ) {
        return this.errors.phone
      } else {
        return this.errors[this.step.field]
      }
    },
		hide() {
			this.$store.commit('hideModal')
		},
		showAuth() {
			this.$store.commit('showModal', {
				name: modals.AUTH,
				props: null,
				isShow: true
			})
		},
		successChangePassword() {
			this.$store.commit('showModal', {
				name: modals.SUCCESS_CHANGE_PASSWORD,
				props: null,
				isShow: true
			})
		},
		toPage() {
			this.hide()
			this.$router.push('/privacy')
		},
		async oneMoreCode() {
			const response = await this.$store.dispatch(SEND_CODE_VERIFICATION, {
				type: 'recovery'
			})

			if (response) {
				this.setError(response)
			}
		},
		_filter(payload) {
			return this.step.filter && payload
				? payload.replace(this.step.filter, '')
				: payload
		},
		async next() {
			const { dispatch, commit } = this.$store

			if (this.step.action) {
				const response = await dispatch(this.step.action, { type: 'recovery' })

				if (response && (response.error || response.errors)) {

					this.setError(response)

					return
				}
			}

			if (this.config.length > this.recovery.step) {
				commit(INCREMENT_STEP)
			} else {
				commit(CLEAR_ALL) // clear data auth/recovery store
        this.successChangePassword()
			}
		}
	}
}
</script>

<style lang="sass">
.authmodal
	&__container
		.input-component
			&__error
				bottom: unset
				top: -23px
</style>