<template>
	<div class="info-modal">
		<div class="info-modal__counter">{{ step + 1 }} / {{ config.length }}</div>
		<div class="info-modal__logo">
			<img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
		</div>
		<p class="info-modal__text" v-html="config[step].text" />
		<a class="info-modal__button" @click="submit">Ок</a>
	</div>
</template>

<script>
const _config = [
	{
		text: 'В режиме “хозяин”, вы публикуете объявления о сдаче жилья. В режиме “гость”, вы ищете жильё'
	},
	{
		text: 'Публикуйте обьявления и зарабатывайте деньги на сдаче жилья'
	},
	{
		text: 'Ищите хозяев с высоким рейтингом профиля. Так вы поймёте, кто новичок, а кто профессионал'
	}
]

export default {
	name: 'InfoRegister',
	data() {
		return {
			step: 0
		}
	},
	computed: {
		config() {
			return _config
		}
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		},
		submit() {
			if (this.step + 1 < this.config.length) {
				this.step += 1
			} else {
				this.hide()
			}
		}
	}
}
</script>
