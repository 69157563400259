import { MODULE_NAME } from "."
import setModuleName from '@/utils/setModuleName'

export const GET_AVATAR_ACT = 'GET'
export const SAVE_AVATAR_ACT = 'SAVE'
export const SET_AVATAR_ACT = 'SET'
export const DEL_AVATAR_ACT = 'DELETE'

export const GET_AVATAR = setModuleName(MODULE_NAME, GET_AVATAR_ACT)
export const SAVE_AVATAR = setModuleName(MODULE_NAME, SAVE_AVATAR_ACT)
export const SET_AVATAR = setModuleName(MODULE_NAME, SET_AVATAR_ACT)
export const DELETE_AVATAR = setModuleName(MODULE_NAME, DEL_AVATAR_ACT)