import { GET_ACT } from '@/constants/store/user/actions'
import { SET } from '@/constants/store/user/mutations'

export default {
	[GET_ACT]: async function ({ commit, rootState }) {
		const response = await this.$api.user.get(rootState.mode)

		if (response && response.status) {
			commit(
				SET,
				{
					...response.response.user,
					profile_percent: response.response.profile_percent
				},
				{ root: true }
			)

			return Promise.resolve()
		}
	}
}
