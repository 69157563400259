import { MODULE_NAME } from '.'

import setModuleName from '@/utils/setModuleName'

export const INCREMENT_STEP_MUT = 'INCREMENT_STEP'
export const DECREMENT_STEP_MUT = 'DECREMENT_STEP'
export const UPDATE_VALUE_MUT = 'UPDATE_VALUE_MUT'
export const CLEAR_ALL_MUT = 'CLEAR_ALL_MUT'

export const INCREMENT_STEP = setModuleName(MODULE_NAME, INCREMENT_STEP_MUT)
export const DECREMENT_STEP = setModuleName(MODULE_NAME, DECREMENT_STEP_MUT)
export const UPDATE_VALUE = setModuleName(MODULE_NAME, UPDATE_VALUE_MUT)
export const CLEAR_ALL = setModuleName(MODULE_NAME, CLEAR_ALL_MUT)
