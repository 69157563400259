<template>
  <div class="authmodal__types">
    <tk-button
        @click="submit(false)"
        class="authmodal__item"
        aria-label="Кнопка выбора режима Поиска жилья"
        kind="main"
    >
      Я ищу жилье
    </tk-button>
    <tk-button
        @click="submit(true)"
        class="authmodal__item"
        aria-label="Кнопка выбора режима Сдачи жилья"
    >
      Я сдаю жилье
    </tk-button>
  </div>
</template>

<script>
import mods from '@/constants/mods'

export default {
	methods: {
		submit(type) {
			if (type) {
				this.$store.commit('changeMode', mods.MASTER)
			} else {
				this.$store.commit('changeMode', mods.GUEST)
			}

			this.$emit('next')
		}
	}
}
</script>
