const routes = [
  {
    path: '/',
    name: 'MainRoute',
    meta: { mainRoute: true, siteMap: true }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: { scrollTop: true, siteMap: true }
  },
  {
    path: '/',
    name: 'Guest',
    component: () => import('../views/Home/Guest'),
    meta: { scrollTop: true, siteMap: true }
  },
  {
    path: '/',
    name: 'Owner',
    component: () => import('../views/Home/Guest'),
    meta: { scrollTop: true, siteMap: true }
  },
  {
    path: '/article/:alias',
    name: 'Article',
    component: () => import('../views/Article'),
    meta: { siteMap: true}
  },
  {
    path: '/license-guest',
    name: 'License',
    component: () => import('../views/LicenseGuest.vue'),
    meta: { scrollTop: true, siteMap: true }
  },
  {
    path: '/license-housekeeper',
    name: 'LicenseHousekeeper',
    component: () => import('../views/LicenseHousekeeper.vue'),
    meta: { scrollTop: true, siteMap: true }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue'),
    meta: { siteMap: true}
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue'),
    meta: { scrollTop: true, siteMap: true }
  },
  {
    path: '/favourites',
    name: 'Favourites',
    component: () => import('../views/Favourites.vue')
  },
  {
    path: '/organisation-details',
    name: 'OrganisationDetails',
    component: () => import('../views/OrganisationDetails.vue'),
    meta: { scrollTop: true, siteMap: true }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts.vue'),
    meta: { scrollTop: true, siteMap: true }
  },
  {
    path: '/places',
    name: 'Places',
    component: () => import('../views/Places'),
    meta: {
      isHaveQueries: true
    }
  },
  {
    path: '/place/:slug',
    name: 'Place',
    component: () => import('../views/Place'),
    meta: { siteMap: true}
  },
  {
    path: '/place/create',
    name: 'Place.Create',
    component: () => import('../views/CreatePlace')
  },
  {
    path: '/place/:slug/request',
    name: 'BookingRequest',
    component: () => import('../views/BookingRequest.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile'),
    children: [
      {
        path: '',
        name: 'Sidebar',
        component: () => import('../components/Sidebar')
      },
      {
        path: 'anketa',
        name: 'profile.anketa',
        component: () => import('../views/Profile/Anketa'),
        children: [
          {
            path: '',
            component: () => import('@/views/Profile/Anketa/components/Main')
          },
          {
            path: 'edit-photo',
            name: 'profile.anketa.edit-photo',
            component: () =>
              import('@/views/Profile/Anketa/components/EditPhoto')
          },
          {
            path: 'contact-data',
            name: 'profile.anketa.contact-data',
            component: () =>
              import('@/views/Profile/Anketa/components/ContactData')
          },
          {
            path: 'identification',
            name: 'profile.anketa.identification',
            component: () =>
              import('@/views/Profile/Anketa/components/Identification')
          }
        ]
      },
      {
        path: 'progress',
        name: 'profile.progress',
        component: () => import('@/views/Profile/Progress')
      },
      {
        path: 'chat',
        name: 'profile.chat',
        component: () => import('@/views/Profile/Chat'),
        children: [
          {
            path: '',
            name: 'profile.chat.chats',
            component: () => import('@/views/Profile/Chat/components/chats')
          },
          {
            path: '',
            name: 'profile.chat.messages',
            component: () => import('@/views/Profile/Chat/components/messages')
          }
        ]
      },
      {
        path: 'reviews',
        name: 'profile.reviews',
        component: () => import('@/views/Profile/Reviews'),
        children: [
          {
            path: '',
            name: 'profile.reviews.main',
            component: () => import('@/views/Profile/Reviews/components/main')
          },
          {
            path: 'guest/:id',
            name: 'profile.reviews.guest',
            component: () => import('@/views/Profile/Reviews/components/guest')
          },
          {
            path: 'master/:id',
            name: 'profile.reviews.master',
            component: () => import('@/views/Profile/Reviews/components/master')
          }
        ]
      },
      {
        path: 'feedback',
        name: 'profile.feedback',
        component: () => import('../views/Profile/Feedback'),
        children: [
          {
            path: '',
            name: 'profile.progress.feedback',
            component: () => import('@/views/Profile/Feedback/components/Main')
          },
          {
            path: 'create',
            name: 'profile.progress.feedback.create',
            component: () =>
              import('@/views/Profile/Feedback/components/Create')
          },
          {
            path: 'create/:theme_id',
            name: 'profile.progress.feedback.create.theme_id',
            component: () =>
              import('@/views/Profile/Feedback/components/Create')
          },
          {
            path: 'chat/:tickets_id',
            name: 'profile.progress.feedback.chat',
            component: () => import('@/views/Profile/Feedback/components/Chat')
          }
        ]
      },
      {
        path: 'faq',
        name: 'profile.faq',
        component: () => import('../views/Profile/Faq'),
        children: [
          {
            path: '',
            name: 'profile.faq.main',
            component: () => import('@/views/Profile/Faq/components/Main')
          },
          {
            path: 'questions',
            name: 'profile.faq.questions',
            component: () => import('@/views/Profile/Faq/components/Questions')
          },
          {
            path: 'start',
            name: 'profile.faq.start',
            component: () => import('@/views/Profile/Faq/components/Start')
          },
          {
            path: 'articles',
            name: 'profile.faq.articles',
            component: () => import('@/views/Profile/Faq/components/Articles')
          },
          {
            path: 'articles/:alias',
            name: 'profile.faq.article',
            component: () => import('@/views/Profile/Faq/components/Article')
          }
        ]
      },
      {
        path: 'my-housing',
        name: 'profile.housing',
        component: () => import('../views/Profile/Housing')
      },
      {
        path: 'booking',
        name: 'profile.booking',
        component: () => import('../views/Profile/Booking')
      },
      {
        path: 'booking/reject/:id',
        name: 'profile.booking-reject',
        component: () => import('../views/Profile/Booking/BookingReject.vue')
      },
      {
        path: 'trips',
        name: 'profile.trips',
        component: () => import('../views/Profile/Trips'),
        children: [
          {
            path: '',
            redirect: '/profile/trips/upcoming'
          },
          {
            path: 'completed',
            name: 'profile.trips.completed',
            component: () =>
              import('@/views/Profile/Trips/components/Completed')
          },
          {
            path: 'upcoming',
            name: 'profile.trips.upcoming',
            component: () => import('@/views/Profile/Trips/components/Upcoming')
          },
          {
            path: 'booking-cancel/:id',
            name: 'profile.trips.booking-cancel',
            component: () =>
              import('@/views/Profile/Trips/components/CancelBooking')
          },
          {
            path: 'booking-edit/:id',
            name: 'profile.trips.booking-edit',
            component: () =>
              import('@/views/Profile/Trips/components/EditBooking')
          }
        ]
      },
      {
        path: 'settings',
        name: 'profile.settings',
        component: () => import('../views/Profile/Settings'),
        children: [
          {
            path: 'notification',
            name: 'settings.notification',
            component: () =>
              import('../views/Profile/Settings/components/Notification')
          },
          {
            path: 'socials',
            name: 'settings.socials',
            component: () =>
              import('../views/Profile/Settings/components/Socials')
          },
          {
            path: 'policy',
            name: 'settings.policy',
            component: () =>
              import('../views/Profile/Settings/components/Policy')
          },
          {
            path: 'terms',
            name: 'settings.terms',
            component: () =>
              import('../views/Profile/Settings/components/Terms')
          }
        ]
      }
    ]
  },
  {
    path: '/error/:code',
    name: 'error',
    component: () => import('../views/Error')
  }
]

export default routes