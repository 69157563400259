<template>
	<div id="map-ymaps" />
</template>

<script>
import { onMounted, computed, reactive, ref, watch, toRefs, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { ymaps_config } from '@/config'

export default {
	props: {
		mapIcon: {
			type: Object,
			default: () => ({ src: '/images/map-price.svg', size: [73, 80] })
		},
		coords: {
			type: Array,
			default: null
		},
		center: {
			type: Object,
			default: null
		},
		isPoint: {
			type: Boolean,
			default: false
		},
		markers: {
			type: [Array, Object],
			default: () => []
		}
	},
	setup(props, { emit }) {
		const { markers, coords, isPoint, center, mapIcon } = toRefs(props)
		const { push } = useRouter()
		const { query } = useRoute()
		const api = inject('api')
    const store = inject('store')
		let polyline = reactive({})
		let map = reactive({})
		let objectManager = reactive({})

		const ymapsMain = ref(null)

		const drawCircle = () => {
			map.geoObjects.remove(polyline)

			const bounds = map.getBounds()
			const center = map.getCenter()

			const rightPoint = [center[0], bounds[1][1]]

			polyline = new ymaps.Polyline(
				[center, rightPoint],
				{},
				{ strokeOpacity: 0, opacity: 0 }
			)
			map.geoObjects.add(polyline)
			const distance = polyline.geometry.getDistance()

			emit('update', { distance, center })
		}

		function _loadBalloonData(objectId) {
			return api.place.get({ id: objectId })
		}

		function _loadClustersData(objectIds) {
			return Promise.all(objectIds.map((itm) => api.place.get({ id: itm })))
		}

		const isCoords = computed(() => coords.value.find((itm) => itm))
		const qrs = computed(() => {
			const { city, ...qrs } = query

			return { city, ...qrs }
		})

    async function setFavourite(id, isFavourite) {
      let response

      if (!isFavourite) {
        response = await api.favorites.create({
          places: id
        })
      } else {
        response = await api.favorites.delete({
          place_id: id
        })
      }

      if (response.status) {
        store.commit('showNotification', {
          type: 'success',
          text: !isFavourite ? 'Успешно добавлено в избранное' : 'Успешно удалено из избранного'
        })
        emit('updatePlace')
        return true
      } else {
        store.commit('showNotification', {
          type: 'error',
          text: 'Ошибка добавления в избранное'
        })
        return false
      }
    }

		onMounted(async () => {
      if (typeof ymaps === 'undefined') {
        const ymapInjection = document.createElement('script')
        ymapInjection.setAttribute('src', 'https://api-maps.yandex.ru/2.1/?apikey=14b779fe-c6fc-42ec-be08-70f220bdbcf1&lang=ru_RU')
        document.head.appendChild(ymapInjection)
      }
      while (typeof ymaps === 'undefined') {
        await new Promise(resolve => setTimeout(resolve, 250))
      }
			ymaps.ready(() => {
				if (!isPoint.value) {
					map = new ymaps.Map(
						'map-ymaps',
						{
							center: center.value,
							zoom: 10,
							controls: ['zoomControl']
						},
						{
							minZoom: 5,
							maxZoom: 21
							// restrictMapArea: true
						}
					)

					// shift the center of the map by a quarter to the desktop (> 912)
					// if (window && window.screen.width > 912) {
					// 	const positions = map.getGlobalPixelCenter()
					// 	map.setGlobalPixelCenter([positions[0] + 100, positions[1] + 200])
					// }

          map.events.add(['click', 'mousedown'], () => {
            map.balloon.close()
          })

					objectManager = new ymaps.ObjectManager({
						clusterize: true,
						gridSize: 32,
						clusterDisableClickZoom: true
					})

					const MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
						`<div class="balloon-card" id="$[properties.id]">
							<a class="balloon-card__close close" href="#">&times;</a>
							{% if properties.authenticated %}
							<button class="btn balloon-card__favorite balloon-favorite">
							  {% if properties.is_favorite %}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="#1AC386"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.61414 18.6441C3.97285 13.7079 0 11.0932 0 6.93418C0 3.78914 2.24219 1.21094 5.3125 1.21094C7.44312 1.21094 9.0241 2.51941 10 4.37559C10.9758 2.51953 12.5568 1.21094 14.6875 1.21094C17.7582 1.21094 20 3.78965 20 6.93418C20 11.0927 16.0323 13.7034 10.3859 18.6441C10.1649 18.8374 9.83508 18.8374 9.61414 18.6441Z"
                      fill="#1AC386"
                      class="active"
                      fill-opacity="0.9"
                    />
                    <path
                      d="M14.6875 1.21094C12.5569 1.21094 10.9759 2.51941 10 4.37559C9.02418 2.51953 7.4432 1.21094 5.3125 1.21094C2.24184 1.21094 0 3.78965 0 6.93418C0 11.0927 3.96766 13.7034 9.61414 18.6441C9.83512 18.8374 10.1649 18.8373 10.3859 18.6441C16.0271 13.7079 20 11.0932 20 6.93418C20 3.78914 17.7578 1.21094 14.6875 1.21094ZM10 17.4258C4.79992 12.9088 1.17188 10.3996 1.17188 6.93418C1.17188 4.33949 2.95195 2.38281 5.3125 2.38281C7.58797 2.38281 8.99809 4.49012 9.43524 6.05453C9.59289 6.62523 10.4052 6.62777 10.5648 6.05453C10.9961 4.51086 12.3899 2.38281 14.6875 2.38281C17.048 2.38281 18.8281 4.33949 18.8281 6.93418C18.8281 10.4016 15.1941 12.914 10 17.4258Z"
                      fill="#1AC386"
                    />
                  </svg>
							  {% else %}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.61414 18.6441C3.97285 13.7079 0 11.0932 0 6.93418C0 3.78914 2.24219 1.21094 5.3125 1.21094C7.44312 1.21094 9.0241 2.51941 10 4.37559C10.9758 2.51953 12.5568 1.21094 14.6875 1.21094C17.7582 1.21094 20 3.78965 20 6.93418C20 11.0927 16.0323 13.7034 10.3859 18.6441C10.1649 18.8374 9.83508 18.8374 9.61414 18.6441Z"
                      fill="white"
                      class="active"
                      fill-opacity="0.9"
                    />
                    <path
                      d="M14.6875 1.21094C12.5569 1.21094 10.9759 2.51941 10 4.37559C9.02418 2.51953 7.4432 1.21094 5.3125 1.21094C2.24184 1.21094 0 3.78965 0 6.93418C0 11.0927 3.96766 13.7034 9.61414 18.6441C9.83512 18.8374 10.1649 18.8373 10.3859 18.6441C16.0271 13.7079 20 11.0932 20 6.93418C20 3.78914 17.7578 1.21094 14.6875 1.21094ZM10 17.4258C4.79992 12.9088 1.17188 10.3996 1.17188 6.93418C1.17188 4.33949 2.95195 2.38281 5.3125 2.38281C7.58797 2.38281 8.99809 4.49012 9.43524 6.05453C9.59289 6.62523 10.4052 6.62777 10.5648 6.05453C10.9961 4.51086 12.3899 2.38281 14.6875 2.38281C17.048 2.38281 18.8281 4.33949 18.8281 6.93418C18.8281 10.4016 15.1941 12.914 10 17.4258Z"
                      fill="#1AC386"
                    />
                  </svg>
							  {% endif %}
              </button>
              {% endif %}
							  <div class="balloon-card__photos">
                  <div class="balloon-card__carousel balloon-carousel owl-carousel" id="owl-car-ymaps">
                    ` +
							`{% for value in properties.images %}` +
							`<div class="balloon-carousel__item">
                          <img width="200" height="150" class="balloon-carousel__img" src="{{ value.image.jpg[480]['1x'] }}" />
                        </div>` +
							`{% endfor %}` +
							`
								  </div>
                </div>
                <a class="balloon-card__link search-place-ymaps-link">
                  <span class="balloon-card__rating">
                    <b>$[properties.rating]</b>
                    <span class="reviews-count">($[properties.reviews_count])</span>
                  </span>
                  <p class="balloon-card__name">$[properties.title]</p>
                  <span class="balloon-card__price">
                    <b>$[properties.price] р</b> за ночь
                  </span>
                </a>
						</div>
					`,
						{
							build: function () {
								this.constructor.superclass.build.call(this)

								this._$element = $('.balloon-card', this.getParentElement())

								this.applyElementOffset()

								this._$element
									.find('.close')
									.on('click', $.proxy(this.onCloseClick, this))

								this._$element
									.find('.balloon-favorite')
									.on('click', $.proxy(this.setFavorite, this))

								this._$element
									.find('.search-place-ymaps-link')
									.on('click', $.proxy(this.goTo, this))
							},
							clear: function () {
								this._$element.find('.close').off('click')

								this.constructor.superclass.clear.call(this)
							},
							onSublayoutSizeChange: function () {
								MyBalloonLayout.superclass.onSublayoutSizeChange.apply(
									this,
									arguments
								)

								if (!this._isElement(this._$element)) {
									return
								}

								this.applyElementOffset()

								this.events.fire('shapechange')
							},
							goTo: function () {
								push({
									path: `/place/${this._$element[0].id}`,
									query: qrs.value
								})
							},
							applyElementOffset: function () {
								this._$element.css({
									left: -(this._$element[0].offsetWidth / 2),
									top: -250
								})
							},
							onCloseClick: function (e) {
								e.preventDefault()
								this.events.fire('userclose')
							},
							setFavorite: async function (event) {
                if (await setFavourite(this._$element[0].id, this._data.properties._data.is_favorite)) {
                  this._data.properties._data.is_favorite = !this._data.properties._data.is_favorite
                  if (event.currentTarget.children[0].getAttribute('fill') === 'none') {
                    event.currentTarget.children[0].setAttribute('fill', "#1AC386")
                  } else {
                    event.currentTarget.children[0].setAttribute('fill', "none")
                  }
                  if (event.currentTarget.children[0].children[0].getAttribute('fill') === 'white') {
                    event.currentTarget.children[0].children[0].setAttribute('fill', "#1AC386")
                  } else {
                    event.currentTarget.children[0].children[0].setAttribute('fill', "white")
                  }
                  // const balloon = map.balloon.getData()
                  // balloon.properties._data.is_favorite = !balloon.properties._data.is_favorite
                  // map.balloon.setData(balloon)
                  // $('#owl-car-ymaps').owlCarousel({
                  //   items: 1,
                  //   nav: true,
                  //   dots: false
                  // })
                }
							},
							getShape: function () {
								if (!this._isElement(this._$element)) {
									return MyBalloonLayout.superclass.getShape.call(this)
								}

								var position = this._$element.position()

								return new ymaps.shape.Rectangle(
									new ymaps.geometry.pixel.Rectangle([
										[position.left, position.top],
										[
											position.left + this._$element[0].offsetWidth,
											position.top + this._$element[0].offsetHeight
										]
									])
								)
							},
							_isElement: function (element) {
								return element && element[0]
							}
						}
					)

					objectManager.objects.options.set({
						iconLayout: 'default#imageWithContent',
						iconImageHref: '',
						iconImageSize: [70, 35],
						iconContentLayout: ymaps.templateLayoutFactory.createClass(
							'<div class="icon-layout">$[price] &#8381;</div>'
						),
						hasBalloon: true,
						hideIconOnBalloonOpen: false,
						// balloon
						balloonShadow: false,
						balloonLayout: MyBalloonLayout,
						balloonPanelMaxMapArea: 0
					})

					const MyBalloonLayoutCluster =
						ymaps.templateLayoutFactory.createClass(
							`<div class="cluster-balloon">
								<div class="cluster-balloon__header">
									<h4 class="cluster-balloon__title">В этой области</h4>
									<button type="button" class="cluster-balloon__close cluster-balloon-close">&times;</button>
								</div>

								<div class="cluster-balloon__content">
									<ul class="cluster-balloon__list">` +
								`
									{% for unit in properties.lists %}` +
								`
										<li class="cluster-balloon__item">
											<div class="balloon-card" id="$[unit.id]">
                        <a class="balloon-card__close close" href="#">&times;</a>
                        {% if unit.authenticated %}
                        <button class="btn balloon-card__favorite balloon-favorite" id="$[unit.id] $[unit.is_favorite]">
                          {% if unit.is_favorite %}
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="#1AC386"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.61414 18.6441C3.97285 13.7079 0 11.0932 0 6.93418C0 3.78914 2.24219 1.21094 5.3125 1.21094C7.44312 1.21094 9.0241 2.51941 10 4.37559C10.9758 2.51953 12.5568 1.21094 14.6875 1.21094C17.7582 1.21094 20 3.78965 20 6.93418C20 11.0927 16.0323 13.7034 10.3859 18.6441C10.1649 18.8374 9.83508 18.8374 9.61414 18.6441Z"
                                fill="#1AC386"
                                class="active"
                                fill-opacity="0.9"
                              />
                              <path
                                d="M14.6875 1.21094C12.5569 1.21094 10.9759 2.51941 10 4.37559C9.02418 2.51953 7.4432 1.21094 5.3125 1.21094C2.24184 1.21094 0 3.78965 0 6.93418C0 11.0927 3.96766 13.7034 9.61414 18.6441C9.83512 18.8374 10.1649 18.8373 10.3859 18.6441C16.0271 13.7079 20 11.0932 20 6.93418C20 3.78914 17.7578 1.21094 14.6875 1.21094ZM10 17.4258C4.79992 12.9088 1.17188 10.3996 1.17188 6.93418C1.17188 4.33949 2.95195 2.38281 5.3125 2.38281C7.58797 2.38281 8.99809 4.49012 9.43524 6.05453C9.59289 6.62523 10.4052 6.62777 10.5648 6.05453C10.9961 4.51086 12.3899 2.38281 14.6875 2.38281C17.048 2.38281 18.8281 4.33949 18.8281 6.93418C18.8281 10.4016 15.1941 12.914 10 17.4258Z"
                                fill="#1AC386"
                              />
                            </svg>
                          {% else %}
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.61414 18.6441C3.97285 13.7079 0 11.0932 0 6.93418C0 3.78914 2.24219 1.21094 5.3125 1.21094C7.44312 1.21094 9.0241 2.51941 10 4.37559C10.9758 2.51953 12.5568 1.21094 14.6875 1.21094C17.7582 1.21094 20 3.78965 20 6.93418C20 11.0927 16.0323 13.7034 10.3859 18.6441C10.1649 18.8374 9.83508 18.8374 9.61414 18.6441Z"
                                fill="white"
                                class="active"
                                fill-opacity="0.9"
                              />
                              <path
                                d="M14.6875 1.21094C12.5569 1.21094 10.9759 2.51941 10 4.37559C9.02418 2.51953 7.4432 1.21094 5.3125 1.21094C2.24184 1.21094 0 3.78965 0 6.93418C0 11.0927 3.96766 13.7034 9.61414 18.6441C9.83512 18.8374 10.1649 18.8373 10.3859 18.6441C16.0271 13.7079 20 11.0932 20 6.93418C20 3.78914 17.7578 1.21094 14.6875 1.21094ZM10 17.4258C4.79992 12.9088 1.17188 10.3996 1.17188 6.93418C1.17188 4.33949 2.95195 2.38281 5.3125 2.38281C7.58797 2.38281 8.99809 4.49012 9.43524 6.05453C9.59289 6.62523 10.4052 6.62777 10.5648 6.05453C10.9961 4.51086 12.3899 2.38281 14.6875 2.38281C17.048 2.38281 18.8281 4.33949 18.8281 6.93418C18.8281 10.4016 15.1941 12.914 10 17.4258Z"
                                fill="#1AC386"
                              />
                            </svg>
                          {% endif %}
                        </button>
                        {% endif %}
                        <div class="balloon-card__photos">
                      <div class="balloon-card__carousel balloon-carousel ymaps-cluster-owl-carousel owl-carousel">
                            ` +
								`{% for item in unit.images %}` +
								`<div class="balloon-carousel__item">
                  <img width="200" height="150" class="balloon-carousel__img" src="{{ item.image.jpg[480]['1x'] }}" />
                                </div>` +
								`{% endfor %}` +
								`
                          </div>
                        </div>
                        <a class="balloon-card__link search-place-ymaps-link" id="$[unit.id]">
                          <span class="balloon-card__rating">
                            <b>$[unit.rating]</b>
                            <span class="reviews-count">($[unit.reviews_count])</span>
                          </span>
                          <p class="balloon-card__name">$[unit.title]</p>
                          <span class="balloon-card__price">
                            <b>$[unit.price] р</b> за ночь
                          </span>
                        </a>
                      </div>
										</li>` +
								`
										{% endfor %}` +
								`
									</ul>
								</div>
							</div>`,
							{
								build: function () {
									this.constructor.superclass.build.call(this)

									this._$element = $(
										'.cluster-balloon',
										this.getParentElement()
									)

									this.applyElementOffset()

									this._$element
										.find('.cluster-balloon-close')
										.on('click', $.proxy(this.onCloseClick, this))

									this._$element
										.find('.balloon-favorite')
										.on('click', $.proxy(this.setFavorite, this))

									this._$element
										.find('.search-place-ymaps-link')
										.on('click', $.proxy(this.goTo, this))
								},
								goTo: function (payload) {
									push({
										path: `/place/${payload.currentTarget.id}`,
										query: qrs.value
									})
								},
								setFavorite: async function (event) {
                  const [id, isFavourite] = event.currentTarget.id.split(' ')
                  if (await setFavourite(id, isFavourite === 'true')) {
                    event.currentTarget.setAttribute('id', `${id} ${isFavourite !== 'true'}`)
                    if (event.currentTarget.children[0].getAttribute('fill') === 'none') {
                      event.currentTarget.children[0].setAttribute('fill', "#1AC386")
                    } else {
                      event.currentTarget.children[0].setAttribute('fill', "none")
                    }
                    if (event.currentTarget.children[0].children[0].getAttribute('fill') === 'white') {
                      event.currentTarget.children[0].children[0].setAttribute('fill', "#1AC386")
                    } else {
                      event.currentTarget.children[0].children[0].setAttribute('fill', "white")
                    }
                    // const balloon = map.balloon.getData()
                    // const place = balloon.properties._data.lists.find((item) => item.id.toString() === id)
                    // place.is_favorite = !place.is_favorite
                    // map.balloon.setData(balloon)
                    // $('.ymaps-cluster-owl-carousel').owlCarousel({
                    //   items: 1,
                    //   nav: true,
                    //   dots: false
                    // })
                  }
								},
								clear: function () {
									this._$element.find('.cluster-balloon-close').off('click')

									this.constructor.superclass.clear.call(this)
								},
								onSublayoutSizeChange: function () {
									MyBalloonLayoutCluster.superclass.onSublayoutSizeChange.apply(
										this,
										arguments
									)

									if (!this._isElement(this._$element)) {
										return
									}

									this.applyElementOffset()

									this.events.fire('shapechange')
								},
								applyElementOffset: function () {
									this._$element.css({
										left: -(this._$element[0].offsetWidth / 2),
										top: -this._$element[0].offsetHeight
									})
								},
								onCloseClick: function (e) {
									e.preventDefault()

									this.events.fire('userclose')
								},
								getShape: function () {
									if (!this._isElement(this._$element)) {
										return MyBalloonLayoutCluster.superclass.getShape.call(this)
									}

									var position = this._$element.position()

									return new ymaps.shape.Rectangle(
										new ymaps.geometry.pixel.Rectangle([
											[position.left, position.top],
											[
												position.left + this._$element[0].offsetWidth,
												position.top +
													this._$element[0].offsetHeight +
													this._$element.find('.arrow')[0].offsetHeight
											]
										])
									)
								},
								_isElement: function (element) {
									return element && element[0] && element.find('.arrow')[0]
								}
							}
						)

					objectManager.clusters.options.set({
						clusterDisableClickZoom: true,
						clusterOpenBalloonOnClick: true,
						balloonLayout: MyBalloonLayoutCluster,
            balloonPanelMaxMapArea: 0,
						clusterBalloonPanelMaxMapArea: 0,
						clusterBalloonMaxHeight: 200,
						clusterIcons: [
							{
								// href: `/images/ballon.svg`,
								href: '/',
								size: [100, 100],
								offset: [0, 0]
							}
						],
						clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
							'<span class="balloon-count">$[properties.geoObjects.length]</span>'
						)
					})

					objectManager.clusters.events.add('click', function (e) {
						var objectId = e.get('objectId')
						var cluster = objectManager.clusters.getById(objectId)
						var ids = cluster.features.map((itm) => itm.id)

						_loadClustersData(ids).then((data) => {
              data.forEach((item) => {
                const ratings = item.response.ratings
                const rating = (parseFloat(ratings.convenience_location) + parseFloat(ratings.hospitable) +
                  parseFloat(ratings.sociality) + parseFloat(ratings.purity) ) / 4
                item.response.rating = rating.toFixed(1)
                item.response.authenticated = store.getters.isAuth
              })

							objectManager.clusters.getById(objectId).properties.lists =
								data.map((itm) => itm.response)

							objectManager.clusters.balloon.open(objectId)

							$('.ymaps-cluster-owl-carousel').owlCarousel({
								items: 1,
								nav: true,
								dots: false
							})

							map.setCenter(e.get('coords'))
						})
					})

					objectManager.objects.events.add('click', function (e) {
						var objectId = e.get('objectId')

						_loadBalloonData(objectId).then((data) => {
              const ratings = data.response.ratings
              const rating = (parseFloat(ratings.convenience_location) + parseFloat(ratings.hospitable) +
                parseFloat(ratings.sociality) + parseFloat(ratings.purity) ) / 4
              data.response.rating = rating.toFixed(1)
              data.response.authenticated = store.getters.isAuth

							objectManager.objects.getById(objectId).properties = data.response
							objectManager.objects.balloon.open(objectId)

							$('#owl-car-ymaps').owlCarousel({
								items: 1,
								nav: true,
								dots: false
							})

							map.setCenter(e.get('coords'))
						})
					})

					map.events.add('boundschange', drawCircle)

					if (markers.value && markers.value.length) {
						objectManager.add(markers.value)
					}

					map.geoObjects.add(objectManager)

					watch(markers, () => {
						objectManager.add(markers.value)
					})
				} else if (isCoords.value) {
					map = new ymaps.Map(
						'map-ymaps',
						{
							center: coords.value,
							zoom: 13,
							restrictMapArea: true,
							controls: ['zoomControl']
						},
						{
							minZoom: 5,
							maxZoom: 21,
							restrictMapArea: true
						}
					)

					const point = new ymaps.Placemark(
						coords.value,
						{},
						{
							iconLayout: 'default#image',
							iconImageHref: mapIcon.value.src,
							iconImageSize: mapIcon.value.size
						}
					)

					map.geoObjects.add(point)

					return
				}
			})
		})

		watch(coords, () => {
			if (!isCoords.value || !ymaps) {
				return
			}

			ymaps.ready(() => {
				map = new ymaps.Map(
					'map-ymaps',
					{
						center: coords.value,
						zoom: 13,
						restrictMapArea: true,
						controls: ['zoomControl']
					},
					{
						minZoom: 5,
						maxZoom: 21,
						restrictMapArea: true
					}
				)

				const point = new ymaps.Placemark(
					coords.value,
					{},
					{
						iconLayout: 'default#image',
						iconImageHref: mapIcon.value.src,
						iconImageSize: mapIcon.value.size
					}
				)

				map.geoObjects.add(point)

				return
			})
		})

		return {
			ymapsMain,

			settings: ymaps_config
		}
	}
}
</script>

<style lang="scss">
.balloon-count {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;

	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 23px;
	color: #5f5350;

	background-color: white;
	border: 5px solid #5f5350;
	border-radius: 50%;
}

.icon-layout {
	position: absolute;
	top: -25px;

	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	margin: 0 auto;
	padding: 7px;

	color: white;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 14px;
	white-space: nowrap;

	background-color: #5f5350;
	border-radius: 5px;
}

.owl-carousel {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto 0;
		left: 0;
		width: 100%;
		// padding: 0 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		button {
			position: relative;
			width: 25px;
			height: 25px;
			font-size: 22px !important;
			color: #3b3f47 !important;
			background-color: rgba(26, 195, 134, 0.9) !important;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
			border-radius: 50%;
			z-index: 4;

			&:hover {
				color: #ffffff;
				background-color: rgba(26, 195, 134, 0.6);
			}

			&--left {
				left: 10px;
			}

			&--right {
				right: 10px;
			}
			& span {
				position: absolute;
				color: white;
				top: -2px;
				left: 9px;
			}
		}
	}
}
</style>
