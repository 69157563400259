<template>
	<div class="invalid">
		<transition name="fade" mode="out-in">
			<template v-if="error && error.status">
				<template v-if="error.isRender">
					<component
						v-bind="$attrs"
						:is="error.component"
						:data="error.text"
						class="invalid__text"
					/>
				</template>
				<div
					v-else
					class="invalid__text"
				>{{ error.value }}</div>
			</template>
		</transition>
	</div>
</template>

<script>
import timeOut from '@/components/TimeOut'

export default {
	components: {
		timeOut
	},
	props: {
		data: {
			type: [String, Object],
			default: null
		}
	},
	computed: {
		error() {
			return this.data
		}
	}
}
</script>