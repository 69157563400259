export default (axios) => ({
  /**
   * Образец запроса: account/users?users[]=rmbln03ag0&users[]=jGzmdv8vpK&with=hobbies
   *
   * @param ids
   * @returns {*}
   */
  get(ids, { controller } = { controller: new AbortController() }) {
    let query = '?'

    for (let i = 0; i < ids.length; i++) {
      if (i > 0) query += "&"
      query += ("users[]=" + ids[i].toString())
    }

    return axios.get(`account/users${query}`, { signal: controller.signal })
  },

  /**
   * По id пользователей возвращает их id + имена + аватарки
   *
   * @param ids
   * @returns {Promise<{}>}
   */
  async getNamesAndAvatars(ids, { controller } = { controller: new AbortController() }) {
    // Получаем информацию о пользователях для отображения аватарок и имен
    const response = await this.get(ids, { controller: controller })

    if (!(response && response.status)) {
      throw 'bad response' + response
    }

    // Перебираем полученные данные и оставляем поля:
    // Отображаемое имя: имя + фамилия
    // id
    // Аватарка
    const prepUsers = response.response.users.map((u) => {
      let user = {}
      user.id = u.id
      user.name = u.name + " " + u.surname
      // Перебираем аватарки и ищем активную (текущую)
      for (let i = 0; i < u.avatars.length; i++) {
        if (u.avatars[i].is_active) {
          user.avatar = u.avatars[i]
          break; // если нашли, можно прерывать цикл
        }
      }
      return user
    })

    // Создаем отображение: id пользователя - информация о нем
    const users = {}
    prepUsers.forEach((u) => { users[u.id] = u })
    return users
  }
})