import {
	GET_ACT,
	DELETE_ACT,
	CREATE_ACT
} from '@/constants/store/favourites/actions'
import { SET_MUT, DELETE_MUT } from '@/constants/store/favourites/mutations'

export default {
	[GET_ACT]: async function ({ commit }, payload) {
		const response = await this.$api.favorites.get(payload)

		if (!response.status) {
			return {
				status: response.status
			}
		}

		commit(SET_MUT, { key: 'content', value: response.response })
	},
	[DELETE_ACT]: async function ({ commit }, payload) {
		const response = await this.$api.favorites.delete(payload)

		if (!response.status) {
			return {
				status: response.status
			}
		}

		commit(DELETE_MUT, { key: 'content', id: payload.place_id })
	},
	[CREATE_ACT]: async function (_, payload) {
		return this.$api.favorites.create(payload)
	}
}
