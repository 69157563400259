/**
 * По переданным дате-времени возвращает их в формате DD.MM.YYYY HH:MM
 *
 * @remarks
 * К примеру, '0' + raw.getDate() возвращает 07, если число седьмое и
 * 013, если тринадцатое. Если взять последние два символа в обоих случаях
 * получится искомый формат.
 *
 * @param datetime - время (от начала)
 * @returns {string}
 */
export function formatDatetime(datetime) {
  const raw = new Date(datetime * 1000)
  const date = (
    ('0' + raw.getDate()).slice(-2) +
    '.' +
    ('0' + (raw.getMonth() + 1)).slice(-2) +
    '.' +
    raw.getFullYear()
  )
  const time =
    ('0' + raw.getHours()).slice(-2) +
    ':' +
    ('0' + raw.getMinutes()).slice(-2)

  return `${date} ${time}`
}

/**
 * Декодирует токен и возвращает содержимое payload в виде объекта
 *
 * @param token
 * @return {Object} - содержимое payload в виде объекта
 */
export function decodeJWT(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
