export default class ChatEvent {
  /**
   * Тип события {@link ChatEventsEnum}
   */
  type

  /**
   * Объект, которому обязына событием
   */
  obj

  constructor(type, obj) {
    this.type = type
    this.obj = obj
  }
}