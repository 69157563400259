<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.75C2.86193 4.75 2.75 4.86193 2.75 5V17C2.75 17.1381 2.86193 17.25 3 17.25H21C21.1381 17.25 21.25 17.1381 21.25 17V5C21.25 4.86193 21.1381 4.75 21 4.75H3ZM1.25 5C1.25 4.0335 2.0335 3.25 3 3.25H21C21.9665 3.25 22.75 4.0335 22.75 5V17C22.75 17.9665 21.9665 18.75 21 18.75H3C2.0335 18.75 1.25 17.9665 1.25 17V5Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 8.75V13.25H13.25V8.75H6.75ZM5.25 8.5C5.25 7.80964 5.80964 7.25 6.5 7.25H13.5C14.1904 7.25 14.75 7.80964 14.75 8.5V13.5C14.75 14.1904 14.1904 14.75 13.5 14.75H6.5C5.80964 14.75 5.25 14.1904 5.25 13.5V8.5Z" fill="#767582"/>
    <path d="M18.5 8.5C19.0523 8.5 19.5 8.05228 19.5 7.5C19.5 6.94772 19.0523 6.5 18.5 6.5C17.9477 6.5 17.5 6.94772 17.5 7.5C17.5 8.05228 17.9477 8.5 18.5 8.5Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.25 11C17.25 10.5858 17.5858 10.25 18 10.25H19C19.4142 10.25 19.75 10.5858 19.75 11C19.75 11.4142 19.4142 11.75 19 11.75H18C17.5858 11.75 17.25 11.4142 17.25 11Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.25 14.5C17.25 14.0858 17.5858 13.75 18 13.75H19C19.4142 13.75 19.75 14.0858 19.75 14.5C19.75 14.9142 19.4142 15.25 19 15.25H18C17.5858 15.25 17.25 14.9142 17.25 14.5Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 17.25C6.41421 17.25 6.75 17.5858 6.75 18V21C6.75 21.4142 6.41421 21.75 6 21.75C5.58579 21.75 5.25 21.4142 5.25 21V18C5.25 17.5858 5.58579 17.25 6 17.25Z" fill="#767582"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 17.25C18.4142 17.25 18.75 17.5858 18.75 18V21C18.75 21.4142 18.4142 21.75 18 21.75C17.5858 21.75 17.25 21.4142 17.25 21V18C17.25 17.5858 17.5858 17.25 18 17.25Z" fill="#767582"/>
  </svg>
</template>
