import { formatDatetime } from "@/chat/utils";

/**
 * Пользватель чата / автор сообщения
 */
export default class ChatUser {
  constructor(user) {
    this.id = user.id
    this.avatar = user.avatar
    this.is_online = user.is_online
    this.name = user.name
    this.time_last_online = user.time_last_online
  }

  get lastTimeOnline() {
    return formatDatetime(this.time_last_online)
  }
}