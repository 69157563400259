<template>
	<div class="description-user">
    <div class="description-user__wrapper">
      <div class="description-user__full-block">
        <div class="description-user__avatar">
          <tk-image
              class="description-user__photo"
              :src="user.avatars?.find((avatar) => avatar.is_active) ?? '/images/default-avatar.svg'"
          />
        </div>
        <span class="description-user__name">{{ name }}</span>

        <div class="description-user__progress">
          <Progress :achievements="achievements" />
        </div>
      </div>

      <div class="description-user__contact-data modal-contact">
        <ul class="modal-contact__list">
          <template v-for="item in contact" :key="item.title">
            <li v-if="item.field && !(item.title === 'Email' && !user.email_verified_at)" class="modal-contact__item">
              <span class="modal-contact__title">{{ item.title }}</span>
              <span class="modal-contact__value" v-html="item.value" />
            </li>
          </template>
        </ul>
      </div>

      <div
          v-if="answers?.length"
          class="description-user__contact-data modal-contact"
      >
        <ul class="modal-contact__list">
          <template v-for="item in answers" :key="item.title">
            <li v-if="item.field" class="modal-contact__item">
              <span class="modal-contact__title">{{ item.title }}</span>
              <span class="modal-contact__value" v-html="item.value" />
            </li>
          </template>
        </ul>
      </div>

      <div
          v-if="user.hobbies?.length"
          class="description-user__hobbies modal-hobbies"
      >
        <h3 class="modal-hobbies__title">Интересы</h3>
        <ul class="modal-hobbies__list">
          <li
              v-for="item in user.hobbies"
              :key="item.id"
              class="modal-hobbies__item"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>

      <div v-if="reviewsCount" class="description-user__reviews modal-reviews">
        <div class="modal-reviews__header">
          <div class="rating">
          <span class="rating__title">
            Отзывы ({{ reviewsCount }})
          </span>
            <div class="rating__value">
              <span>{{ achievements.rating }}</span> / 5
            </div>
            <div class="criteria-item__wrapper">
            <span
                v-for="itm of 5"
                :key="itm"
                :class="{ active: itm <= achievements.rating }"
            />
            </div>
          </div>
          <div class="reviews-sended-card__wrapper">
            <div class="userpic">
              <tk-image :src="review.avatar" :alt="review.author_name" />
            </div>
            <div>
              <p class="date">{{ getDateInFormat(review.created_at) }}</p>
              <p class="username">{{ review.author_name }}</p>
            </div>
          </div>
          <p class="reviews-sended-card__text">
            {{ review.text }}
          </p>
          <RatingList :ratings="review.ratings" />
          <div class="btns-wrap">
            <tk-button class="button border-btn" @click="prevReview">
              <!--						&leftarrow; Туда-->
              <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.30076 0.422023C5.05512 0.186211 4.66718 0.186211 4.42154 0.422023L0 4.66667L4.42154 8.91131C4.66718 9.14712 5.05512 9.14712 5.30076 8.91131C5.561 8.66148 5.561 8.24527 5.30076 7.99545L1.83325 4.66667L5.30076 1.33789C5.561 1.08806 5.561 0.671851 5.30076 0.422023Z"
                />
              </svg>
            </tk-button>
            <tk-button class="button border-btn" @click="nextReview">
              <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.899579 11.4574C1.2154 11.7606 1.71418 11.7606 2.03001 11.4574L7.71484 6L2.03 0.5426C1.71418 0.239414 1.2154 0.239415 0.899578 0.542601C0.564984 0.863808 0.564983 1.39893 0.899577 1.72014L5.3578 6L0.899579 10.2799C0.564985 10.6011 0.564985 11.1362 0.899579 11.4574Z"
                />
              </svg>
              <!--						Сюда &rightarrow;-->
            </tk-button>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import Progress from '@/components/modals/DescriptionUser/Progress'
import { getUserName } from '@/utils/helpers'
import { getDateInFormat } from '@/utils/dateTime'
import RatingList from '@/components/cards/review/RatingList'
import TkButton from '@/components/global/tkButton'
import progress_icon from "@/constants/progress";

export default {
	components: { TkButton, Progress, RatingList },
	props: {
		content: {
			type: Object,
			default: () => ({ id: '' })
		}
	},
	data() {
		return {
			user: {
				email: '',
				phone: '',
				created_at: '',
				answers: [],
        email_verified_at: null,
			},
			review: {},
			reviewIndex: 1,
			reviewsCount: 0,
      achievements: {},
      answersCodes: {},

      loadAbortController: new AbortController(),
      reviewAbortController: new AbortController(),
		}
	},
	computed: {
		contact() {
			return [
				{
					title: 'Дата регистрации',
					value: getDateInFormat(this.user.created_at),
					field: this.user.created_at
				},
				{
					title: 'Телефон',
					value: this.user.phone_verified_at ? 'Подтверждён' : 'Не подтверждён',
					field: true
				},
				{
					title: 'Email',
					value: `<a href="mailto:${this.user.email}">${this.user.email}</a>`,
					field: this.user.email
				}
			]
		},
		answers() {
			return this.user.answers.map((itm) => {
        if (this.answersCodes[itm.id]?.options) {
          return {
          	title: itm.text,
          	value: this.answersCodes[itm.id].options[parseInt(itm.answer)],
          	field: 'answers'
          }
        } else {
          return {
            title: itm.text,
            value: itm.answer,
            field: 'answers'
          }
        }
			})
		},
		name() {
			return getUserName(this.user)
		}
	},
	methods: {
		async getReview() {
      this.reviewAbortController.abort()
      this.reviewAbortController = new AbortController()

			let response = await this.$api.reviews.getReviewOnUser({
				user_id: this.content.id,
				page: this.reviewIndex,
				per_page: 1
			}, { controller: this.reviewAbortController })

			if (response && response.status) {
				this.review = response.response.items[0]
				this.reviewsCount = response.response.meta.total
			} else {
        return
      }

      if (this.review) {
        response = await this.$api.usersList.getNamesAndAvatars([
          this.review.user_id
        ], { controller: this.reviewAbortController })
        const author = response[this.review.user_id]
        this.review.author_name = author.name
        this.review.avatar = author.avatar
      }
		},
		prevReview() {
			if (this.reviewIndex <= 1) {
				return
			}

			this.reviewIndex--
			this.getReview()
		},
		nextReview() {
			if (this.reviewIndex >= this.reviewsCount) {
				return
			}

			this.reviewIndex++
			this.getReview()
		},
    async getUserDescription() {
      let response = await this.$api.user.getDescriptionUser({
        user: this.content.id
      }, { controller: this.loadAbortController })

      if (response?.status) {
        this.user = response.response.users[0]
      }
    },
    async getQuestionList() {
      const guestAnswers = await this.$api.questions.get({ payload: 'GUEST' }, { controller: this.loadAbortController })
      const masterAnswers = await this.$api.questions.get({ payload: 'MASTER' }, { controller: this.loadAbortController })

      if (guestAnswers?.status && masterAnswers?.status) {
        const answersGroups = [...guestAnswers.response, ...masterAnswers.response]
        answersGroups.forEach((group) => {
          group.questions.forEach((question) => {
            if (!Object.hasOwn(this.answersCodes, question.id)) {
              if (question.options) {
                const options = {}
                question.options.forEach((o) => {
                  options[o.id.toString()] = o.text
                })
                question.options = options
              }
              this.answersCodes[question.id] = question
            }
          })
        })
      }
    },
    async getUserProgress() {
      const response = await this.$api.progress.get([this.content.id], { controller: this.loadAbortController })

      if (response?.status) {
        this.achievements = response.response[this.content.id]
        this.achievements.client.icon = progress_icon[this.achievements.client.id]
        this.achievements.owner.icon = progress_icon[this.achievements.owner.id]
        this.achievements.client.rating = parseFloat(this.achievements.client.rating)
        this.achievements.owner.rating = parseFloat(this.achievements.owner.rating)
        if (this.achievements.client.rating > 0 && this.achievements.owner.rating > 0) {
          this.achievements.rating =
            (this.achievements.client.rating * this.achievements.client.rating_count
              + this.achievements.owner.rating * this.achievements.owner.rating_count)
            / (this.achievements.client.rating_count + this.achievements.owner.rating_count)
        } else if (this.achievements.client.rating > 0 && this.achievements.owner.rating === 0) {
          this.achievements.rating = this.achievements.client.rating
        } else if (this.achievements.client.rating === 0 && this.achievements.owner.rating > 0) {
          this.achievements.rating = this.achievements.owner.rating
        } else {
          this.achievements.rating = 0
        }
        this.achievements.rating = this.achievements.rating.toFixed(2)
      }
    },
		getDateInFormat
	},
	async mounted() {
		if (!this.content.id) {
			this.$store.commit('hideModal')
		}

    await Promise.all([
      this.getUserDescription(),
      this.getUserProgress(),
      this.getQuestionList(),
      this.getReview()
    ])
	},
  beforeUnmount() {
    this.loadAbortController.abort()
    this.reviewAbortController.abort()
  }
}
</script>

<style lang="sass">
.description-user
  width: 100%
  padding: 30px 20px 40px 40px
  max-width: 500px
  background: #FFFFFF
  backdrop-filter: blur(54.3656px)
  border-radius: 14px
  overflow: hidden
  &__wrapper
    max-height: 70vh
    overflow: auto
    padding-right: 20px
  &__full-block
    display: flex
    flex-wrap: wrap
    justify-content: center
    padding-bottom: 25px
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)

  &__name
    display: flex
    flex-basis: 100%
    justify-content: center
    margin-top: 5px
    font-style: normal
    font-weight: 500
    text-align: center
    font-size: 14px
    line-height: 16px
    color: #3B3F47

  &__avatar
    position: relative
    width: 60px
    height: 60px
    border-radius: 50%
    overflow: hidden
    flex-shrink: 0

  &__photo
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    object-fit: cover

  &__progress
    display: flex
    margin-top: 10px

    & > div:first-child
      margin-right: 20px

  .modal-contact
    padding: 20px 0
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)

    &__list
      margin: 0

    &__item
      display: flex
      justify-content: space-between
      gap: 10px
      margin-bottom: 10px

      &:last-child
        margin-bottom: 0

    &__title
      width: 45%
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 17px
      color: #A3A3A3

    &__value
      font-weight: 400
      font-size: 14px
      line-height: 16px
      color: #3B3F47
      text-align: right

      a
        color: $green

  .modal-hobbies
    padding: 30px 0
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)

    &__title
      margin: 0
      margin-bottom: 20px
      font-weight: 700
      font-size: 24px
      line-height: 24px
      color: #3B3F47

    &__list
      display: flex
      flex-wrap: wrap
      gap: 5px
      margin: 0

    &__item
      padding: 5px 10px
      font-style: normal
      font-weight: 500
      font-size: 12px
      line-height: 16px
      color: #FFFFFF
      background: #1AC386
      border: 1px solid rgba(0, 0, 0, 0.05)
      border-radius: 4px

.rating
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 5px
  margin-top: 15px
  &__title
    display: block
    margin-right: auto
    font-weight: 700
    font-size: 24px
    line-height: 24px
    color: #3B3F47
  &__value
    margin-right: 20px
    font-weight: 400
    font-size: 14px
    line-height: 17px
    color: #A3A3A3
    span
      font-weight: 400
      font-size: 14px
      line-height: 17px
      color: #1AC386

.criteria-item__wrapper
  display: flex
  justify-content: space-between
  span
    width: 16px
    height: 16px
    position: relative
    &:before
      content: url("~@/assets/images/svg/star-small-grey.svg")
      position: absolute
      left: 0
      top: 0
    &.active
      &:before
        content: url("~@/assets/images/svg/star-small-green.svg")

.btns-wrap
  margin: 40px 0 5px 0
  display: flex
  justify-content: center
  align-items: center
  gap: 15px

  button
</style>
