<template>
	<div class="infomodal">
		<div class="infomodal__wrapper">
			<div class="infomodal__logo">
				<img
					src="@/assets/img/svg/logo.svg"
					width="87"
					height="68"
					alt="Логотип Теремок"
				/>
			</div>
			<p class="infomodal__text">
				Вы внесли изменения в бронирование. Информация будет отправлена хозяину.
				В случае отказа от новых условий средства будут возвращены в течение 72
				часов.
			</p>
			<div class="infomodal__buttons infomodal__buttons--one">
				<a @click="hide">ОК</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BookingEditComplete',
	methods: {
		hide() {
			this.$store.commit('hideModal')
		}
	}
}
</script>
