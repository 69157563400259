export default {
  5: 'wifi',
  6: 'air-conditioner',
  7: 'heater',
  8: 'stove',
  9: 'microwave-oven',
  10: 'dishwasher',
  11: 'hair-dryer',
  12: 'tv',
  13: 'fire-alarm',
  14: 'washer',
  15: 'iron',
  18: 'workplace',
  19: 'free-parking',
  20: 'bathroom-accessory',
  21: 'baby-crib',
  22: 'high-chair',
  23: 'playpen',
  24: 'toys',
  25: 'disabled-parking',
  26: 'wheelchair',
  27: 'wide-doorway',
  28: 'toilet',
  29: 'wide-doorway'
}