import { createI18n } from 'vue-i18n/index'

function loadLocaleMessages() {
	const locales = require.context(
		'../locales',
		true,
		/[A-Za-z0-9-_,\s]+\.json$/i
	)
	const messages = {}
	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
}

function getBrowserLocale(options = {}) {
	const defaultOptions = { countryCodeOnly: false }
	const opt = { ...defaultOptions, ...options }
	const navigatorLocale =
		navigator.languages !== undefined
			? navigator.languages[0]
			: navigator.language
	if (!navigatorLocale) {
		return undefined
	}
	return opt.countryCodeOnly
		? navigatorLocale.trim().split(/-|_/)[0]
		: navigatorLocale.trim()
}

export default createI18n({
	legacy: false,
	globalInjection: true,
	// locale: getBrowserLocale({ countryCodeOnly: true }) || 'en',
	// fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	locale: 'ru',
	fallbackLocale: 'ru',
	messages: loadLocaleMessages()
})
