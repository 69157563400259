<template>
  <footer class="footer">
    <div class="container">
      <nav class="footer__nav nav-footer">
      						<template v-for="itm in menu">
      							<div class="nav-footer__container" v-if="itm.isShow" :key="itm.title">
<!--      								<h4 class="nav-footer__title">{{ itm.title }}</h4>-->
      								<ul class="nav-footer__list">
      									<template v-for="link in itm.links">
      										<li
      											class="nav-footer__item"
      											v-if="link.isShow"
      											:key="link.title"
      										>
      											<router-link
      												:event="link.isDisabled ? '' : 'click'"
      												:to="link.link"
      												:class="[link.isDisabled && 'disabled']"
      											>
      												{{ link.title }}
      											</router-link>
      										</li>
      									</template>
      								</ul>
      							</div>
      						</template>
      					</nav>
      <div class="footer__copyright copyright-footer">
        <div class="copyright-footer__container">
          <router-link
              to="/"
              class="copyright-footer__logo"
              rel="noopener noreferrer"
          >
            <img
                width="41"
                height="32"
                src="/images/logo.svg"
                :alt="$t('home_page.logo_alt')"
            />
          </router-link>
          <div class="copyright-footer__item">
            <img
                src="@/assets/img/pages/home/logo-skolkovo.svg"
                width="142"
                height="40"
                alt="Резидент сколково"
                class="copyright-footert__icon--skolkovo"
            />
          </div>
          <div class="copyright-footer__text">
            <span>
              	Резидент <br />
								Сколково
            </span>
          </div>
        </div>
        <p class="copyright-footer__description">
          © 2023 Teremok, Inc. All rights reserved ·
          <br/>
          <router-link
              class="copyright-footer__link"
              to="/privacy"
              rel="noopener noreferrer"
          >
            Пользовательское соглашение
          </router-link>
          ·
          <router-link
              class="copyright-footer__link"
              to="/license-guest"
              rel="noopener noreferrer"
          >
            Лицензионное соглашение для гостя
          </router-link>
          ·
          <router-link
              class="copyright-footer__link"
              to="/license-housekeeper"
              rel="noopener noreferrer"
          >
            Лицензионное соглашение для арендодателя
          </router-link>

        </p>
        <div class="footer__icons">
          <div class="copyright-footer__socials footer-socials"
               v-for="(itm, idx) in socials" :key="idx"
          >
            <a
                v-if="itm.isShow !== false"
                :key="idx"
                :href="itm.link"
                :aria-label="itm.title"
                target="_blank"
                :class="['footer-socials__item', itm.icon]"
                rel="noopener noreferrer"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { socials, menu } from '@/config'

export default {
  name: 'Footer',
  computed: {
    socials() {
      return socials
    },
    menu() {
      return menu
    }
  }
}
</script>

<style lang="sass">
.footer
  padding: 30px 0 10px
  background-color: #f5f5f5
  &__icons
    display: flex
    gap: 15px
    flex-shrink: 0
  .nav-footer
   display: flex
   justify-content: space-between
   margin: 10px 0 30px 0
   font-size: 1.4rem
   &__container
     width: 100%
   //&__title
   //  margin: 0
   //  font-weight: bold
   //  text-transform: uppercase

   &__list
     margin: 0
     padding: 0
     list-style: none
     display: flex
     gap: 15px
     flex-wrap: wrap
     justify-content: space-around
     font-weight: 700
     font-size: 16px
     line-height: 18px
     color: #3B3F47
     a
       color: inherit
   &__item
     margin-top: 15px

     a.disabled
       color: rgba(59, 63, 71, 0.5)

.copyright-footer
  display: flex
  justify-content: space-between
  align-items: center
  gap: 20px
  padding: 18px 0
  font-size: 1.4rem
  border-top: 1px solid #D9D9D9
  &__logo
    flex-shrink: 0
    margin-top: 10px
    margin-right: 20px
  &__container
    display: flex
    align-items: center
    flex-wrap: wrap
    flex-shrink: 0
    gap: 10px
  &__item
    display: flex
    align-items: center
    margin-right: 20px
  &__text
    display: flex
    gap: 20px
    align-items: center
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 20px
    color: #B0AEAE
    @media screen and (max-width:  479px)
      display: none
    &::before
      content: ''
      display: block
      background-color: #B0AEAE
      height: 48px
      width: 1px

  &__description
    margin-left: 0
    margin-right: auto
    font-weight: 400
    font-size: 13px
    line-height: 18px
    color: #AFAFAF

  &__link
    color: #AFAFAF
    text-decoration: none
    &:hover
      text-decoration: underline
  .footer-socials
    display: flex
    margin: 0
    padding: 0
    list-style: none
    position: relative
    width: 25px
    height: 25px
    &__item
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      &.yt
        background: url("~@/assets/img/svg/socials/youtube.svg") center no-repeat
        background-size: cover
        width: 25px
        height: 25px
      &.tg
        background: url("~@/assets/img/svg/socials/telegram.svg") center no-repeat
        background-size: cover
        width: 25px
        height: 25px
@media screen and (max-width: 1180px)
  .footer
    .copyright-footer
      font-size: 1.2rem

// @media screen and (max-width: 912px)
//   .footer
//     .nav-footer
//       &__item
//         margin-top: 5px

@media screen and (max-width: 800px)
  .footer
    // .nav-footer
    //   flex-direction: column

    //   &__container
    //     width: 100%

    //     &:not(:last-child)
    //       margin-bottom: 20px

    //   &__item
    //     margin-top: 10px

    .copyright-footer
      flex-direction: column
      align-items: flex-start
      padding-top: 30px
      font-size: 1.4rem

// &__socials
//   margin-top: 20px
</style>
