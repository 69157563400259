<template>
	<div class="info-modal">
		<div class="info-modal__logo">
			<img decoding="async" src="@/assets/img/svg/logo.svg" alt="Логотип Теремок" />
		</div>
		<p class="info-modal__text">Пароль успешно изменён.</p>
		<a class="info-modal__button" @click="hide">Ок</a>
	</div>
</template>

<script>
import modals from '@/constants/modals';

export default {
	name: 'InfoRegister',
	methods: {
		showAuth() {
			this.$store.commit('showModal', {
				name: modals.AUTH,
				props: null,
				isShow: true
			})
		},
    hide() {
      this.$store.commit('hideModal')
    },
	}
}
</script>
