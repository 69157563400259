<template>
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_8069_29299)">
			<path
				d="M14.804 10.8043L12.4445 13.1638L11.4719 12.1912C11.2432 11.9625 10.8721 11.9625 10.6433 12.1914C10.4145 12.4201 10.4145 12.7912 10.6433 13.0201L12.0304 14.4068C12.1447 14.5212 12.2946 14.5784 12.4446 14.5784C12.5946 14.5784 12.7445 14.5212 12.8589 14.4068L15.6327 11.633C15.8615 11.4043 15.8615 11.0332 15.6327 10.8045C15.4038 10.5756 15.0328 10.5756 14.804 10.8043Z"
				fill="#CBCBCB"
        class="active-fill"
			/>
			<path
				d="M17.4529 7.17422H13.7239V2.54715C13.7239 1.14273 12.5812 0 11.1766 0H2.547C1.14258 0 0 1.14273 0 2.54715V8.31726C0 9.52011 0.838318 10.5302 1.96106 10.7956V12.2398C1.96106 12.4768 2.10388 12.6904 2.32285 12.7812C2.39532 12.8113 2.47147 12.8258 2.547 12.8258C2.69943 12.8258 2.84927 12.7663 2.96143 12.6541L4.75113 10.8646H6.27625V15.4915C6.27625 16.8961 7.41882 18.0386 8.82324 18.0386H15.2489L17.0384 19.8283C17.1506 19.9405 17.3004 20 17.453 20C17.5284 20 17.6045 19.9855 17.677 19.9554C17.896 19.8647 18.0388 19.651 18.0388 19.4141V17.9707C19.1618 17.7054 20 16.6945 20 15.4915V9.72137C20 8.3168 18.8573 7.17422 17.4529 7.17422ZM4.50836 9.69269C4.35287 9.69269 4.2038 9.75433 4.09409 9.8642L3.13293 10.8252V10.2786C3.13293 9.95499 2.87064 9.69269 2.547 9.69269C1.78879 9.69269 1.17188 9.07562 1.17188 8.31726V2.54715C1.17188 1.78879 1.78879 1.17188 2.547 1.17188H11.1766C11.935 1.17188 12.552 1.78879 12.552 2.54715V7.17422H8.82324C7.42844 7.17422 6.29242 8.30139 6.27686 9.69269H4.50836ZM18.8281 15.4915C18.8281 16.2498 18.2112 16.8668 17.4529 16.8668C17.1292 16.8668 16.8669 17.1292 16.8669 17.4527V17.9994L15.9059 17.0384C15.7961 16.9284 15.647 16.8668 15.4915 16.8668H8.82324C8.06503 16.8668 7.44812 16.2498 7.44812 15.4915V9.72137C7.44812 8.96301 8.06503 8.3461 8.82324 8.3461H17.4529C18.2112 8.3461 18.8281 8.96301 18.8281 9.72137V15.4915Z"
				fill="#CBCBCB"
        class="active-fill"
			/>
		</g>
		<defs>
			<clipPath id="clip0_8069_29299">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
