export default {
	AUTH: 'Auth',
	REGISTRATION: 'Registration',
	RECOVERY: 'Recovery',
	NOT_AUTH: 'NotAuth',
	CHANGE_PHONE: 'ChangePhone',
	PLACE_FILTER: 'PlaceFilter',
	PLACE_CAROUSEL: 'PlaceCarousel',
	AMENITIES: 'AmenitiesModal',
	PLACE: 'Place',
	BOOKING_REQUEST_SUCCESS: 'BookingRequestSuccess',
	COMPLAIN_ON_AD: 'ComplainOnAd',
	SUCCESS_CREATE_OR_CHANGE_PLACE: 'SuccessCreateOrChangePlace',
	GUEST_INFO_REGISTER: 'GuestInfoRegister',
	INFO_REGISTER: 'InfoRegister',
	INFO_REGISTER_STEPS: 'InfoRegisterSteps',
	SUCCESS_CHANGE_PASSWORD: 'SuccessChangePassword',
	REASON_REJECT_BOOKING: 'ReasonRejectBooking',
	BOOKING_CANCELLATION: 'BookingCancellation',
	BOOKING_EDIT_COMPLETE: 'BookingEditComplete',
	SUCCESS_IDENTIFICATION_PHOTO: 'SuccessIdentificationPhoto',
	SUCCESS_COMPLAIN_ON_AD: 'SuccessComplainOnAd',
	CONFIRM_DELETE_ADS: 'ConfirmDeleteAds',
	CONFIRM_TOGGLE_ADS: 'ConfirmToggleAds',
	SUCCESS_CHANGE_PHONE: 'SuccessChangePhone',
	INFO_CONFIRMATION_IN_PROCESS: 'InfoConfirmationInProcess',
	INFO_CONFIRMATION_REJECTED: 'InfoConfirmationRejected',
	COMMON_INFO: 'CommonInfo',
	DESCRIPTION_USER: 'DescriptionUser',
	ARTICLE_CONTENT: 'ArticleContent',
	// GuestModeDescription: 'GuestModeDescription'
}
