export default (axios) => ({
  get({ ids }) {
    let query = '?'

    if (typeof ids === 'string') {
      query += `users[0]=${ids}`
    } else {
      ids.forEach((itm, idx) => {
        query += `users[${idx}]=${itm}`
      })
    }

    return axios.get(`account/users${query}`)
  }
})