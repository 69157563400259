<template>
	<div class="wrapper">
		<div class="amenities">
			<button class="btn amenities__close-btn" @click="hide">
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M13.3 10.0006L19.4872 3.81336C20.1709 3.12973 20.1709 2.0214 19.4872 1.33884L18.6624 0.514003C17.9786 -0.169839 16.8702 -0.169839 16.1877 0.514003L10.0006 6.70105L3.81336 0.512721C3.12973 -0.170907 2.0214 -0.170907 1.33884 0.512721L0.512721 1.33756C-0.170907 2.0214 -0.170907 3.12973 0.512721 3.81229L6.70105 10.0006L0.514003 16.1877C-0.169839 16.8715 -0.169839 17.9798 0.514003 18.6624L1.33884 19.4872C2.02247 20.1709 3.1308 20.1709 3.81336 19.4872L10.0006 13.3L16.1877 19.4872C16.8715 20.1709 17.9798 20.1709 18.6624 19.4872L19.4872 18.6624C20.1709 17.9786 20.1709 16.8702 19.4872 16.1877L13.3 10.0006Z"
						fill="#3B3F47"
					/>
				</svg>
			</button>
			<h3 class="section-title">{{ content.title }}</h3>
			<ul class="amenities__list">
				<li
					v-for="item in content.value"
					:key="item.id"
					class="amenities__item"
				>
					<div class="amenities__item_text">
						<div class="name">{{ item.title }}</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AmentititesModal',
	props: {
		content: {
			type: Object,
			default: () => ({
				title: '',
				value: []
			})
		}
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		}
	}
}
</script>

<style scoped lang="sass">
// .wrapper
// 	display: flex
// 	align-items: center
// 	justify-content: center
// 	padding-left: 15px
// 	padding: 15px
.amenities
	position: relative
	align-items: center
	background: #ffffff
	padding: 40px 110px
	max-width: 860px
	border-radius: 14px
	&__close-btn
		font-size: 20px
		position: absolute
		background: transparent
		color: #3b3f47
		top: 20px
		right: 20px
		border: none
	.section-title
		font-size: 22px
		line-height: 22px
		margin-bottom: 30px
	&__wrap
		display: flex
		justify-content: space-between
	&__list
		list-style: none
		padding-left: 0
		margin-bottom: 30px
		column-count: 2
	&__item
		font-size: 18px
		line-height: 22px
		display: flex
		justify-self: flex-start
		&:not(:last-child)
			margin-bottom: 20px
		.icon
			font-size: 20px
			margin-right: 10px
			color: #574343
		.name
			font-weight: 600
		.title
			font-size: 20px
			line-height: 24px
			font-weight: bold
			margin-bottom: 25px
		&.not-included
			text-decoration: line-through
			color: #C0AEA7
			font-weight: normal
	&__block
		.title
			font-size: 20px
			line-height: 24px
			font-weight: bold
			margin-bottom: 20px
		.amenities__list
			column-count: 1
@media screen and (max-width: 800px)
	// .column-1,
	// .column-2
	// 	width: 100%
	.amenities
		padding: 30px 20px
		&__wrap
			flex-direction: column
		&__list
			column-count: 1
</style>
