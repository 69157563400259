<template>
	<div class="authmodal">
		<div class="authmodal__header">
			<h3 class="title">Изменить номер телефона</h3>
			<tk-button
				class="authmodal__close"
				kind="close-modal"
				type="button"
				aria-label="Кнопка закрытия модального окна"
				@click="hide"
			>
				Кнопка закрытия модального окна
			</tk-button>
		</div>

		<p v-if="stepItem.isIntro" class="authmodal__intro">
			{{ stepItem.intro }} {{ phone }}
		</p>

		<div :key="stepItem.field" class="authmodal__container">
			<template v-if="stepItem.content.type">
				<component
					:is="stepItem.content.type"
					v-bind="stepItem.content.payload"
					@input="
						changeData(stepItem.field, $event),
							clearError(stepItem.errorField, errors)
					"
					:error="errors[stepItem.errorField]"
					@next="next"
				/>
			</template>
			<div
				v-else
				v-html="stepItem.content.value"
				:class="stepItem.content.class"
			/>
		</div>

		<tk-error
			v-if="error.status"
			class="authmodal__common-error"
			@startTimer="isTimeOut = true"
			@endTimer=";(isTimeOut = false), clearMainError()"
			:data="error"
		/>

		<button
			v-if="stepItem.sendOneMore"
			:disabled="isDisabled"
			type="button"
			class="authmodal__repeat"
			@click="oneMoreCode"
		>
			Отправить код повторно
		</button>

		<p v-if="stepItem.isDescription" class="authmodal__description">
			Укажите <b>старый номер</b>, на который придёт короткий код для смены
			номера учётной записи
		</p>

		<tk-button
			v-if="stepItem.nextButtonText"
			:disabled="isDisabled"
			class="authform__submit"
			type="button"
			:aria-label="stepItem.nextButtonText"
			@click="next"
		>
			{{ stepItem.nextButtonText }}
		</tk-button>
	</div>
</template>

<script>
import errorHandler from '@/utils/errorHandler'

import types from '@/components/RegistrationModal/types.vue'

import modals from '@/constants/modals'
import { LOGOUT } from '@/constants/store/auth/actions'

const conf = [
	{
		step: 1,
		field: 'old_phone',
		errorField: 'phone',
		action: 'sendCodeToOldPhone',
		filter: /[^+\d]/g,
		content: {
			type: 'tkInput',
			payload: {
				type: 'tel',
				placeholder: '+7 (000) 000 00 00',
				'aria-label': 'Поле для ввода телефона',
				maska: '+7 (###) ###-##-##'
			}
		},
		isDescription: true,
		nextButtonText: 'Далее'
	},
	{
		step: 2,
		isIntro: true,
		sendOneMore: true,
		errorField: 'code',
		intro: 'Код отправлен в смс на номер',
		field: 'old_phone_code',
		action: 'codeIsValidOldPhone',
		content: {
			type: 'tkSmsCode',
			payload: {}
		}
	},
	{
		step: 3,
		isIntro: true,
		sendOneMore: true,
		errorField: 'code',
		intro: 'Код отправлен в смс на новый номер',
		field: 'new_phone_code',
		action: 'codeIsValidNewPhone',
		content: {
			type: 'tkSmsCode',
			payload: {}
		}
	}
]

// Refactoring

export default {
	name: 'ChangePhone',
	mixins: [errorHandler],
	components: {
		tkTypes: types
	},
	props: {
		content: {
			type: Object,
			default: () => ({
				phone: '',
				reject: () => ({}) // If change phone is not success for return phone field on anketa page
			})
		}
	},
	data() {
		return {
			currentStep: 1,
			isTimeOut: false,
			values: {
				old_phone: '',
				old_phone_code: '',
				new_phone: '',
				new_phone_code: ''
			}
		}
	},
	beforeMount() {
		this._createFieldsError(['phone', 'code'])

		if (this.content.phone) {
			this.values.new_phone = this.content.phone.replace(/[^+\d]/g, '')
		}
	},
	mounted() {
		if (!this.content.phone) {
			return
		}
	},
	computed: {
		config() {
			return conf
		},
		actions() {
			return {
				sendCodeToOldPhone: async ({ old_phone }) =>
					await this.$api.auth.sendCodeVerification({
						phone: old_phone,
						recovery: true
					}),
				codeIsValidOldPhone: async ({ old_phone, old_phone_code }) =>
					await this.$api.auth.codeIsValid({
						phone: old_phone,
						code: old_phone_code
					}),
				codeIsValidNewPhone: async ({ new_phone, new_phone_code }) =>
					await this.$api.auth.codeIsValid({
						phone: new_phone,
						code: new_phone_code
					})
			}
		},
		user() {
			return this.$store.state.user
		},
		stepItem() {
			return this.config.find((itm) => itm.step === this.currentStep)
		},
		isDisabled() {
			return this.isTimeOut
		},
		phone() {
			return this.currentStep === 2
				? this.values.old_phone
				: this.values.new_phone
		}
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		},
		successChangePhone() {
			this.$store.commit('showModal', {
				name: modals.SUCCESS_CHANGE_PHONE,
				isShow: true
			})
		},
		async oneMoreCode() {
			const response = await this.$api.auth.sendCodeVerification({
				phone: this.phone,
				recovery: true
			})

			if (response) {
				this.setError(response)
			}
		},
		async next() {
			if (this.stepItem.action) {
				// check phone is not old
				if (
					this.stepItem.field === 'old_phone' &&
					this.user.phone !== this.values.old_phone
				) {
					this.$store.commit('showNotification', {
						type: 'error',
						text: 'Вы ввели не свой старый номер!'
					})
					return
				}

				// better move to store
				const response = await this.actions[this.stepItem.action](this.values)

				if (!response.status || response.error) {
					this.setError(response)

					return
				}
			}

			if (this.config.length > this.stepItem.step) {
				this.currentStep += 1
			} else {
				// Last step

				const response = await this.$api.user.changePhone({
					...this.values
				})

				if (response && response.status) {
					this.$store.dispatch(LOGOUT)

					setTimeout(() => {
						this.successChangePhone()
					}, 200)
				} else {
					this.content.reject()

					setTimeout(() => {
						this.$store.commit('showNotification', {
							type: 'error',
							text: 'Ошибка смены телефона. Обратитесь к администратору!'
						})

						this.$store.commit('hideModal')
					})
				}
			}
		},
		changeData(field, event) {
			if (this.stepItem.filter) {
				this.values[field] = event.target.value.replace(
					this.stepItem.filter,
					''
				)
			} else {
				this.values[field] = event.target.value
			}
		}
	},
	setup({ content }) {
		// const { errors, error, setError, clearError, clearMainError } =
		// 	useHandleErrors({ fields: ['phone', 'code'] })
		// const { hide, successChangePhone } = useModal()
		// const {
		// 	dispatch,
		// 	state: { user }
		// } = useStore()
		// const actions = {
		// 	sendCodeToOldPhone: async ({ old_phone }) =>
		// 		await api.auth.sendCodeVerification({
		// 			phone: old_phone,
		// 			recovery: true
		// 		}),
		// 	codeIsValidOldPhone: async ({ old_phone, old_phone_code }) =>
		// 		await api.auth.codeIsValid({
		// 			phone: old_phone,
		// 			code: old_phone_code
		// 		}),
		// 	codeIsValidNewPhone: async ({ new_phone, new_phone_code }) =>
		// 		await api.auth.codeIsValid({
		// 			phone: new_phone,
		// 			code: new_phone_code
		// 		})
		// }
		// Methods
		// const oneMoreCode = async () => {
		// 	const response = await api.auth.sendCodeVerification({
		// 		phone: phone.value,
		// 		recovery: true
		// 	})
		// 	if (response) {
		// 		setError(response)
		// 	}
		// }
		// const next = async () => {
		// 	if (step.value.action) {
		// 		// check phone is not old
		// 		if (
		// 			step.value.field === 'old_phone' &&
		// 			user.phone !== data.value.old_phone
		// 		) {
		// 			notify({ type: 'error', text: 'Вы ввели не свой старый номер!' })
		// 			return
		// 		}
		// 		// better move to store
		// 		const response = await actions[step.value.action](data.value)
		// 		if (!response.status || response.error) {
		// 			setError(response)
		// 			return
		// 		}
		// 	}
		// 	if (conf.length > step.value.step) {
		// 		currentStep.value += 1
		// 	} else {
		// 		// Last step
		// 		const response = await api.user.changePhone({
		// 			...data.value
		// 		})
		// 		if (response && response.status) {
		// 			dispatch(LOGOUT)
		// 			setTimeout(() => {
		// 				successChangePhone()
		// 			}, 200)
		// 		} else {
		// 			content.reject()
		// 			setTimeout(() => {
		// 				notify({
		// 					type: 'error',
		// 					text: 'Ошибка смены телефона. Обратитесь к администратору!'
		// 				})
		// 				hide()
		// 			})
		// 		}
		// 	}
		// }
		// const changeData = (field, event) => {
		// 	if (step.value.filter) {
		// 		data.value[field] = event.target.value.replace(step.value.filter, '')
		// 	} else {
		// 		data.value[field] = event.target.value
		// 	}
		// }
		// Computed
		// const step = computed(() =>
		// 	conf.find((itm) => itm.step === currentStep.value)
		// )
		// const isDisabled = computed(() => isTimeOut.value)
		// // choose phone to show
		// const phone = computed(() =>
		// 	currentStep.value === 2 ? data.value.old_phone : data.value.new_phone
		// )
		// return {
		// 	error,
		// 	errors,
		// 	step,
		// 	data,
		// 	phone,
		// 	isTimeOut,
		// 	isDisabled,
		// 	hide,
		// 	clearError,
		// 	clearMainError,
		// 	oneMoreCode,
		// 	changeData,
		// 	next
		// }
	}
}
</script>