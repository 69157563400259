export default (axios) => ({
	/**
	 * Отзывы для гостя
	 *
	 * @param type
	 * @param page
	 * @param per_page
	 * @returns {*}
	 */
	get({ type, page = 1, per_page = 9 }, { controller } = { controller: new AbortController() }) {
		return axios.get(
			`place/client/reviews/${type}?page=${page}&per_page=${per_page}`, { signal: controller.signal }
		)
	},
	getByPlaceId({ place_id, page = 1, per_page = 9999}, { controller } = { controller: new AbortController() }) {
		return axios.get(
			`place/show/${place_id}/reviews?page=${page}&per_page=${per_page}`, { signal: controller.signal }
		)
	},
	getCount({ controller } = { controller: new AbortController() }) {
		return axios.get(`place/client/count-travels`, { signal: controller.signal })
	},

	/**
	 * Отзывы для хозяина
	 *
	 * @param type
	 * @param page
	 * @param per_page
	 * @returns {*}
	 */
	getOwner({ type, page = 1, per_page = 9 }, { controller } = { controller: new AbortController() }) {
		return axios.get(
			`place/owner/reviews/${type}?page=${page}&per_page=${per_page}`, { signal: controller.signal }
		)
	},

	/**
	 * Все отзывы на пользователя: как в роли гостя, так и в роли хоязина
	 *
	 * @param user_id
	 * @param page
	 * @param per_page
	 */
	getReviewOnUser({ user_id, page = 1, per_page = 9}, { controller } = { controller: new AbortController() }) {
		return axios.get(
			`place/user/reviews/list_to_user/${user_id}?page=${page}&per_page=${per_page}`, { signal: controller.signal }
		)
	},

	/**
	 * Удаление отзыва гостя
	 *
	 * @param id
	 * @returns {*}
	 */
	del(id) {
		return axios.delete(`place/client/reviews/${id}`)
	},

	/**
	 * Удаление отзыва хозяина
	 *
	 * @param id
	 * @returns {*}
	 */
	delReviewLandlordToGuest(id) {
		return axios.delete(`place/owner/reviews/${id}`)
	},

	/**
	 * Добавление отзыва гостем на хозяина
	 *
	 * @param id
	 * @param rest
	 * @returns {*}
	 */
	set({ id, ...rest }) {
		return axios.post(`/place/client/reserves/${id}/reviews`, rest)
	},

	/**
	 * Добавление отзыва хозяином на гостя
	 *
	 * @param id
	 * @param rest
	 * @returns {*}
	 */
	setReviewLandlordToGuest({ id, ...rest }) {
		return axios.post(`/place/owner/reserves/${id}/reviews`, rest)
	},

	/**
	 * Помечает, что в выдачу ожидаемых (type: excepted) "отзывов"
	 * больше не попадет отзыв на reserve_id
	 *
	 * @param reserve_id
	 * @returns {*}
	 */
	hideGuestReview(reserve_id) {
		return axios.get(`/place/client/reserves/${reserve_id}/hide_review`)
	},

	/**
	 * Помечает, что в выдачу ожидаемых (type: excepted) "отзывов"
	 * больше не попадет отзыв на reserve_id
	 *
	 * @param reserve_id
	 * @returns {*}
	 */
	hideLandlordReview(reserve_id) {
		return axios.get(`/place/owner/reserves/${reserve_id}/hide_review`)
	}
})
