export default (axios) => ({
	/**
	 * Получить все настройки
	 *
	 * @query { string } - find
	 *
	*/
	get () {
		return axios.get(`account/user/notifications`)
	},
	/**
	 * Обновить настройки
	 *
	*/
	update (payload) {
		return axios.post(`account/user/notifications`, payload)
	}
})