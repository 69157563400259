<template>
	<div class="wrapper">
		<div class="complaintmodal">
			<button
				class="close-modal"
				id="close-modal"
				type="button"
				aria-label="Кнопка закрытия модального окна"
				@click="hide"
			/>

			<h2 class="section-title">Жалоба на объявление</h2>
			<p class="section-subtitle">
				Сообщите нам, что случилось. Мы рассмотрим Ваше обращение и примем
				соответствующие меры.
			</p>

			<p v-if="loading">loading...</p>
			<CancelReason v-else :content="values" @update="submit" />
		</div>
	</div>
</template>

<script>
import CancelReason from '@/components/CancelReasonBlock'
import modals from '@/constants/modals'
import { GET, CREATE } from '@/constants/store/complaints/actions'

export default {
	components: { CancelReason },
	name: 'ComplainOnAd',
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			values: null,
			loading: true
		}
	},
	async beforeMount() {
		const response = await this.$store.dispatch(GET)

		if (response) {
			this.values = response
		}

		this.loading = false
	},
	methods: {
		hide() {
			this.$store.commit('hideModal')
		},
		successComplainOnAd() {
			this.$store.commit('showModal', {
				name: modals.SUCCESS_COMPLAIN_ON_AD,
				isShow: true
			})
		},
		async submit({ form, message }) {
			if (!form) {
				return this.$store.commit('showNotification', {
					type: 'warn',
					text: 'Необходимо выбрать причину'
				})
			}

			const response = await this.$store.dispatch(CREATE, {
				message: message,
				complaint_code: form,
				place_id: this.content.place_id
			})

			if (response.status) {
				setTimeout(() => {
					this.successComplainOnAd()
				}, 100)
			} else {
				this.$store.commit('showNotification', {
					type: 'error',
					text: response.error.complaint[0]
				})
			}
		}
	}
}
</script>

<style scoped lang="sass">
.wrapper
	display: flex
	align-items: center
	justify-content: center
	height: 100%
	padding-left: 15px
	padding-right: 15px
</style>
