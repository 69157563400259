<!--suppress SyntaxError -->
<template>
	<form @submit.prevent id="cancel-reason" class="cancel-reason">
		<ul class="cancel-reason__list">
			<li
				v-for="(itm, idx) in content"
				:key="itm.id"
				class="cancel-reason__item"
			>
				<template v-if="idx < content.length - 1">
					<label :for="itm.code" class="cancel-reason__item_label">
						<span class="cancel-reason__item_text">
							{{ itm.text }}
						</span>
						<div class="switcher cancel-reason__item_switcher">
							<input
								class="tgl tgl-light"
								:id="itm.code"
								name="complaints_on_ad"
								type="radio"
								:value="itm.code"
								v-model.number="form"
							/>
							<label class="tgl-btn" :for="itm.code"></label>
						</div>
					</label>
				</template>
				<template v-else>
					<li class="cancel-reason__item cancel-reason__item_other">
						<label :for="itm.code" class="cancel-reason__item_label">
							<span class="cancel-reason__item_text">
								{{ itm.text }}
							</span>
							<div class="switcher cancel-reason__item_switcher">
								<input
									class="tgl tgl-light"
									:id="itm.code"
									:value="itm.code"
									name="complaints_on_ad"
									type="radio"
									v-model.number="form"
								/>
								<label class="tgl-btn" :for="itm.code"></label>
							</div>
						</label>
						<div class="other-reason" v-show="form === itm.code">
							<textarea
								name="other_reason_textarea"
								class="other-reason__textarea"
								id="other_reason_textarea"
								placeholder="Текст"
								v-model="message"
							/>
							<!-- <button type="button" @click="save" class="other-reason__btn">Готово</button> -->
						</div>
					</li>
				</template>
			</li>
		</ul>
		<button
      type="button"
			class="btn cancel-reason__btn"
			:disabled="isDisabledSubmit"
			@click="submit"
			:aria-label="buttonText"
		>
			{{ buttonText }}
		</button>
	</form>
</template>

<script>
export default {
	props: {
		content: {
			type: Array,
			default: () => []
		},
		buttonText: {
			type: String,
			default: 'Отправить'
		},
		buttonStyle: {
			type: String,
			default: 'main'
		}
	},
	watch: {
		form: function () {
			this.message = ''
		}
	},
	data() {
		return {
			form: null,
			message: ''
		}
	},
	computed: {
		isDisabledSubmit() {
			return !this.form
		}
	},
	methods: {
		submit() {
			this.$emit('update', { form: this.form, message: this.message })
		}
	}
}
</script>

<style lang="sass">
.cancel-reason
  &__list
    padding-left: 0
    margin-bottom: 30px
    box-shadow: 0 0 17px -6px lightgrey
    border-radius: 12px

  &__item
    &_label
      display: flex
      justify-content: space-between
      align-items: center
      padding: 14px 20px 14px 15px

    &:not(:last-child)
      border-bottom: 1px solid #E2E2E2

    &_text
      margin-bottom: 0

    &_other
      .complaintmodal__item_label.active
        box-shadow: 0px 7px 9px -3px lightgrey

  &__btn
    width: 100%
    height: 45px

  .other-reason
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 20px 10px 14px

    &__textarea
      height: 110px
      width: 100%
      padding: 10px
      margin-bottom: 10px

      border: 1px solid #E2E2E2
      border-radius: 12px
      caret-color: #1AC386

      &:focus
        outline: none

  &__btn
    height: 55px
    color: #E93030
    font-weight: 600
    font-size: 16px
    line-height: 16px
    border: 1px solid #E93030
    border-radius: 10px
</style>
