import { createRouter, createWebHistory } from 'vue-router'
import routes from "@/router/routes.mjs";

import store from '@/store'

import mods from '@/constants/mods'
import modals from '@/constants/modals'

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if ( to.meta?.scrollTop ) {
			return { top: 0 }
		}

		// if ( from.name !== "Place" && to.name === "Place" ) {
		// 	return { top: 0 }
		// }

		if ( from.name !== to.name) {
			return { top: 0 }
		}
	},
})

// can switch to place only with these parameters

const notAuthRoutes = [
	'Home',
	'License',
	'LicenseHousekeeper',
	'Terms',
	'Privacy',
	'Places',
	'Place',
	'Article',
	'Owner',
	'Guest',
	'About',
	'Contacts',
	'OrganisationDetails',
]

router.beforeEach((to, _) => {
	// console.log('before each', router, router.currentRoute.value, to)
	//debugger
	if (to.name === 'MainRoute') {
		// console.log('before each: exec guard: into condition')
		if (store.state.mode === mods.GUEST) {
			return { name: 'Guest' }
		} else {
			return { name: 'Owner' }
		}
	}

	// if (to.fullPath.startsWith("/profile") && store.state.mode === mods.MASTER) {
	// 	store.dispatch('getNewBookingsCount')
	// }
})

router.beforeResolve((to, _) => {
	// console.log('before resolve', router, router.currentRoute.value, to)
	if (to.name === 'Place.Create') {
		// console.log('before resolve: exec guard: place create condition')
		if (store.getters.isAccountConfirmed) {
		} else if (store.getters.isAccountUnderVerification) {
			store.commit('showModal', {
				name: modals.INFO_CONFIRMATION_IN_PROCESS
			})
		} else if (store.getters.isAccountVerificationRejected) {
			store.commit('showModal', {
				name: modals.INFO_CONFIRMATION_REJECTED
			})

			return { path: '/profile/anketa/identification' }
		} else {
			return { path: '/profile/anketa/identification' }
		}
	}

	let isNotHave = false

	const _fields = ['city', 'children', 'guests']
	_fields.forEach((itm) => (!to.query[itm] ? (isNotHave = true) : null))

	if (to.meta.isHaveQueries && isNotHave) {
		// console.log('before resolve: exec guard: ????')
		return { path: '/' }
	} else if (!notAuthRoutes.includes(to.name) && !store.getters.isAuth) {
		// console.log('before resolve: exec guard: not auth redirection')
		return { path: '/' }
	}
})
// - can switch to place only with these parameters

export default router
