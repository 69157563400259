<template>
	<div class="place-filter-modal">
		<h1 class="place-filter-modal__title">Фильтр</h1>

		<button
			class="btn place-filter-modal__close-btn"
			@click="hide"
			aria-label="Закрыть модальное окно фильтра"
		/>

		<form
			:key="key"
			@submit.prevent
			class="place-filter-modal__form filter-form"
		>
			<fieldset class="filter-form__fieldset price">
				<fieldset class="filter-form__fieldset price-range">
					<legend class="filter-form__legend">
						Цена
						<span
							>{{ form.price_min || 0 }} - {{ form.price_max || 50000 }} р</span
						>
					</legend>
					<div class="el-slider__wrapper">
						<tk-slider
							v-model="price"
							range
							:show-tooltip="false"
							:step="100"
							:max="50000"
						/>
					</div>
				</fieldset>

				<fieldset class="filter-form__fieldset">
					<legend class="filter-form__legend">Тип жилья</legend>
					<ul class="filter-form__list">
						<li class="filter-form__item">
							<div class="filter-form__text-wrapper">
								<label for="house" class="text">Частный дом</label>
							</div>
							<div class="switcher">
								<input
									v-model="form.type"
									class="tgl tgl-light"
									name="places-filter-type-house"
									id="house"
									:value="2"
									type="radio"
								/>
								<label class="tgl-btn" for="house"></label>
							</div>
						</li>
						<li class="filter-form__item">
							<div class="filter-form__text-wrapper">
								<label for="flat" class="text">Квартира</label>
							</div>
							<div class="switcher">
								<input
									v-model="form.type"
									name="places-filter-type-house"
									class="tgl tgl-light"
									:value="1"
									id="flat"
									type="radio"
								/>
								<label class="tgl-btn" for="flat"></label>
							</div>
						</li>
						<li class="filter-form__item">
							<div class="filter-form__text-wrapper">
								<label for="apartments" class="text">Апартаменты</label>
							</div>
							<div class="switcher">
								<input
									v-model="form.type"
									class="tgl tgl-light"
									name="places-filter-type-house"
									:value="3"
									id="apartments"
									type="radio"
								/>
								<label class="tgl-btn" for="apartments"></label>
							</div>
						</li>
					</ul>
				</fieldset>
			</fieldset>

			<fieldset class="filter-form__fieldset rooms">
				<legend class="filter-form__legend">Кровати и комнаты</legend>
				<ul class="filter-form__list">
					<template v-if="values.space_sleep">
						<li
							v-for="item in values.space_sleep[0].options"
							:key="item.id"
							class="filter-form__item"
						>
							<div class="filter-form__text-wrapper">
								<label :for="`space_sleep-` + item.id" class="text">
									{{ item.title }}
								</label>
							</div>
							<tk-input-number v-model="options[item.id]" :min="0" :max="20" />
						</li>
					</template>

					<template v-if="values.space_bath">
						<li
							v-for="item in values.space_bath[0].options"
							:key="item.id"
							class="filter-form__item"
						>
							<div class="filter-form__text-wrapper">
								<label :for="`space_bath-` + item.id" class="text">{{
									item.title
								}}</label>
							</div>
							<tk-input-number v-model="options[item.id]" :min="0" :max="20" />
						</li>
					</template>

					<template v-if="values.space_kitchen">
						<li
							v-for="item in values.space_kitchen[0].options"
							:key="item.id"
							class="filter-form__item"
						>
							<div class="filter-form__text-wrapper">
								<label :for="`space_kitchen-` + item.id" class="text">{{
									item.title
								}}</label>
							</div>
							<tk-input-number v-model="options[item.id]" :min="0" :max="20" />
						</li>
					</template>
				</ul>
			</fieldset>

			<fieldset v-if="values.rules" class="filter-form__fieldset rules">
				<legend class="filter-form__legend">Правила дома</legend>
				<ul class="filter-form__list">
					<li
						v-for="item in values.rules[0].options"
						:key="item.id"
						class="filter-form__item"
					>
						<div class="filter-form__text-wrapper">
							<label :for="`place-filter-rules-` + item.id" class="text">
								{{ item.title }}
							</label>
						</div>
						<div class="switcher">
							<input
								v-model="options[item.id]"
								class="tgl tgl-light"
								:id="`place-filter-rules-` + item.id"
								type="checkbox"
							/>
							<label class="tgl-btn" :for="`place-filter-rules-` + item.id" />
						</div>
					</li>
				</ul>
			</fieldset>

			<fieldset
				v-if="amenities.options && amenities.options.length"
				class="filter-form__fieldset amenities"
        style="margin-bottom: 0"
      >
				<legend class="filter-form__legend visuality-hidden">Удобства</legend>

				<tk-drop-down animation="slide-fade">
					<template v-slot:toggle="{ active }">
						<div class="filter-form__toggle" :class="{ active: active }">
							<div class="filter-form__text-wrapper">
								<label class="text">{{ amenities.title_group }}</label>
							</div>
						</div>
					</template>

					<template v-slot:content>
						<div
							class="filter-form__dropdown-content dropdown-amenities dropdown"
						>
							<fieldset
								class="filter-form__fieldset filter-form__fieldset--scroll"
							>
								<ul class="filter-form__list">
									<li
										v-for="item in amenities.options"
										:key="item.id"
										class="filter-form__item"
									>
										<div class="filter-form__text-wrapper">
											<label :for="`amentities-` + item.id" class="text">
												{{ item.title }}
											</label>
										</div>
										<div class="switcher">
											<input
												class="tgl tgl-light"
												:id="`amentities-` + item.id"
												v-model="options[item.id]"
												:true-value="1"
												:false-value="0"
												type="checkbox"
											/>
											<label class="tgl-btn" :for="`amentities-` + item.id" />
										</div>
									</li>
								</ul>
							</fieldset>
						</div>
					</template>
				</tk-drop-down>
			</fieldset>

			<fieldset
				v-if="amenitiesKids.options && amenitiesKids.options.length"
				class="filter-form__fieldset amenities-kids"
        style="margin-top: -5px"
      >
				<tk-drop-down animation="slide-fade">
					<template v-slot:toggle="{ active }">
						<div class="filter-form__toggle" :class="{ active: active }">
							<div class="filter-form__text-wrapper">
								<label class="text">{{ amenitiesKids.title_group }}</label>
							</div>
						</div>
					</template>

					<template v-slot:content>
						<div
							class="filter-form__dropdown-content dropdown-amenities-kids dropdown"
						>
							<fieldset
								class="filter-form__fieldset filter-form__fieldset--scroll"
							>
								<ul class="filter-form__list">
									<li
										v-for="item in amenitiesKids.options"
										:key="item.id"
										class="filter-form__item"
									>
										<div class="filter-form__text-wrapper">
											<label :for="`amenities-kids-` + item.id" class="text">
												{{ item.title }}
											</label>
										</div>
										<div class="switcher">
											<input
												class="tgl tgl-light"
												:id="`amenities-kids-` + item.id"
												v-model="options[item.id]"
												:true-value="1"
												:false-value="0"
												type="checkbox"
											/>
											<label
												class="tgl-btn"
												:for="`amenities-kids-` + item.id"
											/>
										</div>
									</li>
								</ul>
							</fieldset>
						</div>
					</template>
				</tk-drop-down>
			</fieldset>

      <fieldset
        v-if="amenitiesDisabilities.options && amenitiesDisabilities.options.length"
        class="filter-form__fieldset amenities-kids"
        style="margin-top: -5px"
      >
        <tk-drop-down animation="slide-fade">
          <template v-slot:toggle="{ active }">
            <div class="filter-form__toggle" :class="{ active: active }">
              <div class="filter-form__text-wrapper">
                <label class="text">{{ amenitiesDisabilities.title_group }}</label>
              </div>
            </div>
          </template>

          <template v-slot:content>
            <div
              class="filter-form__dropdown-content dropdown-amenities-kids dropdown"
            >
              <fieldset
                class="filter-form__fieldset filter-form__fieldset--scroll"
              >
                <ul class="filter-form__list">
                  <li
                    v-for="item in amenitiesDisabilities.options"
                    :key="item.id"
                    class="filter-form__item"
                  >
                    <div class="filter-form__text-wrapper">
                      <label :for="`amenities-disabilities-` + item.id" class="text">
                        {{ item.title }}
                      </label>
                    </div>
                    <div class="switcher">
                      <input
                        class="tgl tgl-light"
                        :id="`amenities-disabilities-` + item.id"
                        v-model="options[item.id]"
                        :true-value="1"
                        :false-value="0"
                        type="checkbox"
                      />
                      <label
                        class="tgl-btn"
                        :for="`amenities-disabilities-` + item.id"
                      />
                    </div>
                  </li>
                </ul>
              </fieldset>
            </div>
          </template>
        </tk-drop-down>
      </fieldset>

			<fieldset class="filter-form__fieldset conditions">
				<legend class="filter-form__legend">Условия размещения</legend>
				<ul class="filter-form__list">
					<li class="filter-form__item">
						<div class="filter-form__text-wrapper">
							<label for="whole-place" class="text">Жилье целиком</label>
						</div>
						<div class="switcher">
							<input
								class="tgl tgl-light"
								v-model="form.room_type"
								id="whole-place"
								name="places-filter-conditions"
								:value="1"
								type="radio"
							/>
							<label class="tgl-btn" for="whole-place" />
						</div>
					</li>
					<li class="filter-form__item">
						<div class="filter-form__text-wrapper">
							<label for="separate-room" class="text">
								Отдельная комната
							</label>
						</div>
						<div class="switcher">
							<input
								class="tgl tgl-light"
								v-model="form.room_type"
								id="separate-room"
								name="places-filter-conditions"
								:value="2"
								type="radio"
							/>
							<label class="tgl-btn" for="separate-room" />
						</div>
					</li>
					<li class="filter-form__item">
						<div class="filter-form__text-wrapper">
							<label for="bed" class="text">Место в комнате</label>
						</div>
						<div class="switcher">
							<input
								class="tgl tgl-light"
								v-model="form.room_type"
								:value="3"
								id="bed"
								name="places-filter-conditions"
								type="radio"
							/>
							<label class="tgl-btn" for="bed" />
						</div>
					</li>
				</ul>
			</fieldset>
		</form>

		<div class="filter-form__buttons">
			<tk-button
				@click="clear"
				type="reset"
				kind="main-brown"
				class="filter-form__reset"
			>
				Сбросить
			</tk-button>

			<tk-button @click="submit" class="filter-form__submit">
				Применить
			</tk-button>
		</div>
	</div>
</template>

<script>
import { objToArr } from '@/utils/normalizeData'

import { GET, GET_MARKERS } from '@/constants/store/search/actions'

// Refactoring

export default {
	name: 'PlaceFilter',
	props: {
		content: {
			type: Object,
			default: () => ({})
		}
	},
	watch: {
		price: function () {
			this.form.price_min = this.price[0]
			this.form.price_max = this.price[1]
		},
		distance: function () {
			this.form.distance = `${this.distance[0]},${this.distance[1]}`
		}
	},
	data() {
		return {
			options: {},
			key: 0,
			price: 0,
			distance: 0,
			values: {},
			form: {}
		}
	},
	created() {
		Object.keys(this._fields).forEach((key) => {
			if (this.$route.query[key]) {
				this.form[key] = this.$route.query[key]
			}

			if (key === 'distance' && this.$route.query[key]) {
				this.distance = [
					+this.$route.query[key].split(',')[0],
					+this.$route.query[key].split(',')[1]
				]
			}

			if (key === 'price_min' && +this.$route.query[key]) {
				this.price = [+this.$route.query[key], this.price]
			}

			if (key === 'price_max' && +this.$route.query[key]) {
				this.price = [this.price[0] || 0, +this.$route.query[key]]
			}
		})
	},
	async beforeMount() {
		const lists = await this.$api.lists.getHousingOptions()

		if (lists && lists.status) {
			this.values = lists.response

			this.setDefaultOptions()
		}

		if (this.$route.query.options) {
			this.options = {
				...this.options,
				...JSON.parse(this.$route.query.options)
			}
		}
	},
	computed: {
		_fields() {
			return {
				auto_booking: '0',
				price_min: 0,
				price_max: 0,
				distance: '',
				type: null,
				room_type: null
			}
		},
		amenities() {
			return this.values.amenities ? this.values.amenities[0] : {}
		},
		amenitiesKids() {
			return this.values.amenities ? this.values.amenities[1] : {}
		},
		amenitiesDisabilities() {
			return this.values.amenities ? this.values.amenities[2] : {}
		}
	},
	methods: {
		async update(payload) {
			// if (this.content?.is_user_favorites !== null) {
			// 	return Promise.all(
			// 		[GET, GET_MARKERS].map((itm) =>
			// 			this.$store.dispatch(itm, { ...payload, is_user_favorites: 1 })
			// 		)
			// 	)
			// } else {
				return Promise.all(
					[GET, GET_MARKERS].map((itm) => this.$store.dispatch(itm, payload))
				)
			// }
		},
		async clear() {
			Object.keys(this.form).forEach((key) => {
				this.form[key] = this._fields[key]
			})

			this.distance = 0
			this.price = 0

			this.setDefaultOptions()

			this.key += 1

			// change queries
			const defaultRouteField = [
				'children',
				'guests',
				'date_to',
				'date_from',
				'city'
			]
			const query = {}
			defaultRouteField.forEach((itm) => (query[itm] = this.$route.query[itm]))

			this.$router.push({ query: { ...query } })

			// update result
			await this.update(query)
		},
		async submit() {
			const { options, ...queries } = this.$route.query

			const params = { ...queries, ...this.form }
			Object.entries(params).forEach(([key, value]) =>
				value === null ? delete params[key] : null
			)

			// add options to payload and check value is set
			const optionsForPayload = {}
			Object.entries(this.options).forEach(([key, value]) =>
				value ? (optionsForPayload[key] = value) : null
			)

			if (Object.keys(optionsForPayload).length) {
				params.options = { ...optionsForPayload }
			}

			const response = await this.update({ ...params })

			if (response.find((itm) => itm)) {
				// Need to notify user
				return
			}

			this.$router.push({
				query: {
					...params,
					options: JSON.stringify(params.options)
				}
			})

			this.hide()
		},
		hide() {
			this.$store.commit('hideModal')
		},
		setDefaultOptions() {
			// Initialize value
			const data = objToArr(this.values, 'key')

			data.forEach((itm) => {
				if (itm[0].title_group) {
					const arr = objToArr(itm, 'key_inner')
					const optns = arr
						.reduce(
							(prev, current) =>
								current.options ? [...prev, current.options] : prev,
							[]
						)
						.flat()

					optns.forEach((itm) => {
						this.options[itm.id] = itm.value
					})
				} else {
					itm[0].options.forEach((itm) => {
						if (itm.type === 'number') {
							this.options[itm.id] = 0
						} else if (itm.type === 'text') {
							this.options[itm.id] = ''
						} else {
							this.options[itm.id] = null
						}
					})
				}
			})
		}
	}
}
</script>

<style lang="sass">
.place-filter-modal
  position: relative
  background-color: #f5f5f5
  width: 100%
  padding: 12px 20px 15px
  max-width: 800px
  border-radius: 14px

  &__title
    background: url('~@/assets/images/svg/filter.svg') calc(50% - 40px) calc(50% - 5px) no-repeat transparent
    width: 80%
    margin: 0 auto 10px
    padding-bottom: 10px
    font-size: 16px
    line-height: 18px
    font-weight: normal
    text-align: center
    border-bottom: 1px solid #E2E2E2

  &__close-btn
    position: absolute
    top: 20px
    right: 20px

    width: 15px
    height: 15px

    background: url("~@/assets/images/svg/close-cross.svg") center no-repeat transparent

  .filter-form
    position: relative

    display: flex
    flex-wrap: wrap
    flex-flow: column wrap
    gap: 20px
    max-height: 500px

    &__text-wrapper
      display: flex
      align-items: center

    &__toggle
      position: relative

      display: flex
      justify-content: space-between
      align-items: center
      padding: 10px 10px 10px 15px
      display: flex

      &:before
        content: url("~@/assets/images/svg/arrow-down.svg")
        position: absolute
        right: 10px

        transition: 0.2s linear

      &.active::before
        transform: rotate(180deg)
        transition: 0.1s linear

      label
        margin-bottom: 0
        margin-right: 10px
        line-height: 17px

        &.tgl-btn
          margin-right: 0

    &__fieldset
      position: relative

      width: 240px
      margin: 0
      padding: 0

      border: 0

      &--scroll
        max-height: 200px

        overflow-y: auto

      &.placetype
        // margin-top: 20px
      &.price-range
        margin-bottom: 20px
      &.center
        margin-bottom: 20px

    &__legend
      display: flex
      justify-content: space-between
      width: 100%
      padding: 0 10px
      margin-bottom: 3px

      font-size: 12px
      line-height: 12px

      box-sizing: border-box
      &.hidden-heading
        color: transparent

    &__list
      padding-left: 0
      margin-bottom: 0
      margin-top: 3px
      list-style: none

      background: #ffffff
      border-radius: 10px

      &.special
        margin-bottom: 20px

      &.rating,
      &.additional,
      &.amenities, &.amenities-kids, &.amenities-disabilities
        .filter-form__item
          position: relative

          &:before
            content: url("~@/assets/images/svg/arrow-down.svg")
            position: absolute
            right: 10px

            transition: 0.2s linear
    &__item
      display: flex
      justify-content: space-between
      align-items: center
      padding: 10px 10px 10px 15px
      display: flex

      &:not(:last-child)
        border-bottom: 1px solid #E2E2E2

      label
        margin-bottom: 0
        margin-right: 10px
        line-height: 17px

        &.tgl-btn
          margin-right: 0

      .counter
        display: flex
        justify-content: center
        align-items: center
        width: 17px
        height: 17px

        border-radius: 50%
        background: #1AC386

        font-size: 10px
        line-height: 9px
        color: #ffffff

      &.active
        box-shadow: 0px 5px 5px rgba(95, 83, 80, 0.1)
        z-index: 4
        &:before
          transform: rotate(180deg)
          transition: 0.1s linear

      &.amenities,
      &.amenities-kids,
      &.amenities-disabilities
        position: relative

    &__buttons
      display: flex
      margin-top: 20px
      justify-content: flex-end
      align-items: flex-end

    &__submit
      width: 180px
      height: 35px
      font-size: 15px
      font-weight: 700

    &__reset
      width: 180px
      height: 35px
      margin-right: 15px
      font-size: 15px
      font-weight: 700

  .el-slider__wrapper
    margin-top: 2px
    background-color: #ffffff
    padding: 3px 20px
    border-radius: 12px

  .dropdown
    background: #ffffff
    border-radius: 11px

    &.dropdown-rating
      top: 30px

  .switcher
    .tgl
      display: none

      &::selection,
      &:after::selection,
      &:before::selection,
      & *::selection,
      & *:after::selection,
      & *:before::selection,
      & + .tgl-btn::selection
        background: none

      & + .tgl-btn
        outline: 0
        display: block
        width: 30px
        height: 18.5px
        position: relative
        cursor: pointer
        border: 1px solid #E5E5E5
        box-sizing: border-box

      & + .tgl-btn:after, .tgl + .tgl-btn:before
        position: absolute
        display: block
        content: ""
        width: 16.47px
        height: 16.47px
        border: 0.5px solid #E5E5E5
        box-sizing: border-box

      & + .tgl-btn:after
        left: -3%
        top: 1%

      & + .tgl-btn:before
        display: none

      &:checked + .tgl-btn:after
        left: 43%

      &-light + .tgl-btn
        background: #ffffff
        border-radius: 2em
        padding: 2px
        transition: all .4s ease

      &-light + .tgl-btn:after
        border-radius: 50%
        background: #fff
        transition: all .2s ease

      &-light:checked + .tgl-btn
        background: #1AC386

@media screen and (max-width: 800px)
  .place-filter-modal
    width: 100%
    height: auto
    padding-bottom: 40px
    margin-top: 750px

    .filter-form
      max-height: unset
      flex-flow: unset
      flex-wrap: wrap

      overflow-y: auto

      &>legend
        display: none
      &__buttons
        position: static
      &__fieldset
        width: 100%

      &__buttons
        width: 100%
        position: static
        justify-content: space-between
        grid-column: unset

      &__submit,
      &__reset
        width: 47%

      .filter-form__reset
        margin-right: 0
</style>
