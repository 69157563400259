export default (axios) => ({
	/**
	 * Получение региона, района для карточки через API DaData
	 *
	 * @query { string } - find
	 *
	 * @return { Object }
	 * 
		[
			{
				"value": "Russia, oblast Belgorodskaya, rayon Valuysky",
				"unrestricted_value": "Russia, oblast Belgorodskaya, rayon Valuysky",
				"fias_id": "9df691ad-8bff-443f-a42e-7226083401b5",
				"region_fias_id": "639efe9d-3fc8-4438-8e70-ec4f2321f2a7"
			}
		]
	 *
	*/
	getCities({ find }) {
		return axios.get(`address/cities?find=${find}`)
	},
	getAddress({ find }) {
		return axios.get(`address?find=${find}`)
	},
	getFavorite() {
		return axios.get(`place/addresses/cities/favorite`)
	},
	getPlaces({ options, ...payload }) {
		if (options && Object.keys(options).length) {
			Object.entries(options).forEach(([key, value]) => {
				payload[`options[${key}]`] = value
			})
		}

		return axios.get(`place/search`, {
      params: { ...payload }
		})
	},
	getMarkers({ options, ...payload }) {
		if (options && Object.keys(options).length) {
			Object.entries(options).forEach(([key, value]) => {
				payload[`options[${key}]`] = value
			})
		}

		return axios.get(`place/map-search`, {
      params: { ...payload }
		})
	}
})
