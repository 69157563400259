import mods from '@/constants/mods'
import accConfirmStatuses from '@/constants/accConfirmStatuses'
import rejectBookingTypes from '@/constants/rejectBookingTypes'

export default {
	isAuth: (store) => !!store.auth?.tokens?.access,

	name: ({ user }) => user.name || 'Незнакомец/ка',
	fullName: ({ user }) => {
		if (!user.surname && !user.name && !user.patronymic) {
			return 'Пользователь'
		} else {
			return `${user.surname || ''} ${user.name || ''}${user.patronymic ? ` ${user.patronymic}` : ''}`
		}
	},
	avatar: ({ avatar }) =>
		avatar.content ? avatar.content : '/images/default-avatar.svg',
	stats: (state) => state.status, // need information about progress

	mode: ({ mode }) => mode,
	isGuest: ({ mode }) => !mode || mods.GUEST === mode,
	isMaster: ({ mode }) => !mode || mods.MASTER === mode,

	isAccountConfirmed: ({ user }) =>
		user.account_confirmation === accConfirmStatuses.CONFIRMED,
	isAccountUnderVerification: ({ user }) =>
		user.account_confirmation === accConfirmStatuses.IN_PROCESSING,
	isAccountVerificationRejected: ({ user }) => 
		user.account_confirmation === accConfirmStatuses.NOT_CONFIRMED,

	profile_percent: ({ user }) =>
		user.profile_percent ? user.profile_percent : 0,

  ownerRejectTypes: ({ rejectAndComplaint }) => rejectAndComplaint.codes?.filter(item => item.type_id === rejectBookingTypes.OWNER).reverse(),
  guestRejectTypes: ({ rejectAndComplaint }) => rejectAndComplaint.codes?.filter(item => item.type_id === rejectBookingTypes.GUEST).reverse()
}
