export default (axios) => ({
	/**
	 * Получение списка запросов с темами
	 *
	 * @param { string } - user_role: GUEST or MASTER
	 *
	*/
	get () {
		return axios.get(`account/user/ticket-themes-and-tickets`)
	},
	/**
	 * Список сообщений в тикете
	 *
	 * @param { string } - theme_id
	 * @param { string } - ticket_id
	 *
	*/
	getItem ({ theme_id, tickets_id }) {
		return axios.get(`account/user/ticket-themes/${theme_id}/tickets/${tickets_id}`)
	},
	/**
	 * Получение переписки по тикету с пагинацией
	 *
	 * @param { string } - ticket_id
	 * @param { string } - page
	 * @param { string } - limit
	 *
	*/
	getMessages ({ ticket_id }) {
		return axios.get(`account/user/tickets/${ticket_id}/replies?page=1&limit=100`)
	},
	/**
	 * Создание тикета
	 *
	 * @param { formData } - message: Мой первый тикет с файлом! Проверка связи!!! Обязательно. Текст воспроса
	 * @param { formData } - upload: Опциональный. Картинка или файл. max:2048 кб, mimetypes:image/jpeg,image/png
	 *
	*/
	create ({ id, message, image }) {
		const data = new FormData()
		data.append('message', message)
		// Object.entries(images).forEach(([key, value]) => data.append(`upload[${key}]`, value))
    if (image) {
      data.append(`upload`, image)
    }

		return axios.post(`account/user/ticket-themes/${id}`, data)
	},
	/**
	 * Ответ в тикете
	 * 
	 * @param { string } - theme_id
	 * @param { string } - ticket_id
	 *
	 * @param { formData } - message: Дополнительное сообщение от пользователя с картинкой. Обязательное поле
	 * @param { formData } - upload: Опциональный. Картинка или файл. max:2048 кб, mimetypes:image/jpeg,image/png
	 *
	*/
	createItem ({ theme_id, ticket_id, payload }) {
		const data = new FormData()
		data.append('message', payload.message)
		if (payload.image) data.append('upload', payload.image)

		return axios.post(`account/user/ticket-themes/${theme_id}/tickets/${ticket_id}`, data)
	}
})