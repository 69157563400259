import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
	state: {
		themes: [],
		tickets: []
	},
	actions,
	getters,
	mutations,
	namespaced: true
}