<template>
	<form @submit.prevent="submit" class="authmodal__form authform">
		<div class="authform__item authform__item--main-password">
			<tk-input
				:label="$t('form.fields.labels.password')"
				type="password"
				autocomplete="off"
				@input="clearError"
				v-model="passwordInputValue"
				required
			/>
		</div>

		<div class="authform__item">
			<tk-input
				:label="$t('form.fields.labels.repeat')"
				type="password"
				autocomplete="off"
				@input="clearError"
				v-model="passwordRepeatInputValue"
				required
			/>
		</div>

		<tk-error class="authform__main-error"  :data="errors" />
    <tk-error class="authform__main-error" :data="{ status: error.status, value: error.text }" />
		<tk-button type="submit" class="authform__submit" aria-label="Кнопка входа">
			Установить
		</tk-button>
	</form>
</template>

<script>
export default {
	props: {
		error: {
			type: Object,
			default: () => ({})
		},
		modelValue: {
			type: null
		}
	},
	data() {
		return {
			errors: { status: false, value: '' },
			passwordInputValue: '',
			passwordRepeatInputValue: ''
		}
	},
	methods: {
		clearError() {
			this.errors.status = false
			this.errors.value = ''

			this.$emit('input')
		},
		submit() {
			if (this.passwordInputValue !== this.passwordRepeatInputValue) {
				this.errors.status = true
				this.errors.value = 'Пароли не совпадают'

				return
			}

			this.$emit('update:modelValue', this.passwordInputValue)

			setTimeout(() => this.$emit('next'))
		}
	}
}
</script>

<style lang="sass">
.authform
	&__main-error
		margin-bottom: 15px

.authmodal

			&--main-password
				.input-component
					&__error
						top: -28px
</style>
