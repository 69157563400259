<template>
	<button :type="type" v-bind="attributes" :class="classes" :style="styles">
		<span class="button__icon-before btn-ico-before">
			<slot name="icon-before"></slot>
		</span>
		<span class="button__content">
			<slot></slot>
		</span>
		<span class="button__icon-after btn-ico-after">
			<slot name="icon-after"></slot>
		</span>
	</button>
</template>

<script>
export default {
	props: {
		kind: {
			type: String,
			default: 'main'
		},
		type: {
			type: String,
			default: 'button'
		},
		height: {
			type: Number,
			default: null
		},
		fontSize: {
			type: String,
			default: null
		},
		lineHeight: {
			type: String,
			default: null
		}
	},
	computed: {
		classes() {
			return `button ${this.kind} ${this.$attrs.class || ''}`
		},
		attributes() {
			const atrs = { ...this.$attrs }
			delete atrs.class

			return atrs
		},
		styles() {
			return {
				height: this.height ? this.height + 'px' : undefined,
				fontSize: this.fontSize ? this.fontSize : undefined,
				lineHeight: this.lineHeight ? this.lineHeight : undefined
			}
		}
	}
}
</script>

<style lang="sass">
.button
  // display: flex
  // justify-content: center
  // align-items: center
  padding: 0

  // width: 100%
  // height: 45px

  // color: #ffffff
  // font-size: 14px
  // line-height: 14px
  // font-weight: bold

  border: 0
  background-color: transparent
  transition: 0.1s linear

  cursor: pointer

  &:disabled
    cursor: not-allowed

  &__icon-before
    display: inline-block

  &.old-main
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 16px
    color: white
    background-color: #1AC386
    border-radius: 11px
  &.props-btn
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 24px
    padding: 8px 16px
    color: #FFFFFF
    background: #1AC386
  &.reg-btn
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 24px
    padding: 12px 20px
    color: #1AC386
    background: white
    border-radius: 14px
  &.main
    font-style: normal
    font-weight: 700
    font-size: 16px
    line-height: 19px
    color: #FFFFFF
    background-color: #1AC386
    border-radius: 40px

    &:hover:not([disabled])
      background-color: #5f5350
      border-color: #5f5350

    &:disabled
      opacity: 0.5

    .svg-icon path
      stroke: white

  &.opacity-border
    height: 45px
    font-weight: 700
    font-size: 15px
    line-height: 100%
    color: $green
    border: 1px dashed #CBCBCB
    border-radius: 10px

  &.main-brown
    color: white
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 16px
    background-color: #5f5350
    border-radius: 40px

    &:hover:not([disabled])
      background-color: #1AC386

  &.back-button
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    max-width: 65px
    height: 18px
    font-size: 18px
    line-height: 18px
    color: $green
    box-sizing: border-box

    .btn-ico-before
      margin-right: 0

      .svg-icon
        width: 15px
        height: 10px
        transform: rotate(-90deg)

        path
          fill: $green

    &.active
      visibility: hidden

  &.link-green
    width: auto
    display: inline-block
    padding: 0

    font-size: 18px
    line-height: 22px
    font-weight: 500
    color: #1AC386

    border: 0
    background-color: transparent

    cursor: pointer

    &:hover
      text-decoration: underline

  &.title-btn
    position: relative

    width: 175px
    height: 35px

    color: #1AC386

    border: 1px solid #1AC386
    border-radius: 12px
    background-color: #fff

    &:hover
      color: #ffffff

      background-color: #1AC386

    &.active
      color: #ffffff
      background-color: #1AC386

    &.notification:before
      content: ''
      position: absolute
      right: 5px
      top: 5px
      width: 10px
      height: 10px
      border-radius: 50%
      background: #F03800

    @media (max-width: 800px)
      width: 92px
      font-size: 11px
      line-height: 12px

  &.close-modal
    width: 20px
    height: 20px

    font-size: 0

    background: url("~@/assets/img/svg/buttons/close.svg") center no-repeat
    border: none

  &.main-white
    height: 56px

    font-style: normal
    font-weight: 700
    font-size: 16px
    line-height: 19px
    color: #2A3043

    background-color: #FFFFFF
    border-radius: 10px

    &:hover
      background-color: #1AC386

  &.main-border
    height: 49px

    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 19px
    color: #2A3043

    border: 1px solid #E0E0E0
    background-color: white
    border-radius: 59px
    transition: all 0.3s linear

    &:hover
      color: white

      background-color: #1AC386

  &.border-btn
    display: flex
    justify-content: center
    align-items: center
    width: 35px
    height: 35px
    color: #FFFFFF
    background: #1AC386
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2)
    border-radius: 50%

    span
      svg
        fill: #fff

    &.disabled
      background: #FFFFFF
      backdrop-filter: blur(27.1828px)
      border: 1px solid #CBCBCB
      box-shadow: none

      span
        svg
          fill: #CBCBCB
</style>
