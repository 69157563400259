import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { arrToObj } from '@/utils/normalizeData'
import { checkWebpCanvas } from '@/utils/helpers'
import getters from './getters'

import auth from './auth'
import place from './place'
import search from './search'
import user from './user'
import avatar from './avatar'
import anketa from './anketa'
import progress from './progress'
import favourites from './favourites'
import complaints from './complaints'
import tickets from './tickets'
import lists from './lists'
import helpers from "./helpers";
import modals from "@/constants/modals";
import router from "@/router";

export default createStore({
	state: {
		currentModal: {
			name: '',
			props: null,
			isShow: false
		},
		notification: {
			isShow: false,
			title: '',
			text: '',
			type: '' // warn/error/success/info. Default info
		},
		language: 'en',
		sleepPlaces: [],
		rejectAndComplaint: {},
		isSupportWebp: null,
		mode: 'MASTER' // guest or master
	},
	mutations: {
		showModal(state, payload) {
			if ((payload.name === modals.REGISTRATION
				|| payload.name === modals.AUTH)
				&& getters.isAuth(this))
			{
				router.push('/')
				return
			}

			state.currentModal.name = payload.name
			state.currentModal.props = payload.props
			state.currentModal.isShow = true
		},
		hideModal({ currentModal }) {
			currentModal.isShow = false
		},
		changeMode(state, mode) {
			state.mode = mode
		},
		showNotification(state, { title, text, type }) {
			state.notification = { title, text, type, isShow: true }
		},
		hideNotification(state) {
			state.notification = { title: '', text: '', type: '', isShow: false }
		},
		setData(state, { key, value }) {
			state[key] = value
		}
	},
	actions: {
		async getSleepPlaces({ commit, state }) {
			if (state.sleepPlaces?.length) {
				return
			}

			const response = await this.$api.lists.getSleepingPlace()

			if (response?.status) {
				commit('setData', {
					key: 'sleepPlaces',
					value: arrToObj([...response.response], 'id')
				})
			}
		},
		async getRejectAndComplaint({ commit, state }) {
			if (Object.keys(state.rejectAndComplaint).length) {
				return
			}

			const response = await this.$api.lists.getRejectAndComplaint()

			if (response?.status) {
				commit('setData', {
					key: 'rejectAndComplaint',
					value: { ...response.response }
				})
			}
		},
		init({ commit, state }) {
      if (state.isSupportWebp === null) {
        commit('setData', { key: 'isSupportWebp', value: checkWebpCanvas() })
      }
		}
	},
	getters,
	modules: {
		auth,
		search,
		user,
		place,
		avatar,
		progress,
		favourites,
		complaints,
		anketa,
		lists,
		tickets,
		helpers
	},
	plugins: [
		createPersistedState({
			paths: [
				'user',
				'sleepPlaces',
				'auth.tokens',
        'isSupportWebp',
        'rejectAndComplaint',
				'home',
				'mode',
				'lists',
				'place',
				'tickets',
				'search.search',
				'search.markers'
			]
		})
	]
})
