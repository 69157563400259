import DLListNode from "@/utils/orderedDLSet/node";
import DLListIterator from "@/utils/orderedDLSet/iterator";

/**
 * Двусвязный упорядоченный (строгий линейный порядок) список.
 * Т.е. для каждого элемента известно его значение, следующий элемент
 * и предыдущий.
 * Реализует:
 * head             - возвращает зачение наибольшего элемента O(1)
 * tail             - возвращает значение наименьшего элемент O(1)
 * insert           - добавляет элемент в список: если элемент меньше или больше
 *                    других то за O(1), иначе O(N)
 * insertSortedRange- добавляет заранее упорядоченный массив из M элементов, O(N+M)
 * insertRange      - добавляет массив из M элементов (предварительно его
 *                    сортирует) O(N + MlogM)
 * @@iterable       - возвращает итератор по элементам списка, возвращая из значения
 *
 * @param comparator: fn(x,y)->bool нестрогий линейный порядок.
 */
export default class OrderedDLSet {
  /**
   * Ссылка на последний элемент списка
   *
   * @private
   */
  _head

  /**
   * Ссылка на первый элемент списка
   *
   * @private
   */
  _tail

  /**
   * Количество элементов в множестве
   *
   * @type {number}
   * @private
   */
  _length = 0

  /**
   * Отношение порядка на множестве
   *
   * @private
   */
  _comparator

  /**
   * Индуцированое отношение эквивалентности из отношения порядка
   *
   * @private
   */
  _eq

  /**
   * _comparator(a,b) возвращает истину, если a >= b
   *
   * @param comparator
   */
  constructor(comparator) {
    this._head = null
    this._tail = null
    this._comparator = comparator
    this._eq = (a, b) => {
      return comparator(a, b) & comparator(b, a)
    }
  }

  /**
   * Возвращает значение наибольшего элемента
   *
   * @returns {*}
   */
  get head() {
    return this._head?.value
  }

  /**
   * Возвращает значение наименьшего элемента
   *
   * @returns {*}
   */
  get tail() {
    return this._tail?.value
  }

  /**
   * Возвращает количество элементов в множестве.
   * Синонима {@link length}
   *
   * @return {number}
   */
  get size() {
    return this._length
  }

  /**
   * Возвращает количество элементов в множестве.
   * Синонима {@link size}
   *
   * @return {number}
   */
  get length() {
    return this.size
  }

  /**
   * Вставляет. Правда.
   *
   * @param value
   */
  insert(value) {
    // если в списке ничего нет - добавляем элемент
    if (this._head == null) {
      const node = new DLListNode(value)
      this._head = node
      this._tail = node
      this._length++
      return
    }

    // если элемент не меньше остальных - добавляем в конец
    if (this._comparator(value, this._head.value)) {
      // т.к. порядок строгий, не добавляем элемент, если такой уже есть
      if (this._comparator(this._head.value, value)) {
        return
      }
      const node = new DLListNode(value, this._head, null)
      this._head.next = node
      this._head = node
      this._length++
      return
    }

    // если элемент не больше остальных - добавляем в начало
    if (this._comparator(this._tail.value, value)) {
      // т.к. порядок строгий, не добавляем элемент, если такой уже есть
      if (this._comparator(value, this._tail.value)) {
        return
      }
      const node = new DLListNode(value, null, this._tail)
      this._tail.prev = node
      this._tail = node
      this._length++
      return
    }

    // значит новый элемент будет распологаться между двумя другими
    // проходимся по списку и находим для него место
    let prev_node = this._tail
    let next_node = prev_node.next
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (this._comparator(value, prev_node.value) &&
          this._comparator(next_node.value, value)) {
        // т.к. порядок строгий, не добавляем элемент, если такой уже есть
        if (this._comparator(value, next_node.value)) {
          return
        }
        const node = new DLListNode(value, prev_node, next_node)
        prev_node.next = node
        next_node.prev = node
        this._length++
        return
      } else {
        prev_node = next_node
        next_node = next_node.next
      }
    }
  }

  /**
   * Добавляет в список коллекцию заранее упорядоченных элементов.
   *
   * @remarks
   * Реализуется обычное слияние упорядоченных коллекций
   *
   * @param values
   */
  insertSortedRange(values) {
    if (!values.length) {
      return
    }

    let prev_node = this._tail
    let next_node = prev_node.next
    for (let i = 0; i < values.length; i++) {
      let node = new DLListNode(value[i])

      // если список пуст - добавляем элемент
      if (this._head == null) {
        this._head = node
        this._tail = node
        next_node = node
        this._length++
        continue
      }

      // добавление в начало
      if (prev_node == null && this._comparator(next_node.value, node.value)) {
        // т.к. порядок строгий, не добавляем элемент, если такой уже есть
        if (this._comparator(node.value, next_node.value)) {
          continue
        }
        node.next = next_node
        next_node.prev = node
        next_node = node
        this._tail = node
        this._length++
        continue
      }

      // добавление в конец
      if (next_node == null && this._comparator(node.value, prev_node.value)) {
        // т.к. порядок строгий, не добавляем элемент, если такой уже есть
        if (this._comparator(prev_node.value, node.value)) {
          continue
        }
        node.prev = prev_node
        prev_node.next = node
        prev_node = node
        this._head = node
        this._length++
        continue
      }

      // добавление между элементами
      if (this._comparator(next_node.value, node.value) &&
          this._comparator(node.value, prev_node.value)) {
        // т.к. порядок строгий, добавляем элемент только если его нет
        if (!this._comparator(node.value, next_node.value)) {
          node.next = next_node
          node.prev = prev_node
          next_node.prev = node
          prev_node.next = node
          prev_node = node
          this._length++
          continue
        }
      }

      // если не добавили - переходим к следующей паре
      prev_node = next_node
      next_node = next_node.next
    }
  }

  /**
   * Добавляет в список коллекцию элементов.
   *
   * @remarks
   * Сортируем и вызываем {@link insertSortedRange}
   *
   * @param values
   */
  insertRange(values) {
    values.sort(this._comparator)
    this.insertSortedRange(values)
  }

  [Symbol.iterator]() {
    return new DLListIterator(this._tail);
  }
}