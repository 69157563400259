import mutations from './mutations'
import actions from './actions'

import register from './register'
import recovery from './recovery'

export default {
	state: {
		tokens: {
			access: null,
			refresh: null
		}
	},
	actions,
	mutations,
	modules: {
		register,
		recovery
	},
	namespaced: true
}
