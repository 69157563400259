<template>
  <div class="info-modal">
    <li class="reviews__item">
      <div class="reviews__item_header">
        <div class="guest-img">
          <tk-image :src="review.avatar" :alt="review.author_name" />
        </div>
        <div class="header-wrap">
          <div class="guest-name">{{ review.author_name }}</div>
          <div class="date">{{ getDateInFormat(review.created_at) }}</div>
        </div>
      </div>
      <p class="reviews__item_text" ref="text">
        {{ review.text }}
      </p>
      <RatingList :ratings="review.ratings" />
    </li>
  </div>
</template>

<script>
import { getDateInFormat } from "@/utils/dateTime";
import RatingList from "@/components/cards/review/RatingList";

export default {
  name: "ReviewModal",
  components: { RatingList },
  props: {
    content: Object
  },
  data() {
    return {
      review: this.content.review,
      showMoreBtn: false,
      isShown: false
    }
  },
  methods: {
    getDateInFormat
  },
  /**
   * Т.к. "размер" текстового блока комменатрия изначально ограничен
   * (css overflow), проверяем, вмещается ли весь текст в него
   * если да, то не будем отображать кнопку "показать больше", что изменяет
   * видимость / размеры.
   */
  mounted() {
    this.showMoreBtn = (this.$refs.text.offsetHeight < this.$refs.text.scrollHeight)
  }
};
</script>

<style lang="sass">
.info-modal
  nded-card__criteria
    width: 100%
    border-top: unset

.reviews__item
  list-style: none
</style>
