import setModuleName from '@/utils/setModuleName'

import { MODULE_NAME } from '.'

export const SET_MUT = 'SET'
export const CLEAR_MUT = 'CLEAR'
export const UPDATE_MUT = 'UPDATE'
export const DELETE_MUT = 'DELETE'

export const SET = setModuleName(MODULE_NAME, SET_MUT)
export const CLEAR = setModuleName(MODULE_NAME, CLEAR_MUT)
export const UPDATE = setModuleName(MODULE_NAME, UPDATE_MUT)
export const DELETE = setModuleName(MODULE_NAME, DELETE_MUT)