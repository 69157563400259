import {
	ElDatePicker,
	ElSlider,
  ElAutocomplete,
  ElUpload,
	ElSelect,
	ElOption,
  ElProgress
} from 'element-plus'
import { Carousel, Slide } from 'vue3-carousel'
import { createApp } from 'vue'

import App from './App.vue'

import router from './router'
import store from './store'

import i18n from './plugins/i18n'
import EventBus from './plugins/eventBus'
import { api } from './plugins/api'
import GlobalComponents from './plugins/global-components'
import './plugins/element-plus'

import lazy from './directives/lazy'
import outside from './directives/outside'
import phoneMask from './directives/mask'

import 'vue3-carousel/dist/carousel.css'
import 'element-plus/dist/index.css'
import Chat from "@/chat/Chat";

import { UniversalMetric } from "@/metrics/universalMetric"
import { YandexMetric } from "@/metrics/yandexMetric";

store.$api = api

const app = createApp(App)
	.directive('lazy', lazy)
	.directive('click-outside', outside)
	.directive('phone', phoneMask)

	.provide('api', api)
	.provide('bus', EventBus)
	.provide('chat', new Chat())
	.provide('metricsService', new UniversalMetric([
		new YandexMetric()
	]))

	.use(i18n)
	.use(store)
	.use(router)

	.component('tk-datepicker', ElDatePicker)
	.component('tk-slider', ElSlider)
	.component('tk-select', ElSelect)
	.component('tk-option', ElOption)
	.component('tk-upload', ElUpload)
	.component('tk-progress', ElProgress)
	.component('tk-autocomplete', ElAutocomplete)
	.component('tk-carousel', Carousel)
	.component('tk-slide', Slide)

GlobalComponents.register(app)

app.config.globalProperties.$api = api
app.config.globalProperties.$bus = EventBus

app.mount('#app')
