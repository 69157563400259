import setModuleName from '@/utils/setModuleName'

import { MODULE_NAME } from '.'

export const SET_MUT = 'SET_DATA'
export const SET_FIELD_MUT = 'SET_DATA_FIELD'
export const DELETE_FIELD_MUT = 'DELETE_FIELD'
export const CLEAR_ALL_MUT = 'CLEAR_ALL'

export const SET = setModuleName(MODULE_NAME, SET_MUT)
export const SET_FIELD = setModuleName(MODULE_NAME, SET_FIELD_MUT)
export const DELETE_FIELD = setModuleName(MODULE_NAME, DELETE_FIELD_MUT)
export const CLEAR_ALL = setModuleName(MODULE_NAME, CLEAR_ALL_MUT)
